import { Component, OnInit, Inject, Host } from "@angular/core";
import { ShowProjectComponent } from "../show-project.component";
import { L } from "@ic2/ic2-lib";
import { Projet } from "../../../../ic2/entities/Projet";
import { UserTelEmailDTO } from "../../../../ic2/entities/UserTelEmailDTO";
import {
  Departement,
  ScanhaRight,
  TypologieBatiment,
  NiveauPrecision,
  LivrableReseau,
  Livrable2D,
  LivrableReleveNumerique,
  Livrable3D,
  Precision2D,
  TypeLivrable,
  SurfaceUnite,
  TypologieReseau,
} from "../../../../ic2/entities/entities";
import LivrablePerDimension from "../../../core/LivrablePerDimension";
import { AuthService } from "../../../core/auth.service";
import { ProjetService } from "../../../../ic2/services/ProjetService";

@Component({
  selector: "app-infos-project",
  templateUrl: "./infos-project.component.html",
  styleUrls: ["./infos-project.component.scss"],
})
export class InfosProjectComponent implements OnInit {
  projet: Projet;
  userAssigne: UserTelEmailDTO;
  projetCopy: Projet;

  canEdit: boolean = true;
  editMode: boolean;
  saveLoading: boolean;
  showDates: boolean;

  typeLivrables: TypeLivrable[] = TypeLivrable.values;
  typeLivrable: typeof TypeLivrable = TypeLivrable;
  livrables2D: Livrable2D[] = Livrable2D.values;
  livrables3D: Livrable3D[] = LivrablePerDimension["3D"];
  livrablesReseau: LivrableReseau[] = LivrablePerDimension["Reseau"];
  livrablesReleve: LivrableReleveNumerique[] =
    LivrablePerDimension["Nuage de point"];
  typologies: TypologieBatiment[] = TypologieBatiment.values;
  typologiesReseau: TypologieReseau[] = TypologieReseau.values;
  precisions: NiveauPrecision[] = NiveauPrecision.values;
  precisions2D: Precision2D[] = Precision2D.values;
  unites: SurfaceUnite[] = SurfaceUnite.values;

  constructor(
    public parentComp: ShowProjectComponent,
    @Inject(AuthService) public authService: AuthService,
    @Inject(ProjetService) public projetService: ProjetService
  ) {}

  ngOnInit() {
    this.canEdit = this.authService.has(ScanhaRight.ADMIN);
    this.parentComp.dataLoaded.subscribe((data) => {
      this.projet = data.projet;
      this.userAssigne = data.userAssigne;
    });
  }

  edit() {
    this.editMode = true;
    this.projetCopy = Object.assign(new Projet(), this.projet);
  }
  cancel() {
    this.projet = this.projetCopy;
    this.editMode = false;
  }
  save() {
    this.saveLoading = true;
    this.projetService.editProjet(this.projet).subscribe(
      (data) => {
        this.saveLoading = false;
        this.editMode = false;
      },
      (err) => {
        this.saveLoading = false;
        L.e(err);
      }
    );
  }
}
