import { Component, OnInit, Host, Inject, ViewChild } from "@angular/core";
import { FicheProjet, UserLiteDTO } from "../../../../../ic2/entities/entities";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { ProjetActionService } from "../../../../../ic2/services/ProjetActionService";
import { Observable, Subject, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  filter,
} from "rxjs/operators";
import { ScanhaUserService } from "../../../../../ic2/services/ScanhaUserService";
import { L } from "@ic2/ic2-lib";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-action-validation-finale",
  templateUrl: "./action-validation-finale.component.html",
  styleUrls: ["./action-validation-finale.component.scss"],
})
export class ActionValidationFinaleComponent implements OnInit {
  ficheProjet: FicheProjet;
  loading: boolean;
  success: string;
  selectedUser: UserLiteDTO;
  users: UserLiteDTO[];

  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    public parentComp: ActionLayoutComponent,
    @Inject(ProjetActionService)
    private projetActionService: ProjetActionService,
    @Inject(ScanhaUserService) private scanhaUserService: ScanhaUserService
  ) {
    this.search = this.search.bind(this);
  }

  ngOnInit() {
    this.parentComp.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
    });
    this.scanhaUserService.getUsersAssignable().subscribe(
      (data) => {
        this.users = data;
        this.selectedUser = this.users.find((u) => {
          return u.idUser === this.ficheProjet.projet.idUserAssigne;
        });
      },
      (err) => {
        L.e(err);
      }
    );
  }

  formatter(result: UserLiteDTO) {
    if (result === null || result === undefined || <any>result === 0) return "";
    return result.firstName + " " + result.lastName;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => {
        return term === ""
          ? this.users
          : this.users
              .filter((u) => {
                return (
                  (
                    u.firstName.toLowerCase() +
                    " " +
                    u.lastName.toLowerCase()
                  ).indexOf(term.toLowerCase()) > -1 ||
                  (
                    u.lastName.toLowerCase() +
                    " " +
                    u.firstName.toLowerCase()
                  ).indexOf(term.toLowerCase()) > -1
                );
              })
              .slice(0, 10);
      })
    );
  };

  redistribuer() {
    if (this.selectedUser === null || this.selectedUser === undefined) return;
    this.loading = true;
    this.projetActionService
      .redistribue(
        this.ficheProjet.projet.id,
        this.selectedUser.idUser,
        this.ficheProjet.projet.commentaireScanha
      )
      .subscribe(
        (data) => {
          this.success = "Bien redistribué";
          setTimeout(() => {
            this.loading = false;
            this.success = null;
            this.parentComp.parentComp.reload();
          }, 2000);
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }

  allGood() {
    this.loading = true;
    this.projetActionService
      .validationFinale(this.ficheProjet.projet.id)
      .subscribe(
        (data) => {
          this.success = "Bien validé";
          setTimeout(() => {
            this.loading = false;
            this.success = null;
            this.parentComp.parentComp.reload();
          }, 2000);
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }
}
