import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Inject, LOCALE_ID } from "@angular/core";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { IRPC, Ic2Module } from "@ic2/ic2-lib";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { APP_BASE_HREF } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundComponent } from "./notfound/notfound.component";
import { LoginComponent } from "./login/login.component";
import { FrontLayoutComponent } from "./front/front-layout/front-layout.component";
import { HomeComponent } from "./front/home/home.component";
import { DevisFormComponent } from "./front/devis-form/devis-form.component";
import { ClientSpaceLayoutComponent } from "./client-space/client-space-layout/client-space-layout.component";
import { AdminProjectListComponent } from "./client-space/admin-project-list/admin-project-list.component";
import { ClientProjectListComponent } from "./client-space/client-project-list/client-project-list.component";
import { AdminInvoiceListComponent } from "./client-space/admin-invoice-list/admin-invoice-list.component";
import { AdminUserListComponent } from "./client-space/admin-user-list/admin-user-list.component";
import { UserEditFormComponent } from "./client-space/user-edit-form/user-edit-form.component";
import { ShowProjectComponent } from "./client-space/show-project/show-project.component";
import { AdminCreateAccountFormComponent } from "./client-space/admin-create-account-form/admin-create-account-form.component";
import { AdminPriceSettingsComponent } from "./client-space/admin-price-settings/admin-price-settings.component";
import { InfosProjectComponent } from "./client-space/show-project/infos-project/infos-project.component";
import { ProjectFilesComponent } from "./client-space/show-project/project-files/project-files.component";
import { UserEditPageComponent } from "./client-space/user-edit-page/user-edit-page.component";
import { AddressEditFormComponent } from "./client-space/user-edit-form/address-edit-form/address-edit-form.component";
import { ActionLayoutComponent } from "./client-space/show-project/actions/action-layout/action-layout.component";
import { ActionAdminCreationCompteComponent } from "./client-space/show-project/actions/action-admin-creation-compte/action-admin-creation-compte.component";
import { CreationMdpComponent } from "./front/creation-mdp/creation-mdp.component";
import { MdpOublieComponent } from "./front/mdp-oublie/mdp-oublie.component";
import { DevisFacturesDisplayComponent } from "./client-space/show-project/devis-factures-display/devis-factures-display.component";
import { ActionAdminRectifPrixAssignationComponent } from "./client-space/show-project/actions/action-admin-rectif-prix-assignation/action-admin-rectif-prix-assignation.component";
import { ActionClientValideDevisComponent } from "./client-space/show-project/actions/action-client-valide-devis/action-client-valide-devis.component";
import { ContactFormComponent } from "./front/contact-form/contact-form.component";
import { ActionClientPaiementComponent } from "./client-space/show-project/actions/action-client-paiement/action-client-paiement.component";
import { ActionClientAttenteVirementComponent } from "./client-space/show-project/actions/action-client-attente-virement/action-client-attente-virement.component";
import { NgxPayPalModule } from "ngx-paypal";
import { ActionPriseRdvComponent } from "./client-space/show-project/actions/action-prise-rdv/action-prise-rdv.component";
import { ActionUploadFilesComponent } from "./client-space/show-project/actions/action-upload-files/action-upload-files.component";
import { ActionValidationFinaleComponent } from "./client-space/show-project/actions/action-validation-finale/action-validation-finale.component";
import { MentionsLegalesComponent } from "./front/mentions-legales/mentions-legales.component";
import { ConditionsGeneralesDeVenteComponent } from "./front/conditions-generales-de-vente/conditions-generales-de-vente.component";
import { PartageProjetComponent } from "./front/partage-projet/partage-projet.component";
import { ActionPartageFichiersComponent } from "./client-space/show-project/actions/action-partage-fichiers/action-partage-fichiers.component";
import { RecaptchaModule } from "ng-recaptcha";
import { CookiePolicyComponent } from "./front/cookie-policy/cookie-policy.component";
import { RgpdComponent } from "./front/rgpd/rgpd.component";
import { AdminPriceSettingsPlan2dComponent } from "./client-space/admin-price-settings/admin-price-settings-plan2d/admin-price-settings-plan2d.component";
import { AdminPriceSettingsMaquette3dComponent } from "./client-space/admin-price-settings/admin-price-settings-maquette3d/admin-price-settings-maquette3d.component";
import { AdminPriceSettingsReleveComponent } from "./client-space/admin-price-settings/admin-price-settings-releve/admin-price-settings-releve.component";
import { AdminPriceSettingsReseauComponent } from "./client-space/admin-price-settings/admin-price-settings-reseau/admin-price-settings-reseau.component";
import { AdminPriceSettingsPrixauscanComponent } from "./client-space/admin-price-settings/admin-price-settings-prixauscan/admin-price-settings-prixauscan.component";

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    FrontLayoutComponent,
    HomeComponent,
    DevisFormComponent,
    ClientSpaceLayoutComponent,
    AdminProjectListComponent,
    ClientProjectListComponent,
    AdminInvoiceListComponent,
    AdminUserListComponent,
    UserEditFormComponent,
    ShowProjectComponent,
    AdminCreateAccountFormComponent,
    AdminPriceSettingsComponent,
    AdminPriceSettingsPlan2dComponent,
    AdminPriceSettingsMaquette3dComponent,
    AdminPriceSettingsReleveComponent,
    AdminPriceSettingsReseauComponent,
    AdminPriceSettingsPrixauscanComponent,
    InfosProjectComponent,
    DevisFacturesDisplayComponent,
    ProjectFilesComponent,
    UserEditPageComponent,
    AddressEditFormComponent,
    ActionLayoutComponent,
    ActionAdminCreationCompteComponent,
    CreationMdpComponent,
    MdpOublieComponent,
    ActionAdminRectifPrixAssignationComponent,
    ActionClientValideDevisComponent,
    ContactFormComponent,
    ActionClientPaiementComponent,
    ActionClientAttenteVirementComponent,
    ActionPriseRdvComponent,
    ActionUploadFilesComponent,
    ActionValidationFinaleComponent,
    MentionsLegalesComponent,
    ConditionsGeneralesDeVenteComponent,
    PartageProjetComponent,
    ActionPartageFichiersComponent,
    CookiePolicyComponent,
    RgpdComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "scanha" }),
    HttpClientModule,
    Ic2Module,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPayPalModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: LOCALE_ID, useValue: "fr" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(IRPC)
    public irpc: IRPC /*, @Inject(RoleService) public roleService: RoleService*/
  ) {
    if (typeof window !== "undefined") {
      irpc.authToken = (<any>window).jwtToken;
      irpc.url = environment.rpcUrl + "rpc";
    } else {
      irpc.url = environment.ssrRpcUrl + "rpc";
    }
    if (irpc.authToken !== null && irpc.authToken !== undefined) {
      const decodedToken = new JwtHelperService().decodeToken(irpc.authToken);
      console.log("TODO add roles to service from this", decodedToken);
      //if (decodedToken.roles !== undefined)
      //  roleService.setRightFromStringList(JSON.parse(decodedToken.roles));
    }
    //console.log(decodedToken);
    irpc.log = !environment.production;
  }
}
