import { Component, OnInit, Host } from "@angular/core";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { environment } from "../../../../../../../../environments/environment";
import { L } from "@ic2/ic2-lib";

@Component({
  selector: "app-action-client-attente-virement",
  templateUrl: "./action-client-attente-virement.component.html",
  styleUrls: ["./action-client-attente-virement.component.scss"],
})
export class ActionClientAttenteVirementComponent implements OnInit {
  montantVirement: number = null;
  idFacture: number = null;
  rpcUrl;
  constructor(public actionLayoutComp: ActionLayoutComponent) {
    this.rpcUrl = environment.rpcUrl;
  }

  ngOnInit() {
    this.actionLayoutComp.parentComp.dataLoaded.subscribe((data) => {
      if (data.factureList.length > 0) {
        var derniereFacture = data.factureList.reduce((a, b) => {
          return a.dateCreation > b.dateCreation ? a : b;
        });
        this.montantVirement = derniereFacture.montantTTC;
        this.idFacture = derniereFacture.id;
      } else {
        L.e("Impossible de trouver la dernière facture ?");
      }
    });
  }
}
