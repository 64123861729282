// DB ENUM com.scanha.metier.project.entities.TypologieBatiment generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class TypologieBatiment {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ENTREPOT = new TypologieBatiment(3, "Entrepôt", "ENTREPOT");
  static readonly BATIMENT_PUBLIC = new TypologieBatiment(5, "Bâtiment public ", "BATIMENT_PUBLIC");
  static readonly MAISON = new TypologieBatiment(14, "Maison", "MAISON");
  static readonly IMMEUBLE = new TypologieBatiment(15, "Immeuble", "IMMEUBLE");
  static readonly AUTRE = new TypologieBatiment(16, "Autre", "AUTRE");

  static readonly values = [
    TypologieBatiment.ENTREPOT, 
    TypologieBatiment.BATIMENT_PUBLIC, 
    TypologieBatiment.MAISON, 
    TypologieBatiment.IMMEUBLE, 
    TypologieBatiment.AUTRE 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of TypologieBatiment.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of TypologieBatiment.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.TypologieBatiment'] = TypologieBatiment;
reverseMapping[TypologieBatiment.name] = 'com.scanha.metier.project.entities.TypologieBatiment';
