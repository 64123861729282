import { Component, OnInit, Inject, Host } from "@angular/core";
import { ScanhaUserService } from "../../../../../ic2/services/ScanhaUserService";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { UserEditValidators } from "../../../user-edit-form/user-edit.validators";
import { Address } from "../../../../../ic2/entities/Address";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { FicheProjet, ScanhaRight } from "../../../../../ic2/entities/entities";

@Component({
  selector: "app-action-admin-creation-compte",
  templateUrl: "./action-admin-creation-compte.component.html",
  styleUrls: ["./action-admin-creation-compte.component.scss"],
})
export class ActionAdminCreationCompteComponent implements OnInit {
  loading: boolean;
  submitted: boolean;
  error: string = null;
  success: string = null;
  ficheProjet: FicheProjet;
  address: UntypedFormGroup = this.fb.group({
    ligne: [null, [Validators.required]],
    ligne2: [null, []],
    cp: [null, [Validators.required]],
    ville: [null, [Validators.required]],
    pays: [null, [Validators.required]],
  });
  form: UntypedFormGroup = this.fb.group({
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    tel: [null, [Validators.required]],
    societe: [null, []],
    fonction: [null, []],
    siret: [null, [UserEditValidators.siretValide]],
    prixAuScan: [false, []],
    address: this.address,
  });

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ScanhaUserService) public scanhaUserService: ScanhaUserService,
    public actionLayoutComp: ActionLayoutComponent
  ) {}

  ngOnInit() {
    this.actionLayoutComp.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;

      this.form.patchValue({
        firstName: this.ficheProjet.projet.prenomDO,
        lastName: this.ficheProjet.projet.nomDO,
        email: this.ficheProjet.projet.email,
        tel: this.ficheProjet.projet.tel,
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.form.valid) return;
    this.loading = true;
    let val = this.form.value;
    let address = Object.assign(new Address(), val.address);
    //console.log(val, address);
    this.scanhaUserService
      .createUser(
        val.email,
        val.tel,
        val.firstName,
        val.lastName,
        val.societe,
        val.fonction,
        val.siret,
        address,
        ScanhaRight.DONNEUR_DORDRE,
        val.prixAuScan,
        this.ficheProjet.projet.id
      )
      .subscribe(
        (voi) => {
          this.success = "Compte bien créé";
          setTimeout(() => {
            this.loading = false;
            this.actionLayoutComp.parentComp.reload();
            this.form.reset();
            this.submitted = false;
            this.success = null;
          }, 2000);
        },
        (err) => {
          this.error = err.message;
          this.loading = false;
        }
      );
  }
}
