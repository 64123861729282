// com.scanha.metier.project.service.ProjetServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { Projet } from '../entities/Projet';
import { FicheProjet } from '../entities/FicheProjet';
import { AdminProjetDTO } from '../entities/AdminProjetDTO';
import { ClientProjetDTO } from '../entities/ClientProjetDTO';
import { AdminProjetFilter } from '../entities/AdminProjetFilter';

@Injectable({
  providedIn: 'root',
})
export class ProjetService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getProjets(page: number, nbPerPage: number): RpcRequestBuilder<ListWithCount<ClientProjetDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetService.getProjets';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getProjetsAdmin(page: number, nbPerPage: number, filter: AdminProjetFilter): RpcRequestBuilder<ListWithCount<AdminProjetDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetService.getProjetsAdmin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.scanha.metier.project.dto.AdminProjetFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getProjet(id: number): RpcRequestBuilder<FicheProjet> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetService.getProjet';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.scanha.metier.project.dto.FicheProjet');
  }

  editProjet(p: Projet): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetService.editProjet';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(p, 'com.scanha.metier.project.entities.Projet', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
