<h1>Conditions générales de ventes - Place de marché scanha.fr</h1>

<h2>Préambule</h2>
<p>La société SCANhA, SASU de droit français au capital de 1&nbsp;000&nbsp;€ (euros), dont le siège social est situé 2
  Rue Saint Dominique, Pépinière technologique, 51000 CHÂLONS-EN-CHAMPAGNE, immatriculée au Registre du commerce et des
  sociétés de Châlons-en-Champagne sous le numéro 844 543 454, a mis en place sur le site <a href="https://scanha.fr/"
    target="_blank">www.scanha.fr</a> une Place de marché permettant la mise en relation des Clients Professionnels
  avec des Scan Opérateurs du domaine du BTP et des dessinateurs dans une démarche BIM. Cette activité est ci-après
  désignée «&nbsp;le Service&nbsp;» ou encore «&nbsp;la Place de marché&nbsp;». Le Client est toute personne physique
  ou morale, qui commande un ou plusieurs scan(s), et ses prestations associées (conception de plans 2D, modélisation
  de maquette 3D), de bâtiment par l’intermédiaire de la Place de marché. Le Scan Opérateur est toute personne physique
  ou morale qui s’inscrit sur le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a> dans le but de
  fournir des services de scan, et ses prestations associées (conception de plans 2D, modélisation de maquette 3D), de
  bâtiment, par l’intermédiaire de la Place de Marché. Les présentes conditions générales de la Place de marché
  SCANHA.FR comprennent&nbsp;:</p>
<ul>
  <li>les conditions d’utilisation du Service applicables au Client, qui ont vocation à régir les relations entre ce
    dernier et la société SCANhA,</li>
  <li>les conditions générales de prestation de services, qui ont vocation à régir les relations entre le Client et le
    Scan Opérateur.</li>
</ul>

<h2>Conditions d'utilisation du service applicables au client</h2>
<h3>1. Objet</h3>
<p>Les présentes conditions ont pour objet de définir les modalités selon lesquelles la société SCANhA met à la
  disposition des Clients, dans le cadre du Service, des outils technologiques permettant de commander des scans, et
  ses potentielles prestations associées (conception de plans 2D, modélisation de maquette 3D), auprès de Scans
  opérateurs. Pour utiliser le Service, le Client accepte de se soumettre aux présentes conditions générales
  d’utilisation. Cette acceptation se matérialise par un clic de validation lors de la commande. En vue de la
  conclusion du contrat résultant de l’acceptation des présentes conditions générales, le Client déclare jouir de sa
  pleine capacité juridique et des pouvoirs nécessaires pour conclure celui-ci.</p>
<h3>2. Description du service</h3>
<p>Le Service est constitué d’un ensemble d’outils permettant aux Clients d’être mis en relation avec des Scans
  Opérateurs en vue de commander des scans, et ses potentielles prestations associées (conception de plans 2D,
  modélisation de maquette 3D), de payer le prix, de recevoir ou télécharger un dossier de prestations et de
  communiquer avec ces derniers à l’aide d’un outil de messagerie mis à leur disposition. Lorsque la commande du Client
  a pour objet un ou plusieurs scans, et ses potentielles prestations associées (conception de plans 2D, modélisation
  de maquette 3D), proposés par un Scan Opérateur autre que la société SCANhA, le contrat résultant de cette commande
  est conclu directement entre le Client et ledit Scan Opérateur, en sorte que ce dernier est seul responsable de
  l’exécution de la prestation choisie. En tant que tiers à ce contrat, la société SCANhA décline toute responsabilité
  au titre des obligations du Scan Opérateur. Toute réclamation sera donc transmise à ce dernier, à qui il appartiendra
  d’y répondre.</p>
<h3>3. Accès au service</h3>
<p>Le Client navigue librement sur le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a>. L’accès au
  Service s’effectue en cliquant directement sur l’interface dédiée à la Place de marché. Une fois la commande de
  scans, et ses potentielles prestations associées (conception de plans 2D, modélisation de maquette 3D), passée par le
  Client, ce dernier reçoit dans la confirmation de commande, un identifiant et un mot de passe lui permettant
  d’accéder à son compte Client. Le Client s’engage à les conserver secrets et à ne les divulguer à aucun tiers. Il est
  seul responsable de l’accès au Service grâce à ses identifiants et mot de passe, sauf preuve d’une utilisation
  frauduleuse qui ne lui serait pas imputable. En cas de perte, de détournement ou d’utilisation frauduleuse de son
  identifiant et/ou mot de passe, il s’engage à avertir immédiatement le service commercial de la société SCANhA.</p>
<h3>4. Utilisation du service par le client</h3>
<p>Le Client s’interdit de détourner ou de tenter de détourner les fonctionnalités du Service à des fins autres que
  celles décrites à l’article 2 des présentes conditions. Le Client est seul responsable de l’utilisation qu’il fait du
  Service, notamment des appréciations qu’il fait sur le site, et s’engage à garantir à première demande et indemniser
  la société SCANhA de tout dommage, perte, manque à gagner que cette dernière pourrait subir du fait de cette
  utilisation du Service par le Client.</p>
<h3>5. Prix du service</h3>
<p>L’utilisation du Service par le Client est gratuite (hors coûts éventuels de la connexion, dont le prix dépend des
  conditions conclues avec l’opérateur de communication électronique). Seule la commande de scans, et ses potentielles
  prestations associées (conception de plans 2D, modélisation de maquette 3D), est payante, selon les modalités prévues
  dans les conditions générales de prestation de services.</p>
<h3>6. Données personnelles</h3>
<p>Les informations et données délivrées par le Client dans le cadre du Service sont traitées par la société SCANhA et
  nécessaires à la gestion de son compte, ainsi qu’à la réalisation des scans, et ses potentielles prestations
  associées (conception de plans 2D, modélisation de maquette 3D), commandés. Les informations sont transmises aux
  Scans Opérateurs à cette seule fin. Ces informations et données sont également conservées à des fins de sécurité,
  afin de respecter les obligations légales et réglementaires incombant, le cas échéant, à la société SCANhA, ainsi que
  pour lui permettre d’améliorer et de personnaliser les services proposés aux utilisateurs de la Place de marché. La
  société SCANhA est autorisée, en cas de présomption d’acte répréhensible ou s’il y a lieu de suspecter un usage
  abusif de la Place de marché, à communiquer les données du participant concerné aux autorités d’investigation
  compétentes. Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
  libertés, le Client peut exercer son droit d’accès, d’opposition, de rectification et de suppression des données le
  concernant en s’adressant à la société SCANhA, 2 Rue Saint Dominique, Pépinière technologique, 51000 CHALONS EN
  CHAMPAGNE, Tel&nbsp;: 03&nbsp;26&nbsp;24&nbsp;91&nbsp;33, Email&nbsp;: aurelie.husson@scanha.fr. Toute demande
  présentée à ce titre devra être accompagnée de la photocopie d’un titre d’identité portant la signature du demandeur
  et préciser l’adresse à laquelle doit parvenir la réponse. Une réponse sera alors adressée dans un délai maximum de
  deux mois suivant la réception de la demande.</p>
<h3>7. Responsabilité & Assurance</h3>
<p>La société SCANhA est tenue à une obligation de moyens dans le cadre de l’utilisation du Service par les Clients. En
  conséquence, la responsabilité de la société SCANhA ne peut être engagée qu’en cas de démonstration d’une faute
  effective de sa part ayant causé un préjudice au Client. La société SCANhA déclare à cet égard avoir souscrit une
  assurance couvrant les conséquences d'un engagement de sa responsabilité auprès d’une compagnie honorablement connue
  et notoirement solvable et s’engage à fournir tout justificatif de celle-ci à première demande. Compte tenu des
  spécificités du réseau internet, la société SCANhA ne peut offrir aucune garantie de continuité du Service. Sa
  responsabilité ne peut, dès lors, être engagée en cas de dommage lié à l’impossibilité temporaire d’accéder à la
  Place de marché ou aux services associés à celle-ci, du fait notamment d’un dysfonctionnement ou d’une saturation du
  réseau internet. D’une manière générale, la société SCANhA ne saurait voir sa responsabilité engagée dans les cas
  suivants&nbsp;:</p>
<ul>
  <li>intrusion et/ou intervention malveillante de tiers,</li>
  <li>détournement éventuel des données échangées par email ou des identifiants du Client,</li>
  <li>dysfonctionnement ou indisponibilité provenant d’un cas de force majeure.</li>
</ul>
<p>Il est par ailleurs rappelé que la société SCANhA n’est pas responsable des dommages qui résulteraient d’une
  information erronée ou incomplète de la part du Client, notamment dans le cadre de sa commande, ou encore d’une
  mauvaise utilisation du Service par ce dernier. En outre, conformément à l’article 6 I 1°, 2° et 3° de la loi n°
  2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, la société SCANhA ne saurait voir sa
  responsabilité engagée à raison des contenus rendus disponibles sur le site par les Clients, notamment en ce qui
  concerne les appréciations que ces derniers font des Scans Opérateurs, sauf si elle ne les rendait pas promptement
  inaccessibles après avoir été informée de leur illicéité dans les conditions prévues par cette loi. La société SCANhA
  se dégage de toute responsabilité quant au contenu des sites édités par des tiers que le Client pourrait être amené à
  consulter à partir des liens existant sur le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a>,
  ainsi qu’aux informations et services diffusés par ces liens.</p>
<h3>8. Sécurisations</h3>
<p>Le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a> fait l’objet d’un système de sécurisation
  pour protéger le plus efficacement possible toutes les données sensibles liées aux moyens de paiement utilisés sur le
  Service.</p>
<h3>9. Propriété intellectuelle</h3>
<p>Tout le contenu du présent sur le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a>, incluant, de
  façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur
  mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à
  d’autres sociétés partenaires ou auteurs.</p>
<p>Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces
  différents éléments est strictement interdite sans l’accord express par écrit de SCANha. Cette représentation ou
  reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et
  suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon
  pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés
  pourraient intenter une action en justice à votre encontre.</p>
<h3>10. Dispositions générales</h3>
<p>Toute modification des présentes conditions générales pourra être effectuée par la société SCANhA. La modification
  s’appliquera automatiquement à la date d’entrée en vigueur indiquée dans la notification de cette modification par
  email ou sur le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a>. Au cas où l'une quelconque des
  stipulations des présentes conditions générales serait déclarée nulle ou contraire à une disposition d'ordre public,
  elle sera réputée non écrite et toutes les autres stipulations resteront en vigueur et conserveront leur plein effet.</p>
<h3>11. Loi applicable et traitement des réclamations au titre du service</h3>
<p>Les présentes conditions générales sont soumises au droit français. Tout litige relatif à leur interprétation et/ou
  leur exécution relève des juridictions françaises. Conformément à l’article L. 612-1 du Code de la consommation, la
  société SCANhA propose aux Clients un service de médiation pour les litiges de consommation liés à l’utilisation du
  Service&nbsp;:</p>
<ul>
  <li>soit par email&nbsp;: aurelie.husson@scanha.fr</li>
  <li>soit par courrier postal&nbsp;: SCANhA, 2 Rue Saint Dominique, Pépinière technologique, 51000 CHALONS EN
    CHAMPAGNE</li>
</ul>

<h2>Conditions générales de prestation de services</h2>
<h3>1. Objet</h3>
<p>Les présentes conditions générales s’appliquent à toute commande de scans, et ses potentielles prestations associées
  (conception de plans 2D, modélisation de maquette 3D), effectuée par un Client auprès d’un Scan Opérateur par
  l’intermédiaire du Service. Il est rappelé à cet égard que lorsque la commande du Client a pour objet un ou plusieurs
  scans, et ses potentielles prestations associées (conception de plans 2D, modélisation de maquette 3D), proposés par
  un Scan Opérateur autre que la société SCANhA, le contrat résultant de cette commande est conclu directement entre le
  Client et ledit Scan Opérateur, en sorte que ce dernier est seul responsable de l’exécution de la prestation choisie.
  En tant que tiers à ce contrat, la société SCANhA décline toute responsabilité au titre des obligations du Scan
  Opérateur. Toute réclamation sera donc transmise à ce dernier, à qui il appartiendra d’y répondre. A l’égard du
  Client, les présentes conditions viennent en complément des conditions d’utilisation du Service également applicables
  à ce dernier. Elles sont acceptées par le Client à chaque commande, selon les modalités définies ci-après. En vue de
  la conclusion du contrat résultant de l’acceptation des présentes conditions générales, le Client déclare jouir de
  ses pleines capacités juridiques et des pouvoirs nécessaires pour conclure celui-ci.</p>
<h3>2. Commance à l'aide du service</h3>
<p>La commande de scans, et ses potentielles prestations associées (conception de plans 2D, modélisation de maquette
  3D), à l’aide du Service s’effectue selon les étapes suivantes&nbsp;:</p>
<p>1) Le Client renseigne à l’aide du Service les informations relatives au bien qu’il souhaite faire étudier, ainsi
  qu’à la nature des relevés numériques à réaliser.</p>
<p>2) Le Client renseigne ses coordonnées, ainsi que celles du bien à étudier.</p>
<p>3) SCANha génère un devis et le transmet au Client dans les 48 heures.</p>
<p>4) Il confirme son choix, prend connaissance et accepte les présentes conditions par un clic de validation.</p>
<p>5) Le Client procède au règlement de sa commande.</p>
<p>6) Le Client reçoit un email ou un courrier de confirmation de l’enregistrement de sa commande.</p>
<p>7) La Service sélectionne le Scan opérateur adapté à la demande du Client, permettant à ce dernier de connaître
  l’identité du Scan Opérateur.</p>
<p>8) Le Scan Opérateur est informé par la société SCANhA de la commande du Client.</p>
<p>9) Le Client et le Scan Opérateur fixent un rendez-vous. A ce titre, si le Client est un consommateur et qu’il
  souhaite fixer un rendez-vous avant la fin du délai de rétractation mentionné à l'article L. 221-18 du Code de la
  consommation, il en fait la demande expresse.</p>
<p>Les étapes 1) à 9) ci-dessus décrites peuvent être traitées par le Client soit directement sur le site <a href="https://scanha.fr/"
    target="_blank">www.scanha.fr</a>, soit par l’intermédiaire de l’assistance téléphonique mise en place dans le
  cadre du Service.</p>
<h3>3. Informations communiquées par le client</h3>
<p>Les offres présentées par la Place de marché le sont en fonction des informations fournies par le Client à la
  commande. Il revient donc à ce dernier de s’assurer que ces informations sont exactes et exhaustives. Toute
  conséquence d’une information incomplète, manquante ou erronée imputable au Client sera de la responsabilité de ce
  dernier, qui ne pourra dès lors rechercher la responsabilité du Scan Opérateur. Toute modification des données
  fournies par le Client (notamment en ce qui concerne la nature des scans, et ses potentielles prestations associées
  (conception de plans 2D, modélisation de maquette 3D), à réaliser, la surface réelle étudiée) pourra par ailleurs
  donner lieu à une modification de l’offre de choisie par ce dernier à la date effective de réalisation de la commande
  à l’adresse du bien à étudier, à moins que le Scan Opérateur ne soit contraint d’annuler son intervention compte tenu
  des nouvelles données fournies par le Client.</p>
<h3>4. Prix & Code promotionnel</h3>
<b>4.1 Prix</b>
<p>Le prix des offres de scans, et ses potentielles prestations associées (conception de plans 2D, modélisation de
  maquette 3D), présentées par la Place de marché est fixé librement par les Scans Opérateurs. Il s’entend en euros,
  toutes taxes comprises, le prix dû par le Client est celui accepté par ce dernier à la commande. Sauf conditions
  particulières, ce prix est payé en intégralité à la commande par carte bancaire (dans le cadre d’un système de
  paiement sécurisé) via Stripe. En cas de défaut de paiement, le Scan Opérateur est en droit de suspendre ses
  prestations. Tout défaut de paiement, quelle qu’en soit la cause, entraîne en outre à l’égard des Clients
  professionnels, l’exigibilité de plein droit d’intérêts de retard calculée à compter du jour suivant la date
  d’échéance sur la base du taux d’intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement
  la plus récente majorée de 10 points, ainsi que d’une indemnité forfaitaire pour frais de recouvrement de 40 Euros,
  sans préjudice de toute indemnité complémentaire.</p>
<b>4.2 Code promotionnel</b>
<p>Dans le cadre d’opérations commerciales ou partenariats, un code promotionnel peut être utilisé sous certaines
  modalités. Celui-ci est applicable dans le cadre de la commande. Dans le cadre de la validation de la commande, ce
  code sera appliqué sur le montant total des scans, et ses potentielles prestations associées (conception de plans 2D,
  modélisation de maquette 3D), à payer. Nous nous réservons le droit de refuser tout code promotionnel non valable ou
  ayant expiré pour toute commande.</p>
<h3>5. Indisponibilité du client</h3>
<p>En cas d’indisponibilité, le Client peut annuler ou modifier, sans frais, la date et/ou heure du rendez-vous fixé en
  vue de la réalisation du ou des scans, et ses potentielles prestations associées (conception de plans 2D,
  modélisation de maquette 3D), commandés à l’aide du Service jusqu’à 48 heures* avant celui-ci. Il est toutefois
  précisé ici que cette modification peut entraîner une modification du prix de la commande, en fonction des tarifs
  proposés aux nouvelles date et heure qui seront choisies par le Client. En cas de demande de modification par le
  Client des dates et/ou heures du rendez-vous fixé en vue de la réalisation du ou des scans, et ses potentielles
  prestations associées (conception de plans 2D, modélisation de maquette 3D), commandés à l’aide du Service moins de
  48 heures avant celui-ci, le prix dû par ce dernier sera majoré d’un complément de 80 euros TTC.</p>
<p>*soit 48h avant l'heure de début du créneau choisi</p>
<h3>6. Indisponibilité du scan opérateur</h3>
<p>En cas d’indisponibilité du Scan Opérateur, celui-ci s’engage à en informer le Client, sans délai, afin de convenir
  de nouvelles modalités d’intervention. A défaut d’accord du Client, la commande sera annulée sans frais pour ce
  dernier et les sommes versées à la commande lui seront intégralement restituées. Dans l’hypothèse d’une telle
  annulation, le Scan Opérateur s’engage à prendre intégralement à sa charge la rémunération due à la société SCANhA.</p>
<h3>7. Dossier de prestations – Facture</h3>
<p>Le Scan Opérateur prévient le Client du délai de mise à disposition du dossier de prestations et de la facture et
  veille à respecter celui-ci. Ce délai sera exprimé en jour ouvré à compter du rendez-vous au cours duquel le ou les
  scans, et ses potentielles prestations associées (conception de plans 2D, modélisation de maquette 3D), auront été
  réalisés et sous réserve de la réception de l’entier paiement du prix.</p>
<h3>8. Conservation du contrat</h3>
<p>Conformément aux dispositions de l’article L. 213-1 du Code de la consommation, le Scan Opérateur s’engage à
  conserver pendant un délai de 10 ans les données relatives aux contrats conclus avec les Clients et en garantir à
  tout moment l’accès à ces derniers.</p>
<h3>9. Droit de rétractation</h3>
<p>Lorsque le Client est un consommateur, il dispose d’un délai de 14 jours pour exercer le droit de rétractation prévu
  aux articles L. 221-18 et suivants du Code de la consommation à compter de la conclusion du contrat selon les
  modalités définies à l’article 2 ci-dessus. Le droit de rétractation peut être exercé en informant le Scan Opérateur
  de sa décision de se rétracter par l’envoi, avant l’expiration du délai rappelé ci-dessus, du formulaire de
  rétractation figurant en annexe des présentes conditions ou de toute autre déclaration dénuée d’ambiguïté exprimant
  la volonté du consommateur de se rétracter. Lorsque le droit de rétractation ci-dessus visé est exercé, la société
  SCANha, en sa qualité de tiers de confiance, procède au remboursement des sommes versées par le Client au plus tard
  dans les 14 jours à compter de la date à laquelle elle est informée de la décision du consommateur de se rétracter.
  Ce remboursement est effectué en utilisant le même moyen de paiement que celui utilisé par le consommateur pour la
  transaction initiale, sauf accord express de ce dernier pour qu’un autre moyen de paiement soit utilisé. Par
  dérogation à ce qui précède, il est toutefois convenu que si, lors de la commande, le consommateur a formulé une
  demande expresse en vue de la réalisation du ou des scans, et ses potentielles prestations associées (conception de
  plans 2D, modélisation de maquette 3D), choisis avant la fin du délai de rétractation mentionné ci-dessus et qu’il
  exerce ensuite son droit de rétractation, il reste tenu de payer au Scan Opérateur un montant correspondant au
  service fourni jusqu’à la communication de sa décision de se rétracter ; ce montant est proportionné au prix total de
  la prestation convenu dans le contrat.</p>
<h3>10. Opposition au démarchage téléphonique</h3>
<p>En application de l’article L.223-1 du Code de Consommation, si, en dehors de sa relation contractuelle avec SCANhA,
  d’une manière générale le Client ne souhaite pas faire l’objet de prospection commerciale par voie téléphonique, il
  peut gratuitement s’inscrire sur une liste d’opposition au démarchage téléphonique accessible via le site&nbsp;: <a
    href="http://www.bloctel.gouv.fr/" target="_blank">www.bloctel.gouv.fr</a>.</p>
<h3>11. Obligations du scan opérateur</h3>
<p>Le Scan Opérateur, qui agit en qualité de professionnel, s’engage à respecter l’ensemble des lois et règlements
  applicables à son activité.</p>
<h3>12. Obligations du client</h3>
<p>Le Client, ou toute personne mandatée pour le représenter, fournit les renseignements exacts et utiles à l’exécution
  des prestations du Scan Opérateur. Le Client, ou toute personne mandatée pour le représenter, doit payer le prix du
  ou des scans, et ses potentielles prestations associées (conception de plans 2D, modélisation de maquette 3D),
  commandés, selon les modalités visées à l’article 3. Le Scan Opérateur se réserve le droit de refuser une commande
  pour laquelle le Client ne se serait pas acquitté du prix. Le Client, ou toute personne mandatée pour le représenter,
  doit être présent aux dates et heure convenues pour permettre au Scan Opérateur l’accès à au bâtiment et pour
  l’accompagner au cours de l’exécution de ses prestations. Le Client doit mettre à disposition du Scan Opérateur les
  moyens techniques et d’accessibilité nécessaires à l’exécution de ses prestations. Le Scan Opérateur se réserve le
  droit de refuser d’honorer une commande relative à un bâtiment qui présenterait un danger et pour laquelle le Client
  ne consentirait pas à prendre les mesures de sécurité recommandées par le Scan Opérateur. Dans cette hypothèse, le
  prix de la prestation initialement convenue reste dû par le Client.</p>
<h3>13. Responsabilité - Assurance</h3>
<p>Le Scan Opérateur est tenu à une obligation de moyens au titre des prestations fournies au Client dans le cadre du
  Service. En conséquence, la responsabilité du Scan Opérateur ne peut être engagée qu’en cas de démonstration d’une
  faute effective de sa part ayant causé un préjudice au Client. Le Scan Opérateur déclare à cet égard avoir souscrit
  une assurance permettant de couvrir les conséquences d'un engagement de sa responsabilité en raison de ses
  interventions, et s’engage à fournir tout justificatif de celle-ci à première demande.</p>
<p>La responsabilité du Scan Opérateur ne peut pas être engagée en cas d'inexécution ou de mauvaise exécution du
  contrat due soit au fait du Client, soit à un cas de force majeure, et notamment dans le cas où l’accès au bâtiment
  est rendu impossible en raison de l’absence du Client aux date et heure convenues. La responsabilité du Scan
  Opérateur ne saurait être recherchée de quelque manière que ce soit pour une quelconque erreur ou omission d’une
  prestation effectuée hors du cadre de sa mission de scans, et ses potentielles prestations associées (conception de
  plans 2D, modélisation de maquette 3D).</p>
<h3>14. Données personnelles</h3>
<p>Les informations et données délivrées par le Client dans le cadre de sa commande sont transmises au Scan Opérateur
  aux seules fins de traitement de ladite commande. Elles ne peuvent en aucun cas être utilisées par ce dernier à
  d’autres fins. Le Scan Opérateur s’engage à assurer la sécurité des données à caractère personnel traitées aux fins
  ci-dessus rappelées. Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
  libertés, le Client peut exercer son droit d’accès, d’opposition, de rectification et de suppression des données le
  concernant en s’adressant au Scan Opérateur. Toute demande présentée à ce titre devra être accompagnée de la
  photocopie d’un titre d’identité portant la signature du demandeur et préciser l’adresse à laquelle doit parvenir la
  réponse. Une réponse sera alors adressée dans un délai maximum de deux mois suivant la réception de la demande.</p>
<h3>15. Litiges - Contestations</h3>
<p>À tout moment, le Client a la possibilité de contacter le Scan Opérateur et de lui adresser toute demande ou
  réclamation en utilisant l’outil de messagerie mis à disposition dans le cadre du Service. Les litiges sont
  directement réglés entre le Client et le Scan Opérateur, qui s’efforceront de résoudre leurs différends amiablement.
  Conformément à l’article L612-1 du Code de la consommation, il est proposé aux Clients, ayant la qualité de
  consommateur, un service de médiation pour les litiges de consommation liés à l’exécution des prestations de scans,
  et ses potentielles prestations associées (conception de plans 2D, modélisation de maquette 3D), commandés à l’aide
  du Service&nbsp;:</p>
<ul>
  <li>soit par email&nbsp;: aurelie.husson@scanha.fr</li>
  <li>soit par courrier postal&nbsp;: SCANhA, 2 Rue Saint Dominique, Pépinière technologique, 51000 CHALONS EN
    CHAMPAGNE</li>
</ul>
<h3>16. Loi applicable</h3>
<p>Les présentes conditions générales sont soumises au droit français. Tout litige relatif à leur interprétation et/ou
  à leur exécution relève des juridictions françaises. Toutes contestations ou litiges qui pourraient naitre de
  l’interprétation ou de l’exécution de ces Conditions Générales seront de la compétence exclusive des tribunaux dont
  dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le
  français.</p>
<h3>17. Dispositions générales</h3>
<p>Les présentes conditions générales pourront être modifiées à tout moment, en tout ou en partie. La modification
  s’appliquera automatiquement à la date d’entrée en vigueur indiquée dans la notification de cette modification par
  email ou sur le site <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a>. Au cas où l'une quelconque des
  stipulations des présentes conditions générales serait déclarée nulle ou contraire à une disposition d'ordre public,
  elle sera réputée non écrite et toutes les autres stipulations resteront en vigueur et conserveront leur plein effet.</p>
