import {
  Component,
  OnInit,
  Input,
  Host,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { FicheProjet, Devis } from "../../../../../ic2/entities/entities";
import { UntypedFormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { environment } from "../../../../../../../../environments/environment";
import { PaiementService } from "../../../../../ic2/services/PaiementService";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { JSONSerializer, L } from "@ic2/ic2-lib";

@Component({
  selector: "app-action-client-paiement",
  templateUrl: "./action-client-paiement.component.html",
  styleUrls: ["./action-client-paiement.component.scss"],
})
export class ActionClientPaiementComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() acompte: boolean = false;
  virement: boolean = false;
  ficheProjet: FicheProjet;
  paiementCB: boolean = false;
  showCryptoHelp: boolean = false;
  loading: boolean = false;
  @ViewChild("cardInfo") cardInfo: ElementRef;
  cardHandler = this.onChange.bind(this);
  card: any;
  error: string;
  success: string;
  stripe: any;
  dernierDevis: Devis;
  montantHT: number;
  montantTTC: number;
  paypal: boolean;
  payPalConfig?: IPayPalConfig;
  recette: boolean = environment.name !== "PRODUCTION";

  /*currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
  form: FormGroup = this.fb.group({
    numCarteBleue: [null, [Validators.required,Validators.pattern('[0-9. ]+')]],
    mois: [null, [Validators.required, Validators.min(1), Validators.max(12)]],
    annee: [null, [Validators.required, Validators.min(this.currentYear), Validators.max(this.currentYear + 10)]],
    cvc: [null, [Validators.required, Validators.min(0)]],
  });*/

  constructor(
    public actionLayoutComp: ActionLayoutComponent,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(PaiementService) private paiementService: PaiementService,
    private cd: ChangeDetectorRef,
    private _ngZone: NgZone
  ) {}

  ngOnInit() {
    this.actionLayoutComp.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
      var dernierDevis = data.devisList.reduce((a, b) => {
        return a.dateCreation > b.dateCreation ? a : b;
      });
      this.dernierDevis = dernierDevis;
      this.paiementService
        .getMontantAPayer(this.ficheProjet.projet.id, this.acompte)
        .subscribe(
          (data) => {
            this.montantHT = data.montantHT;
            this.montantTTC = data.montantTTC;
            this.initConfig();
          },
          (err) => {
            this.error = err.message;
          }
        );
    });
  }

  private initConfig(): void {
    this.stripe = Stripe(environment.stripePublishableKey); // use your test publishable key

    this.payPalConfig = {
      currency: "EUR",
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "EUR",
                value: this.montantTTC + "",
              },
              items: [
                {
                  name: this.ficheProjet.projet.nom,
                  quantity: "1",
                  category: "PHYSICAL_GOODS",
                  unit_amount: {
                    currency_code: "EUR",
                    value: +this.montantTTC.toFixed(2) + "", //correctif #658 car le montant total dans breakdown (plus haut) n'était pas = a la somme, j'ai du ré-arrondir ici
                  },
                },
              ],
            },
          ],
          application_context: {
            shipping_preference: "NO_SHIPPING", //pour éviter de permettre a l'utilisateur de changer l'adresse de livraison dans le popup paypal
            brand_name: "Scanha",
          },
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        layout: "horizontal",
        tagline: false,
        //fundingicons: false,
      },
      onApprove: (data, actions) => {
        /*console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });*/
      },
      onClientAuthorization: (data) => {
        console.log(
          "onClientAuthorization - you should probably inform your server about completed transaction at this point",
          data
        );
        /* AVANT MIGRATION, DATA RESSEMBLAIT A ça :
          data = 
        intent: "sale"
        orderID: "EC-03W5618555918152S"
        payerID: "4C3248Y5YGSLL"
        paymentID: "PAY-85R830890V0995445LQSOWWA"
        paymentToken: "EC-03W5618555918152S"
        returnUrl: "https://www.sandbox.paypal.com/?paymentId=PAY-85R830890V0995445LQSOWWA&token=EC-03W5618555918152S&PayerID=4C3248Y5YGSLL"
        */

        this.paiementService
          .paiementPaypal(this.ficheProjet.projet.id, data)
          .subscribe(
            (v) => {
              this.success = "Paiement réussi";
              setTimeout(() => {
                this.loading = false;
                this.success = null;
                this.actionLayoutComp.parentComp.reload();
              }, 2000);
            },
            (err) => {
              this.error = err.message;
            }
          );
      },
      onCancel: (data, actions) => {
        console.log("OnCancel", data, actions);
        //this.showCancel = true;
      },
      onError: (err) => {
        console.error("OnError", err);
        /*this.zone.run(() => {
          this.paymentDataService.step = PaymentDataService.STEP_ERROR;
        });*/
      },
      onClick: (data, actions) => {
        console.log("onClick", data, actions);
        //this.resetStatus();
      },
    };

    /*
    let paypalEnv = null;
    let client = {} as IPaypalClient;
    if (environment.production) {
      paypalEnv = PayPalEnvironment.Production;
      client.production = environment.paypalClientId;
    } else {
      paypalEnv = PayPalEnvironment.Sandbox;
      client.sandbox = environment.paypalClientId;
    }
    this.payPalConfig = new IPayPalConfig(
      PayPalIntegrationType.ClientSideREST,
      paypalEnv,
      {
        commit: true,
        client: client,
        button: {
          label: "paypal",
        },
        onPaymentComplete: (data, actions) => {
          console.log("OnPaymentComplete", data, actions);
          /* data = 
        intent: "sale"
        orderID: "EC-03W5618555918152S"
        payerID: "4C3248Y5YGSLL"
        paymentID: "PAY-85R830890V0995445LQSOWWA"
        paymentToken: "EC-03W5618555918152S"
        returnUrl: "https://www.sandbox.paypal.com/?paymentId=PAY-85R830890V0995445LQSOWWA&token=EC-03W5618555918152S&PayerID=4C3248Y5YGSLL"
        */
    /*
          this.paiementService
            .paiementPaypal(this.ficheProjet.projet.id, data)
            .subscribe(
              (v) => {
                this.success = "Paiement réussi";
                setTimeout(() => {
                  this.loading = false;
                  this.success = null;
                  this.actionLayoutComp.parentComp.reload();
                }, 2000);
              },
              (err) => {
                this.error = err.message;
              }
            );
        },
        onCancel: (data, actions) => {
          console.log("OnCancel", data, actions);
        },
        onError: (err) => {
          console.log("OnError", err);
        },
        transactions: [
          {
            amount: {
              currency: "EUR",
              total: this.montantTTC,
            },
          },
        ],
      }
    );*/
  }
  modePaiementPaypal() {
    this.paypal = true;
    this.paiementCB = false;
    this.virement = false;
    this.cd.detectChanges();
  }
  modePaiementVirement() {
    this.paypal = false;
    this.paiementCB = false;
    this.virement = true;
    this.cd.detectChanges();
  }

  modePaiementCB() {
    this.paypal = false;
    this.paiementCB = true;
    this.virement = false;
    this.cd.detectChanges();

    //this._ngZone.runTask(() => {
    this.card = this.stripe.elements().create("card", {
      style: {
        base: {
          fontFamily: "Muli, sans-serif",
          fontSize: "19px",
          //'padding': '10px',
          //'color': '#C1C7CD',
        },
        invalid: {
          color: "red",
        },
      },
      hidePostalCode: true,
    });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener("change", this.cardHandler);
    //});
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener("change", this.cardHandler);
      this.card.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async validerPaiementCB(form: NgForm) {
    console.log(this.card, this.stripe);
    this.loading = true;
    this.card.update({
      disabled: true,
    });
    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      //console.log('Something is wrong:', error);
      this.card.update({
        disabled: false,
      });
      this.loading = false;
    } else {
      //console.log('Success!', token);
      this.paiementService
        .paiementStripe(this.ficheProjet.projet.id, token.id)
        .subscribe(
          (data) => {
            this.success = "Paiement réussi";
            setTimeout(() => {
              this.card.update({
                disabled: false,
              });
              this.loading = false;
              this.success = null;
              this.actionLayoutComp.parentComp.reload();
            }, 2000);
          },
          (err) => {
            this.error = err.message;
            this.card.update({
              disabled: false,
            });
            this.loading = false;
          }
        );
    }
  }

  validerPaiementVirement() {
    this.loading = true;
    this.paiementService.paiementVirement(this.ficheProjet.projet.id).subscribe(
      (data) => {
        this.success = "En attente du paiement par virement";
        setTimeout(() => {
          this.loading = false;
          this.success = null;
          this.actionLayoutComp.parentComp.reload();
        }, 2000);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
