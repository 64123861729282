// ENTITY com.ic2.entity.UserRelation generated by ic2 3.0.0-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserRelation {

  idUser: number | null = 0;
  idUserLie: number | null = 0;
  typeRelation: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.UserRelation'] = UserRelation;

reverseMapping[UserRelation.name] = 'com.ic2.entity.UserRelation';

fields['com.ic2.entity.UserRelation']  = {
    idUser: 'java.lang.Integer',    idUserLie: 'java.lang.Integer',    typeRelation: 'java.lang.Integer'};
