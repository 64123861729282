import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import {
  ScanhaRight,
  UserBundle,
  LoginResponse,
} from "../../ic2/entities/entities";
import { IRPC } from "@ic2/ic2-lib";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as moment from "moment";
import { CoreService } from "../../ic2/services/CoreService";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userBundle: UserBundle = null;
  _roles: ScanhaRight[] = [];
  // store the URL so we can redirect after logging in
  redirectUrl: string;
  timeoutId: number;

  constructor(
    private router: Router,
    public coreService: CoreService,
    public irpc: IRPC
  ) {}

  isLoggedIn(): Observable<boolean> {
    return Observable.create((observer) => {
      if (this.userBundle !== null) {
        observer.next(true);
        observer.complete();
        return;
      }
      if (!localStorage) return;
      let token = localStorage.getItem("token");
      //console.log(token);
      if (
        token === null ||
        token === undefined ||
        token === "undefined" ||
        token === "null"
      ) {
        observer.next(false);
        observer.complete();
        return;
      }
      this.irpc.authToken = token;

      //check que le token est tjr valable
      const decodedToken = new JwtHelperService().decodeToken(token);
      //console.log(decodedToken);
      const expires = new Date(decodedToken.exp * 1000);
      const compareTo = new Date();
      if (compareTo > expires) {
        //console.log('token is expired, disconnect user');
        localStorage.removeItem("token");
        this.irpc.authToken = null;
        observer.next(false);
        observer.complete();
        return;
      }

      //Recuperer le degineo bundle
      this.coreService.getBundle().subscribe(
        (data) => {
          this.bundleReceived(data);
          observer.next(true);
          observer.complete();
        },
        (error) => {
          this.irpc.authToken = null;
          console.error(error);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  bundleReceived(data: UserBundle) {
    this.userBundle = data;
    if (data.token) this.irpc.authToken = data.token;
    const decodedToken = new JwtHelperService().decodeToken(
      this.irpc.authToken
    );
    //console.log(decodedToken, ((decodedToken.exp * 1000) - new Date().getTime()) * 0.8);
    clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      console.log("retreiving new user token");
      this.coreService.getBundle().subscribe(
        (data) => {
          this.bundleReceived(data);
        },
        (error) => {
          this.irpc.authToken = null;
          console.error(error);
        }
      );
    }, (decodedToken.exp * 1000 - new Date().getTime()) * 0.8);
    this._roles = [];
    for (let right of this.userBundle.rights) {
      this._roles.push(ScanhaRight.mapFromId(right.idRight));
    }
    localStorage.setItem("token", this.irpc.authToken);
  }

  login(email: string, password: string): Observable<boolean> {
    return Observable.create((observer) => {
      this.coreService.login(email, password).subscribe(
        (data) => {
          console.log(data);
          this.bundleReceived(data.ub);
          observer.next(true);
          observer.complete();
        },
        (error) => {
          console.error(error);
          observer.error(error);
        }
      );
    });
  }

  has(...roles: ScanhaRight[]) {
    for (let role of roles) {
      for (let possessedRole of this._roles) {
        if (role === possessedRole) return true;
      }
    }
    return false;
  }

  logout(): void {
    localStorage.removeItem("token");
    this.irpc.authToken = null;
    this.userBundle = null;
    this._roles = [];
    console.log("logged out");
    this.router.navigate(["/"]);
  }
}
