<form (onSubmit)="onSubmit()" #ngForm="ngForm" [formGroup]="form" [class.show-errors]="ngForm.submitted && !form.valid">
  <div class="container mt-5">
    <div class="row mb-2">
      <div class="hr-sect">
        <span>Votre compte scanha.fr <i class="fas fa-user"></i></span>
      </div>
    </div>
    <div class="row mb-2 mt-5">
      <label>E-mail</label>
      <input type="text" formControlName="email" class="form-control" placeholder="E-mail" />
    </div>
    <div class="row mb-2" *ngIf="error">
      <div class="alert alert-danger">
        {{error}}
      </div>
    </div>
    <div class="row mb-2" *ngIf="success">
      <div class="alert alert-success">
        {{success}}
      </div>
    </div>
    <div class="row justify-content-end">
      <button type="submit" (click)="onSubmit()" class="btn btn-success">Récupérer mon compte <i class="fas fa-circle-notch spinning"
          *ngIf="loading"></i></button>
    </div>
  </div>
</form>
