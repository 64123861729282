<h1>Utilisation des cookies et traceurs</h1>

<h2>1. Cookies et navigation sur le site</h2>
<p>Conformément aux dispositions des articles 38 à 40 de la loi n°78-17 du 6 janvier 1978, vous disposez d'un droit
  d'accès, de modification, de rectification et de suppression des données qui vous concernent.</p>
<p>Certaines informations non personnelles peuvent être recueillies à l'occasion de votre navigation sur le Site
  (notamment via Google Analytics et/ou les réseaux sociaux).</p>
<p>En outre, votre navigation sur le Site peut entraîner l'implantation de fichiers «&nbsp;cookies&nbsp;» dans votre
  ordinateur, qui peuvent simplifier la visite du site et améliorer l'ergonomie du dialogue entre notre Site et votre
  ordinateur. Seul l'émetteur d'un cookie est susceptible de lire ou de modifier des informations qui y sont contenues.</p>
<p>Vous pouvez accepter ou refuser l'installation de cookies sur votre ordinateur dans les conditions décrites
  ci-dessous.</p>

<h2>2. Sécurité & Cookies</h2>
<p>Nous prenons très au sérieux la sécurité des informations que vous nous confiez. Dans le but d'empêcher des accès
  non autorisés, nous avons mis en place des procédures sécurisées pour s'assurer que ces informations sont bien
  gardées et protégées.</p>
<p>Un cookie est un petit fichier qui demande la permission d'être hébergé momentanément sur votre disque dur. Une fois
  que vous l'acceptez, le fichier est ajouté et le cookie aide à analyser le trafic d'un site web et permet de savoir
  quand date votre dernière visite sur un site. Les cookies permettent aux applications web de réagir de façon
  spécifique. L'application web peut ainsi mieux répondre à vos besoins et envies en rassemblant et en se souvenant des
  informations sur vos préférences.</p>
<p>Nous utilisons des cookies pour analyser les visites afin d'identifier les pages en cours d'utilisation. Cela nous
  aide à collecter des informations nous permettant d'améliorer notre site web afin de mieux vous servir. Nous
  utilisons ces données pour des analyses statistiques puis les données analysées sont retirées du système.</p>
<p>Les cookies nous aident aussi à rendre le site plus rapide et plus pratique. En aucun cas, les cookies ne donnent
  accès à votre ordinateur ou à toute autre information personnelle, autre que les données que vous avez choisi de
  partager avec nous. Vous pouvez choisir d'accepter ou de refuser les cookies. La majorité des navigateurs web
  (Firefox, Chrome, Safari, Internet Explorer, Edge...) accepte automatiquement les cookies, mais vous pouvez changer
  leurs paramètres pour refuser les cookies si vous préférez. Cela risque cependant de vous empêcher de profiter de
  tous les avantages du site web.</p>

<h2>3. Liste des cookies collectés</h2>
<p>Pour une utilisation optimale de ce site, de votre ordinateur, votre tablette ou votre téléphone mobile dès lors que
  vous naviguez sur Internet, vous devez accepter les cookies étant donné que des fonctionnalités en ligne ont été
  développées spécifiquement pour fonctionner uniquement grâce aux cookies. Il est important de rappeler que les
  cookies placés par ce site web n'enregistre aucune information personnelle et aucune donnée sensible. Ils ne
  récupèrent pas par exemple votre nom, votre adresse ou vos détails de paiement.</p>
<table>
  <tr>
    <td><b>NOM DU COOKIE</b></td>
    <td><b>DESCRIPTION DU COOKIE</b></td>
  </tr>
  <tr>
    <td>localstorage "token"</td>
    <td>Identifiant de connexion</td>
  </tr>
  <tr>
    <td>CONSENT</td>
    <td>Votre accord avec les cookies</td>
  </tr>
  <tr>
    <td>1P_JAR</td>
    <td>Date de consentement des cookies</td>
  </tr>
</table>
<h2>4. Cookies des services tiers</h2>
<p>Pour assurer un niveau de service élevé, nous faisons appel à des cookies tiers. Quand vous visitez notre site,
  certains cookies ne sont pas placés par nous mais par des services externes. Nous vous conseillons de vous informer
  sur les sites de nos partenaires pour plus d'informations sur leurs cookies et sur les possibilités de les gérer.
  Voici quelques exemples de services qui placent des cookies&nbsp;:</p>
<table>
  <tr>
    <td><b>NOM DU COOKIE</b></td>
    <td><b>DESCRIPTION DU COOKIE</b></td>
  </tr>
  <tr>
    <td>GOOGLE ANALYTICS</td>
    <td>Google place des cookies à partir de notre site pour analyser les performances du site et comment vous naviguez
      autour. Ces informations nous permettent de faire des modifications sur le site web qui améliore votre expérience
      en ligne. Ces cookies placés par Google nous permettent aussi de vérifier l'efficacité des campagnes marketing.</td>
  </tr>
  <tr>
    <td>STRIPE</td>
    <td>(NID + __stripe_mid + __stripe_sid) Informations stripe</td>
  </tr>
</table>
<h2>5. Comment exprimer vos choix concernant les cookies&nbsp;?</h2>
<p>Vos choix sont exprimés directement avec votre terminal.</p>
<p>Vous pouvez autoriser ou refuser l'enregistrement de cookies dans votre terminal avec les paramètres appropriés de
  votre logiciel de navigation sur Internet. Attention, la configuration de chaque navigateur est différente. Elle est
  décrite dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits
  en matière de cookies, notamment de la manière suivante pour les navigateurs les plus couramment utilisés&nbsp;:</p>
<ul>
  <li>Pour Internet Explorer™&nbsp;:<br />
    <a href="https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies" target="_blank">Cliquez-ici</a>
  </li>
  <li>
    Pour Safari™&nbsp;:<br />
    <a href="https://support.apple.com/kb/PH5042" target="_blank">Cliquez-ici</a>
  </li>
  <li>
    Pour Chrome™&nbsp;:<br />
    <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en/" target="_blank">Cliquez-ici</a>
  </li>
  <li>
    Pour Firefox™&nbsp;:<br />
    <a href="https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites?" target="_blank">Cliquez-ici</a>
  </li>
  <li>
    Pour Opera™&nbsp;:<br />
    <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">Cliquez-ici</a>
  </li>
</ul>
