<div class="alert alert-danger" *ngIf="error">
  {{ error }}
</div>
<table class="table table-hover table-responsive-sm">
  <thead>
    <tr>
      <th>#</th>
      <th>Utilisateur</th>
      <th>Société</th>
      <th>Montant TTC</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let f of factures">
      <td>{{ f.facture.id }}</td>
      <td>{{ f.lastName + " " + f.firstName }}</td>
      <td>{{ f.societe }}</td>
      <td>{{ f.facture.montantTTC }} €</td>
      <td>
        <a href="{{ rpcUrl + 'facture/' + f.token }}" target="_blank"
          ><i class="fas fa-download"></i
        ></a>
        <button
          *ngIf="!f.facture.paye"
          class="ms-2 btn btn-success"
          [disabled]="loading"
          (click)="paye(f.facture.id)"
        >
          Payé <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<div class="row justify-content-center">
  <ngb-pagination
    [collectionSize]="count"
    [(page)]="page"
    [pageSize]="nbPerPage"
    [maxSize]="6"
    [rotate]="true"
    (pageChange)="onPageChange()"
    [disabled]="loading"
  ></ngb-pagination>
</div>
