import { Component, OnInit, Host, Inject } from "@angular/core";
import { ShowProjectComponent } from "../../show-project.component";
import { EtatProjet, ScanhaRight } from "../../../../../ic2/entities/entities";
import { AuthService } from "../../../../../app/core/auth.service";
import { InfosProjectComponent } from "../../infos-project/infos-project.component";

@Component({
  selector: "app-action-layout",
  templateUrl: "./action-layout.component.html",
  styleUrls: ["./action-layout.component.scss"],
})
export class ActionLayoutComponent implements OnInit {
  etat: EtatProjet;
  admin: boolean;
  scanop: boolean;
  dessinateur: boolean;
  donneurOrdre: boolean;
  etats: typeof EtatProjet = EtatProjet;

  constructor(
    public parentComp: ShowProjectComponent,
    @Inject(AuthService) public authService: AuthService
  ) {}

  ngOnInit() {
    this.parentComp.dataLoaded.subscribe((data) => {
      this.etat = data.projet.etatProjet;
      this.admin = this.authService.has(ScanhaRight.ADMIN);
      this.scanop = this.authService.has(ScanhaRight.SCAN_OPERATOR);
      this.dessinateur = this.authService.has(ScanhaRight.DESSINATEUR);
      this.donneurOrdre = this.authService.has(ScanhaRight.DONNEUR_DORDRE);
    });
  }
}
