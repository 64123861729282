// ENTITY com.scanha.metier.user.dto.ScanhaBundle generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ScanhaBundle {


  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.user.dto.ScanhaBundle'] = ScanhaBundle;

reverseMapping[ScanhaBundle.name] = 'com.scanha.metier.user.dto.ScanhaBundle';

fields['com.scanha.metier.user.dto.ScanhaBundle']  = {
};
