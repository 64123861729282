// DB ENUM com.scanha.metier.project.entities.Precision2D generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class Precision2D {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly SIMPLE = new Precision2D(1, "dwg/dxf", "SIMPLE");
  static readonly COMPLET = new Precision2D(2, "annoté/côté/avec cartouche", "COMPLET");

  static readonly values = [
    Precision2D.SIMPLE, 
    Precision2D.COMPLET 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of Precision2D.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of Precision2D.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.Precision2D'] = Precision2D;
reverseMapping[Precision2D.name] = 'com.scanha.metier.project.entities.Precision2D';
