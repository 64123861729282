<div class="container-fluid" *ngIf="ficheProjet">
  <div class="row mb-2 pb-1 border-bottom border-primary">
    <div class="col">Fichiers</div>
  </div>
  <div
    class="row align-items-center mb-4"
    *ngFor="let f of ficheProjet.fichierList"
  >
    <div class="col-4" style="word-break: break-word">
      <a (click)="dl(f)" [routerLink]="null">{{ f.filename }}</a>
      <!--{{ f.idFichier }}-->
    </div>
    <div class="col-4">
      {{ f.typeFichier.lib }}
      <i
        class="fas fa-exclamation-triangle text-danger ms-2"
        ngbTooltip="Une erreur c'est produite à l'upload ou le fichier n'est pas encore uploadé completement"
        *ngIf="!f.uploadTermine"
      ></i>
    </div>
    <div
      [class.col-4]="!canDeleteFiles"
      [class.col-3]="canDeleteFiles"
      class="col-4 d-flex justify-content-center align-items-center"
    >
      <div *ngIf="this.dls[f.idFichier] && !this.dls[f.idFichier].downloading">
        Téléchargement terminé
      </div>
      <div
        class="progress ms-2"
        style="width: 150px"
        *ngIf="
          this.dls[f.idFichier] &&
          this.dls[f.idFichier].progressPercent &&
          this.dls[f.idFichier].downloading
        "
      >
        <div
          class="progress-bar"
          role="progressbar"
          [style.background-color]="this.dls[f.idFichier].error ? 'red' : null"
          [style.width]="this.dls[f.idFichier].progressPercent + '%'"
        >
          {{
            this.dls[f.idFichier].error
              ? "Erreur, réessai..."
              : this.dls[f.idFichier].progressPercent
          }}%
        </div>
      </div>
      <a
        *ngIf="!this.dls[f.idFichier]"
        class="pointer"
        (click)="dl(f)"
        [routerLink]="null"
      >
        <i class="fas fa-download"></i>
      </a>
    </div>
    <div class="col-1" *ngIf="canDeleteFiles">
      <a class="text-danger pointer" (click)="del(f)" [routerLink]="null">
        <i class="fas fa-trash-alt"></i>
      </a>
    </div>
  </div>
</div>
