// ENTITY com.scanha.metier.other.entities.Departement generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Departement {

  id: number | null = 0;
  regionCode: string | null = '';
  code: string | null = '';
  name: string | null = '';
  slug: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.other.entities.Departement'] = Departement;

reverseMapping[Departement.name] = 'com.scanha.metier.other.entities.Departement';

fields['com.scanha.metier.other.entities.Departement']  = {
    id: 'java.lang.Integer',    regionCode: 'java.lang.String',    code: 'java.lang.String',    name: 'java.lang.String',    slug: 'java.lang.String'};
