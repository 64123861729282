import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ScanhaUserService } from '../../../ic2/services/ScanhaUserService';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mdp-oublie',
  templateUrl: './mdp-oublie.component.html',
  styleUrls: ['./mdp-oublie.component.scss']
})
export class MdpOublieComponent implements OnInit {

  form: UntypedFormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });

  loading: boolean;
  error: string = null;
  success: string = null;

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ScanhaUserService) private scanhaUserService: ScanhaUserService,
    @Inject(Router) public router: Router,
  ) {

  }

  ngOnInit() {
  }

  onSubmit() {
    if (!this.form.valid) {
      console.log(this.form.errors);
      return;
    }
    this.loading = true;
    this.scanhaUserService.mdpOublie(this.form.value.email).subscribe(
      (data) => {
        this.success = 'Si vous possédez un compte SCANha avec cet email, vous allez recevoir un lien pour remettre à zéro votre mot de passe.';
        setTimeout(() => {
          this.loading = false;
          this.router.navigate(['/']);
        }, 3000);
      },
      (err) => {
        this.loading = false;
        this.error = err.message;
      }
    );
  }

}
