<div
  class="container"
  [formGroup]="form"
  [class.show-errors]="submitted && !form.valid"
>
  <div class="row mb-2">
    <div class="hr-sect col">
      <span>Informations <i class="fas fa-user"></i></span>
    </div>
  </div>
  <div class="row mb-2" *ngIf="form.get('firstName')">
    <div class="col-12">
      <label>Prénom</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="firstName" />
    </div>
  </div>
  <div class="row mb-2" *ngIf="form.get('lastName')">
    <div class="col-12">
      <label>Nom</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="lastName" />
    </div>
  </div>
  <div class="row mb-2" *ngIf="form.get('email')">
    <div class="col-12">
      <label>Email</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="email" />
    </div>
  </div>
  <div class="row mb-2" *ngIf="form.get('right') && withRight">
    <div class="col-12">
      <label>Role</label>
    </div>
    <div class="col-12">
      <select formControlName="right" class="form-control">
        <option [ngValue]="null">- Role -</option>
        <option *ngFor="let right of rights" [ngValue]="right">
          {{ right }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2" *ngIf="form.get('mdp') && form.get('mdp2')">
    <div class="col-md-3 col-sm-12">
      <label
        >Nouveau mot de passe<br />
        <span class="text-muted" style="font-size: 0.6em">
          (Laissez vide pour ne pas changer)
        </span>
      </label>
    </div>
    <div class="col-md-3 col-sm-12">
      <input type="password" class="form-control" formControlName="mdp" />
    </div>
    <label class="col-md-3 col-sm-12">Retapez le</label>
    <div class="col-md-3 col-sm-12">
      <input type="password" class="form-control" formControlName="mdp2" />
    </div>
  </div>
  <div class="row mb-2" *ngIf="form.get('tel')">
    <div class="col-12">
      <label>Téléphone</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="tel" />
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <label>Société</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="societe" />
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <label>Fonction</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="fonction" />
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <label>SIRET (14 caractères sans espace)</label>
    </div>
    <div class="col-12">
      <input type="text" class="form-control" formControlName="siret" />
    </div>
  </div>
  <div class="row mb-2" *ngIf="admin">
    <div class="col-12">
      <label>Prix au scan</label>
    </div>
    <div class="col-12">
      <ic2-checkbox-input formControlName="prixAuScan"></ic2-checkbox-input>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col hr-sect">
      <span>Addresse <i class="fas fa-map-marked-alt"></i></span>
    </div>
  </div>

  <app-address-edit-form [form]="getAddressForm()"></app-address-edit-form>
</div>
