<div class="mt-3">
  <label>Tarif plan 2D</label>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">Nombre de scans</div>
      <div class="col-6">Prix/u</div>
      <ng-container
        *ngFor="let palier of parent.tarif.paliersPrixAuScan; let i = index"
      >
        <div class="col-5">
          <input
            type="number"
            [(ngModel)]="parent.tarif.paliersPrixAuScan[i].min"
            (ngModelChange)="parent.change()"
            (blur)="parent.reorder(parent.tarif.paliersPrixAuScan)"
            class="form-control"
          />
        </div>
        <div class="col-5">
          <input
            type="number"
            [(ngModel)]="parent.tarif.paliersPrixAuScan[i].fixe"
            (ngModelChange)="parent.change()"
            class="form-control"
          />
        </div>
        <div class="col-2">
          <i
            class="fas fa-trash-alt pointer text-danger"
            *ngIf="parent.tarif.paliersPrixAuScan.length > 1"
            (click)="parent.removePalier(parent.tarif.paliersPrixAuScan, i)"
          ></i>
          <i
            class="fas fa-plus-circle ms-2 pointer text-success"
            (click)="parent.addPalierAfter(parent.tarif.paliersPrixAuScan, i)"
          ></i>
        </div>
      </ng-container>
    </div>
  </div>
</div>
