// DB ENUM com.scanha.metier.project.entities.NiveauPrecision generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class NiveauPrecision {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly LOD_200 = new NiveauPrecision(2, "LOD 200", "LOD_200");
  static readonly LOD_300 = new NiveauPrecision(3, "LOD 300", "LOD_300");

  static readonly values = [
    NiveauPrecision.LOD_200, 
    NiveauPrecision.LOD_300 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of NiveauPrecision.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of NiveauPrecision.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.NiveauPrecision'] = NiveauPrecision;
reverseMapping[NiveauPrecision.name] = 'com.scanha.metier.project.entities.NiveauPrecision';
