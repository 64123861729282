<ng-container *ngIf="parent.tarif !== null">
  <div class="mt-3">
    <label>Surface</label>
    <input
      type="number"
      [(ngModel)]="parent.surface"
      (ngModelChange)="parent.change()"
      class="form-control"
      placeholder="m²"
    />
  </div>
  <div class="mt-3">
    <label>Tarif plan 2D</label>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">Minimum m²</div>
        <div class="col-6">Coefficient</div>
        <ng-container
          *ngFor="let palier of parent.tarif.tarif2D; let i = index"
        >
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.tarif2D[i].min"
              (ngModelChange)="parent.change()"
              (blur)="parent.reorder(parent.tarif.tarif2D)"
              class="form-control"
            />
          </div>
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.tarif2D[i].coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <i
              class="fas fa-trash-alt pointer text-danger"
              *ngIf="parent.tarif.tarif2D.length > 1"
              (click)="parent.removePalier(parent.tarif.tarif2D, i)"
            ></i>
            <i
              class="fas fa-plus-circle ms-2 pointer text-success"
              (click)="parent.addPalierAfter(parent.tarif.tarif2D, i)"
            ></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <label>Précision 2D</label>
    <table class="table align-middle table-responsive-sm">
      <tr *ngFor="let cl of parent.tarif.coefsPrecision2D">
        <td
          class="pointer"
          [class.active]="cl === parent.coefPrecision2DSelected"
          (click)="parent.coefPrecision2DSelected = cl; parent.calcul()"
        >
          {{ cl.preci.lib }}
        </td>
        <td>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">coef</span>
            </div>
            <input
              type="number"
              [(ngModel)]="cl.coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="mt-3">
    <label>Tarif planche</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">tarif</span>
      </div>
      <input
        type="number"
        [(ngModel)]="parent.tarif.coefPlanche"
        (ngModelChange)="parent.change()"
        class="form-control"
      />
    </div>
  </div>
  <div class="mt-3">
    <label>Nombre de niveaux</label>
    <input
      type="number"
      [(ngModel)]="parent.nbNiveaux"
      (ngModelChange)="parent.change()"
      class="form-control"
    />
  </div>
  <div class="mt-3">
    <label>Nombre de coupes</label>
    <input
      type="number"
      [(ngModel)]="parent.nbCoupes"
      (ngModelChange)="parent.change()"
      class="form-control"
    />
  </div>
  <div class="mt-3">
    <label>Nombre de facades</label>
    <input
      type="number"
      [(ngModel)]="parent.nbFacades"
      (ngModelChange)="parent.change()"
      class="form-control"
    />
  </div>
  <div class="mt-3">
    <label>Coef niveaux uniquement</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">coef</span>
      </div>
      <input
        type="number"
        [(ngModel)]="parent.tarif.coefNiveauUniquement"
        (ngModelChange)="parent.change()"
        class="form-control"
      />
    </div>
  </div>
  <div class="mt-3">
    <label>Coef facade uniquement</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">coef</span>
      </div>
      <input
        type="number"
        [(ngModel)]="parent.tarif.coefFacadeUniquement"
        (ngModelChange)="parent.change()"
        class="form-control"
      />
    </div>
  </div>

  <div class="mt-3">
    <label>Typologie batiment</label>
    <table class="table align-middle table-responsive-sm">
      <tr *ngFor="let cl of parent.tarif.coefsTypologieBatiment">
        <td
          class="pointer"
          [class.active]="cl === parent.coefTypologieSelected"
          (click)="parent.coefTypologieSelected = cl; parent.calcul()"
        >
          {{ cl.typologieBatiment.lib }}
        </td>
        <td>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">coef</span>
            </div>
            <input
              type="number"
              [(ngModel)]="cl.coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="mt-3">
    <label>Marge</label>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">Minimum m²</div>
        <div class="col-6">Coefficient</div>
        <ng-container
          *ngFor="let palier of parent.tarif.paliersMarge; let i = index"
        >
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.paliersMarge[i].min"
              (ngModelChange)="parent.change()"
              (blur)="parent.reorder(parent.tarif.paliersMarge)"
              class="form-control"
            />
          </div>
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.paliersMarge[i].coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <i
              class="fas fa-trash-alt pointer text-danger"
              *ngIf="parent.tarif.paliersMarge.length > 1"
              (click)="parent.removePalier(parent.tarif.paliersMarge, i)"
            ></i>
            <i
              class="fas fa-plus-circle ms-2 pointer text-success"
              (click)="parent.addPalierAfter(parent.tarif.paliersMarge, i)"
            ></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
