<app-user-edit-form [form]="form" [submitted]="submitted"></app-user-edit-form>
<div class="container">
  <div class="row" *ngIf="error">
    <div class="alert alert-danger">
      {{error}}
    </div>
  </div>
  <div class="row" *ngIf="success">
    <div class="alert alert-success">
      {{success}}
    </div>
  </div>
  <div class="row justify-content-end">
    <button type="button" (click)="onSubmit()" [disabled]="loading" class="btn btn-success">Créer compte <i class="fas fa-circle-notch spinning"
        *ngIf="loading"></i></button>
  </div>
</div>
