// ENTITY com.scanha.metier.user.dto.UserTelEmailDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserTelEmailDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  tel: string | null = '';
  email: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.user.dto.UserTelEmailDTO'] = UserTelEmailDTO;

reverseMapping[UserTelEmailDTO.name] = 'com.scanha.metier.user.dto.UserTelEmailDTO';

fields['com.scanha.metier.user.dto.UserTelEmailDTO']  = {
    idUser: 'java.lang.Integer',    firstName: 'java.lang.String',    lastName: 'java.lang.String',    tel: 'java.lang.String',    email: 'java.lang.String'};
