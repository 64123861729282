import { Component, OnInit, Inject } from "@angular/core";
import { environment } from "../../../../../../environments/environment";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { ContactService } from "../../../ic2/services/ContactService";
import { L } from "@ic2/ic2-lib";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
  host: { class: "col" },
})
export class ContactFormComponent implements OnInit {
  recaptchaPublicKey: string;
  form: UntypedFormGroup = this.fb.group({
    nom: [null, [Validators.required]],
    prenom: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    tel: [null, [Validators.required, Validators.pattern("[0-9\\+\\-\\ ]+")]],
    text: [null, [Validators.required]],
  });
  loading: boolean;
  submitted: boolean;
  success: string;

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ContactService) private contactService: ContactService
  ) {
    this.recaptchaPublicKey = environment.recaptchaPublicKey;
  }

  ngOnInit() {}

  presubmit() {
    this.submitted = true;
    return true;
  }

  submit(captchaResponse: string): void {
    console.log(this.form, captchaResponse);
    if (!this.form.valid) {
      return;
    }
    let value = this.form.value;
    this.loading = true;
    this.contactService
      .contact(
        value.nom,
        value.prenom,
        value.email,
        value.tel,
        value.text,
        captchaResponse
      )
      .subscribe(
        (data) => {
          this.form.reset();
          this.loading = false;
          this.submitted = false;
          this.success = "Merci de nous avoir contacté";
          setTimeout(() => {
            this.success = null;
          }, 3000);
        },
        (err) => {
          L.e(err);
        }
      );
  }
}
