import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./notfound/notfound.component";
import { LoginComponent } from "./login/login.component";
import { FrontLayoutComponent } from "./front/front-layout/front-layout.component";
import { HomeComponent } from "./front/home/home.component";
import { DevisFormComponent } from "./front/devis-form/devis-form.component";
import { ClientSpaceLayoutComponent } from "./client-space/client-space-layout/client-space-layout.component";
import { ClientProjectListComponent } from "./client-space/client-project-list/client-project-list.component";
import { AdminProjectListComponent } from "./client-space/admin-project-list/admin-project-list.component";
import { AdminCreateAccountFormComponent } from "./client-space/admin-create-account-form/admin-create-account-form.component";
import { AdminInvoiceListComponent } from "./client-space/admin-invoice-list/admin-invoice-list.component";
import { AdminPriceSettingsComponent } from "./client-space/admin-price-settings/admin-price-settings.component";
import { AdminUserListComponent } from "./client-space/admin-user-list/admin-user-list.component";
import { UserEditFormComponent } from "./client-space/user-edit-form/user-edit-form.component";
import { ShowProjectComponent } from "./client-space/show-project/show-project.component";
import { AuthGuard } from "./core/auth-guard.service";
import { InfosProjectComponent } from "./client-space/show-project/infos-project/infos-project.component";
import { ProjectFilesComponent } from "./client-space/show-project/project-files/project-files.component";
import { UserEditPageComponent } from "./client-space/user-edit-page/user-edit-page.component";
import { MdpOublieComponent } from "./front/mdp-oublie/mdp-oublie.component";
import { CreationMdpComponent } from "./front/creation-mdp/creation-mdp.component";
import { DevisFacturesDisplayComponent } from "./client-space/show-project/devis-factures-display/devis-factures-display.component";
import { MentionsLegalesComponent } from "./front/mentions-legales/mentions-legales.component";
import { ConditionsGeneralesDeVenteComponent } from "./front/conditions-generales-de-vente/conditions-generales-de-vente.component";
import { PartageProjetComponent } from "./front/partage-projet/partage-projet.component";
import { CookiePolicyComponent } from "./front/cookie-policy/cookie-policy.component";
import { RgpdComponent } from "./front/rgpd/rgpd.component";

const projectRoutes: Routes = [
  { path: "", redirectTo: "info", pathMatch: "full" },
  { path: "info", component: InfosProjectComponent },
  { path: "devis-factures", component: DevisFacturesDisplayComponent },
  { path: "fichiers", component: ProjectFilesComponent },
];
const routes: Routes = [
  {
    path: "",
    component: FrontLayoutComponent,
    children: [
      { path: "", component: HomeComponent },
      { path: "devis", component: DevisFormComponent },
      { path: "mentions-legales", component: MentionsLegalesComponent },
      {
        path: "conditions-generales-de-vente",
        component: ConditionsGeneralesDeVenteComponent,
      },
      { path: "rgpd", component: RgpdComponent },
      { path: "cookie-info", component: CookiePolicyComponent },
      { path: "partage-projet/:token", component: PartageProjetComponent },
      { path: "mot-de-passe-oublie", component: MdpOublieComponent },
      { path: "creation-mdp/:token", component: CreationMdpComponent },
    ],
  },
  {
    path: "client",
    component: ClientSpaceLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "projets", pathMatch: "full" },
      { path: "projets", component: ClientProjectListComponent },
      {
        path: "projet/:id",
        component: ShowProjectComponent,
        children: projectRoutes,
      },
      { path: "mon-compte", component: UserEditPageComponent },
      { path: "nouveau-projet", component: DevisFormComponent },
    ],
  },
  {
    path: "admin",
    component: ClientSpaceLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "projets", pathMatch: "full" },
      { path: "projets", component: AdminProjectListComponent },
      {
        path: "projet/:id",
        component: ShowProjectComponent,
        children: projectRoutes,
      },
      { path: "creation-compte", component: AdminCreateAccountFormComponent },
      { path: "factures", component: AdminInvoiceListComponent },
      { path: "parametrage-tarif", component: AdminPriceSettingsComponent },
      { path: "utilisateurs", component: AdminUserListComponent },
      { path: "utilisateur/:id", component: UserEditPageComponent },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
    }),
  ],
  providers: [],
})
export class AppRoutingModule {}
