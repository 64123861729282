import { Component, OnInit, Host, Inject } from "@angular/core";
import { AuthService } from "../../../../../app/core/auth.service";
import { ScanhaRight, FicheProjet } from "../../../../../ic2/entities/entities";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { ProjetActionService } from "../../../../../ic2/services/ProjetActionService";
import { L } from "@ic2/ic2-lib";

@Component({
  selector: "app-action-prise-rdv",
  templateUrl: "./action-prise-rdv.component.html",
  styleUrls: ["./action-prise-rdv.component.scss"],
})
export class ActionPriseRdvComponent implements OnInit {
  ficheProjet: FicheProjet;
  donneurOrdre: boolean;
  admin: boolean;
  propositionRdvClient: string;
  rdvClient: string;
  loading: boolean;
  success: string;

  constructor(
    public parentComp: ActionLayoutComponent,
    @Inject(AuthService) public authService: AuthService,
    @Inject(ProjetActionService)
    private projetActionService: ProjetActionService
  ) {}

  ngOnInit() {
    this.parentComp.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
      this.donneurOrdre = this.authService.has(ScanhaRight.DONNEUR_DORDRE);
      this.admin = this.authService.has(ScanhaRight.ADMIN);
      this.propositionRdvClient = this.ficheProjet.projet.propositionRdvClient;
    });
  }

  proposerDates() {
    this.loading = true;
    this.projetActionService
      .proposerDatesRdv(this.ficheProjet.projet.id, this.propositionRdvClient)
      .subscribe(
        (data) => {
          this.success = "Dates bien proposées";
          setTimeout(() => {
            this.loading = false;
            this.success = null;
            this.parentComp.parentComp.reload();
          }, 2000);
        },
        (error) => {
          L.e(error);
          this.loading = false;
        }
      );
  }

  accepterRdv() {
    this.loading = true;
    this.projetActionService
      .accepterRdv(this.ficheProjet.projet.id, this.rdvClient)
      .subscribe(
        (data) => {
          this.success = "Rendez vous fixé, un email à été envoyé au client";
          setTimeout(() => {
            this.loading = false;
            this.success = null;
            this.parentComp.parentComp.reload();
          }, 2000);
        },
        (error) => {
          L.e(error);
          this.loading = false;
        }
      );
  }
}
