<form (onSubmit)="onSubmit()" #ngForm="ngForm" [formGroup]="form" [class.show-errors]="ngForm.submitted && !form.valid">
  <div class="container mt-5">
    <div class="row mb-2">
      <div class="hr-sect">
        <span>Votre compte scanha.fr <i class="fas fa-user"></i></span>
      </div>
    </div>
    <div class="row mb-2 mt-5">
      <label>Mot de passe <span class="text-muted" style="font-size: small">(Minimum 8 caractères)</span></label>
      <input type="password" formControlName="mdp1" class="form-control" placeholder="Mot de passe" />
    </div>
    <div class="row mb-2">
      <label>Retapez le mot de passe</label>
      <input type="password" formControlName="mdp2" class="form-control" placeholder="Retapez" />
    </div>
    <div class="row mb-2" *ngIf="error">
      <div class="alert alert-danger">
        {{error}}
      </div>
    </div>
    <div class="row justify-content-end">
      <button type="submit" (click)="onSubmit()" class="btn btn-success">Créer mon mot de passe <i class="fas fa-circle-notch spinning"
          *ngIf="loading"></i></button>
    </div>
  </div>
</form>