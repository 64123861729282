<div class="container mt-4 mb-4">
  <div class="row">
    <div class="col">Redistribuer à</div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        (focus)="focus$.next($event.target.value)"
        (click)="click$.next($event.target.value)"
        #instance="ngbTypeahead"
        [(ngModel)]="selectedUser"
        [ngbTypeahead]="search"
        [resultFormatter]="formatter"
        [inputFormatter]="formatter"
        required
      />
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">Commentaire privé:<br /></div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <textarea
        class="w-100 form-control"
        [(ngModel)]="ficheProjet.projet.commentaireScanha"
      ></textarea>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <button class="btn btn-success" [disabled]="loading" (click)="allGood()">
        Tout est bon
      </button>
    </div>
    <div class="col text-end">
      <button
        class="btn btn-secondary"
        [disabled]="loading"
        (click)="redistribuer()"
      >
        Redistribuer
      </button>
    </div>
  </div>
  <div class="row mt-2" *ngIf="success">
    <div class="col">
      <div class="alert alert-success">
        {{ success }}
      </div>
    </div>
  </div>
</div>
