import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  loading: boolean;
  message: string;
  backgroundNumber: number;
  constructor(
    @Inject(AuthService) public authService: AuthService,
    @Inject(Router) public router: Router,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
  ) {
    this.backgroundNumber = this.getRandomInt(1, 2);
  }
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe(
      (data) => {
        if (data)
          this.router.navigate(['client']);
      }
    );
  }
  onSubmit() {
    console.log('submit', this.loginForm);

    this.loading = true;
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(() => {
      this.loading = false;
      if (this.authService.isLoggedIn) {
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/client';

        // Redirect the user
        this.router.navigate([redirect]);
      }
    }, (error) => {
      this.loading = false;
      if (error.code === -2)
        this.message = 'Une erreur est survenue : Serveur éteint';
      else
        this.message = 'Une erreur est survenue : '+error.message;
    });
  }

}
