// DB ENUM com.scanha.metier.project.entities.EtatProjet generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EtatProjet {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ATTENTE_CREATION_COMPTE = new EtatProjet(1, "Attente création de compte", "ATTENTE_CREATION_COMPTE");
  static readonly ATTENTE_RECTIFICATION_PRIX_ET_ASSIGNATION = new EtatProjet(2, "Attente rectification du devis", "ATTENTE_RECTIFICATION_PRIX_ET_ASSIGNATION");
  static readonly ATTENTE_ACCEPTATION_DEVIS_CLIENT = new EtatProjet(3, "Attente acceptation devis client", "ATTENTE_ACCEPTATION_DEVIS_CLIENT");
  static readonly REFUSE_PAR_CLIENT = new EtatProjet(4, "A revoir", "REFUSE_PAR_CLIENT");
  static readonly ATTENTE_PAIEMENT_ACCOMPTE = new EtatProjet(5, "Attente paiement accompte", "ATTENTE_PAIEMENT_ACCOMPTE");
  static readonly ATTENTE_PAIEMENT_ACCOMPTE_ATTENTE_VIREMENT = new EtatProjet(6, "Attente virement accompte", "ATTENTE_PAIEMENT_ACCOMPTE_ATTENTE_VIREMENT");
  static readonly ATTENTE_PRISE_RDV = new EtatProjet(7, "Attente prise de rendez-vous", "ATTENTE_PRISE_RDV");
  static readonly ATTENTE_UPLOAD_FICHIERS = new EtatProjet(8, "Attente upload fichiers", "ATTENTE_UPLOAD_FICHIERS");
  static readonly ATTENTE_VALIDATION_FINALE = new EtatProjet(9, "Attente validation finale", "ATTENTE_VALIDATION_FINALE");
  static readonly ATTENTE_UPLOAD_FICHIERS_SUP = new EtatProjet(10, "Attente upload fichiers supplémentaires", "ATTENTE_UPLOAD_FICHIERS_SUP");
  static readonly ATTENTE_PAIEMENT_FINAL = new EtatProjet(11, "Attente paiement final client", "ATTENTE_PAIEMENT_FINAL");
  static readonly ATTENTE_PAIEMENT_FINAL_ATTENTE_VIREMENT = new EtatProjet(12, "Attente virement final client", "ATTENTE_PAIEMENT_FINAL_ATTENTE_VIREMENT");
  static readonly PROJET_CLOS = new EtatProjet(13, "Projet cloturé", "PROJET_CLOS");

  static readonly values = [
    EtatProjet.ATTENTE_CREATION_COMPTE, 
    EtatProjet.ATTENTE_RECTIFICATION_PRIX_ET_ASSIGNATION, 
    EtatProjet.ATTENTE_ACCEPTATION_DEVIS_CLIENT, 
    EtatProjet.REFUSE_PAR_CLIENT, 
    EtatProjet.ATTENTE_PAIEMENT_ACCOMPTE, 
    EtatProjet.ATTENTE_PAIEMENT_ACCOMPTE_ATTENTE_VIREMENT, 
    EtatProjet.ATTENTE_PRISE_RDV, 
    EtatProjet.ATTENTE_UPLOAD_FICHIERS, 
    EtatProjet.ATTENTE_VALIDATION_FINALE, 
    EtatProjet.ATTENTE_UPLOAD_FICHIERS_SUP, 
    EtatProjet.ATTENTE_PAIEMENT_FINAL, 
    EtatProjet.ATTENTE_PAIEMENT_FINAL_ATTENTE_VIREMENT, 
    EtatProjet.PROJET_CLOS 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EtatProjet.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EtatProjet.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.EtatProjet'] = EtatProjet;
reverseMapping[EtatProjet.name] = 'com.scanha.metier.project.entities.EtatProjet';
