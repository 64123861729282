// ENTITY com.scanha.metier.tarif.entities.CoefTypologieReseau generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TypologieReseau } from './TypologieReseau';

export class CoefTypologieReseau {

  coef: number | null = 0;
  typologieReseau: TypologieReseau | null = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.tarif.entities.CoefTypologieReseau'] = CoefTypologieReseau;

reverseMapping[CoefTypologieReseau.name] = 'com.scanha.metier.tarif.entities.CoefTypologieReseau';

fields['com.scanha.metier.tarif.entities.CoefTypologieReseau']  = {
    coef: 'java.lang.Double',    typologieReseau: 'com.scanha.metier.project.entities.TypologieReseau'};
