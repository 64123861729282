import { Component, OnInit, Inject, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ScanhaRight } from "../../../ic2/entities/entities";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: "app-user-edit-form",
  templateUrl: "./user-edit-form.component.html",
  styleUrls: ["./user-edit-form.component.scss"],
})
export class UserEditFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  rights: ScanhaRight[];
  @Input() withRight: boolean = true;
  admin: boolean = false;

  constructor(@Inject(AuthService) private authService: AuthService) {
    this.admin = this.authService.has(ScanhaRight.ADMIN);
    if (
      this.authService.userBundle.rights.find(
        (ur) => ur.idRight === ScanhaRight.ADMIN.id
      )
    )
      this.rights = ScanhaRight.values;
    else if (
      this.authService.userBundle.rights.find(
        (ur) => ur.idRight === ScanhaRight.APPORTEUR_AFFAIRE.id
      )
    )
      this.rights = ScanhaRight.values.filter(
        (r) => r.id !== ScanhaRight.ADMIN.id
      );
  }

  ngOnInit() {}

  getAddressForm(): UntypedFormGroup {
    return this.form.get("address") as UntypedFormGroup;
  }
}
