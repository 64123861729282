<div class="wrapper">
  <div class="login container-fluid">
    <div class="row justify-content-center pb-5">
      <div class="col">
        <img
          src="assets/images/logo-scanha-login_150x194.png"
          width="150px"
          height="194px"
        />
      </div>
    </div>
    <div class="row justify-content-center pb-5">
      <div class="col">
        <h1>Connectez-vous</h1>
      </div>
    </div>
    <form (onSubmit)="onSubmit()" [formGroup]="loginForm">
      <div class="row pb-3">
        <div class="col">
          <label>Adresse e-mail</label>
          <input
            type="text"
            formControlName="email"
            class="form-control"
            placeholder="E-mail"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Mot de passe</label>
          <input
            type="password"
            formControlName="password"
            class="form-control"
            placeholder="Mot de passe"
          />
        </div>
      </div>
      <div class="row justify-content-end pb-3">
        <div class="col text-end mdpoublie">
          <a routerLink="/mot-de-passe-oublie">Mot de passe oublié ?</a>
        </div>
      </div>
      <div class="row alert alert-danger" *ngIf="message">{{ message }}</div>
      <div class="row justify-content-end">
        <div class="col" style="text-align: end">
          <button
            type="submit"
            (click)="onSubmit()"
            [disabled]="!loginForm.pristine && !loginForm.valid"
            class="btn btn-success"
          >
            Se connecter
            <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div
    class="image"
    [style.backgroundImage]="'url(assets/images/site_connexion.jpg)'"
  ></div>
</div>
