<div class="text-center">
  <h1>Mentions légales</h1>

  <h2>Éditeur du Site&nbsp;:</h2>
  <b>SCANha (SAS)</b>
  <p>RCS Châlons en Champagne 844 543 454 - NAF 6311Z</p>
  <p>2 rue Saint Dominique<br>
    Pépinière Technologique<br>
    51000 CHALONS-EN-CHAMPAGNE<br>
    France</p>
  <p>Email&nbsp;: aurelie.husson@scanha.fr</p>
  <p>Site web&nbsp;: <a href="https://scanha.fr/" target="_blank">www.scanha.fr</a></p>
  <p>Téléphone&nbsp;: 03&nbsp; 26&nbsp; 24&nbsp; 91&nbsp; 33</p>

  <h2>Hébergeur&nbsp;:</h2>
  <b>OVH</b>
  <p>2 Rue Kellerman<br>
    59100 ROUBAIX</p>
  <p>Site web&nbsp;: <a href="https://www.ovh.com/fr/" target="_blank">www.ovh.com</a></p>

  <h2>Propulsé par&nbsp;:</h2>
  <b>Degineo</b>
  <p>1 place de la comédie<br>
    51000 CHALONS-EN-CHAMPAGNE</p>
  <p>Site web&nbsp;: <a href="https://www.degineo.com/" target="_blank">www.degineo.com</a></p>
</div>
