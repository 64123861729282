<div *ngIf="ficheProjet === null || departements === null">
  Chargement <i class="fas fa-circle-notch spinning"></i>
</div>
<div *ngIf="ficheProjet && departements">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a
      class="navbar-brand"
      [routerLink]="null"
      title="{{ ficheProjet.projet.nom }}"
      >Projet {{ ficheProjet.projet.nom }}</a
    >
    <button
      class="navbar-toggler"
      type="button"
      (click)="collapse = !collapse"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" id="navbarNav" [class.collapse]="collapse">
      <ul class="navbar-nav">
        <li class="nav-item pointer" routerLinkActive="active">
          <a class="nav-link" routerLink="info">Informations</a>
        </li>
        <li class="nav-item pointer" routerLinkActive="active">
          <a class="nav-link" routerLink="devis-factures">Devis & Factures</a>
        </li>
        <li class="nav-item pointer" routerLinkActive="active">
          <a
            class="nav-link"
            [class.disabled]="!canAccessFiles"
            routerLink="fichiers"
            >Fichiers</a
          >
        </li>
      </ul>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>

<app-action-layout></app-action-layout>
