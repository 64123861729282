// ENTITY com.scanha.metier.project.dto.ClientProjetDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TypeLivrable } from './TypeLivrable';
import { EtatProjet } from './EtatProjet';

export class ClientProjetDTO {

  id: number | null = 0;
  nom: string | null = '';
  etatProjet: EtatProjet | null = null;
  livrable: TypeLivrable | null = null;
  surface: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.ClientProjetDTO'] = ClientProjetDTO;

reverseMapping[ClientProjetDTO.name] = 'com.scanha.metier.project.dto.ClientProjetDTO';

fields['com.scanha.metier.project.dto.ClientProjetDTO']  = {
    id: 'java.lang.Integer',    nom: 'java.lang.String',    etatProjet: 'com.scanha.metier.project.entities.EtatProjet',    livrable: 'com.scanha.metier.project.entities.TypeLivrable',    surface: 'java.lang.Integer'};
