<form
  #ngForm="ngForm"
  [formGroup]="form"
  [class.show-errors]="submitted && !form.valid"
>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <span class="ps-3 label">Votre nom</span>
        <input type="text" formControlName="nom" class="form-control mt-2" />
      </div>
      <div class="col-12 col-md-6 mt-sm-0 mt-3">
        <span class="ps-3 label">Votre prénom</span>
        <input type="text" formControlName="prenom" class="form-control mt-2" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col"><span class="ps-3 label">Votre e-mail</span></div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" formControlName="email" class="form-control" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="ps-3 label">Votre numéro de téléphone</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" formControlName="tel" class="form-control" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="ps-3 label">Ecrivez votre message ci-dessous</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <textarea
          class="w-100 form-control"
          formControlName="text"
          rows="7"
        ></textarea>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <re-captcha
          #captchaRef="reCaptcha"
          siteKey="{{ recaptchaPublicKey }}"
          size="invisible"
          (resolved)="$event && submit($event)"
          badge="inline"
        ></re-captcha>
        <button
          class="btn btn-secondary btn-bold-white w-100"
          [disabled]="loading"
          (click)="presubmit() && form.valid && captchaRef.execute()"
        >
          Envoyer <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
        </button>
        <div class="text-muted">
          Ce site est protégé par reCAPTCHA et la
          <a href="https://policies.google.com/privacy"
            >Politique de confidentialité</a
          >
          et les
          <a href="https://policies.google.com/terms">Termes de service</a> de
          Google s'appliquent.
        </div>
      </div>
    </div>
    <div class="row justify-content-end" *ngIf="success">
      <div class="alert alert-success">
        {{ success }}
      </div>
    </div>
  </div>
</form>
