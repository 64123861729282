// ENTITY com.scanha.metier.project.dto.AdminProjetFilter generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EtatProjet } from './EtatProjet';

export class AdminProjetFilter {

  etat: EtatProjet | null = null;
  idDepartement: number | null = 0;
  idUserAssigne: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.AdminProjetFilter'] = AdminProjetFilter;

reverseMapping[AdminProjetFilter.name] = 'com.scanha.metier.project.dto.AdminProjetFilter';

fields['com.scanha.metier.project.dto.AdminProjetFilter']  = {
    etat: 'com.scanha.metier.project.entities.EtatProjet',    idDepartement: 'java.lang.Integer',    idUserAssigne: 'java.lang.Integer'};
