<div class="container mt-4 mb-4" *ngIf="donneurOrdre || admin">
  <div class="row">
    <div class="col">
      Merci d'indiquer vos disponibilitées pour mise en place du scan opérateur
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <textarea
        class="form-control w-100"
        [(ngModel)]="propositionRdvClient"
      ></textarea>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col text-end">
      <button
        class="btn btn-success"
        [disabled]="loading || propositionRdvClient?.length < 1"
        (click)="proposerDates()"
      >
        Proposer dates
      </button>
    </div>
  </div>
  <div class="row mt-2" *ngIf="success">
    <div class="col">
      <div class="alert alert-success">
        {{ success }}
      </div>
    </div>
  </div>
</div>
<div class="container mt-4 mb-4" *ngIf="!donneurOrdre || admin">
  <div class="row">
    <div class="col-3">Proposition du client :</div>
    <div class="col-9">
      {{
        ficheProjet.projet.propositionRdvClient === null
          ? "Pas de proposition client"
          : ficheProjet.projet.propositionRdvClient
      }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <textarea class="form-control w-100" [(ngModel)]="rdvClient"></textarea>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col text-end">
      <button
        class="btn btn-success"
        [disabled]="loading || rdvClient?.length < 1"
        (click)="accepterRdv()"
      >
        Accepter RDV
      </button>
    </div>
  </div>
  <div class="row mt-2" *ngIf="success">
    <div class="col">
      <div class="alert alert-success">
        {{ success }}
      </div>
    </div>
  </div>
</div>
