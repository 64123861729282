import { Livrable3D, LivrableReseau, LivrableReleveNumerique } from "../../ic2/entities/entities";

export default {
    '3D': [
        Livrable3D.REVIT_2019,
        Livrable3D.REVIT_2018,
        Livrable3D.REVIT_2017,
        //Livrable3D.REVIT_2016,
        
        Livrable3D.ARCHICAD_22,
        Livrable3D.ARCHICAD_21,
        Livrable3D.ARCHICAD_20,
        Livrable3D.ARCHICAD_19,
        Livrable3D.AUTRES
    ],
    'Reseau': [
        LivrableReseau.RESEAU_DWG,
        LivrableReseau.RESEAU_SHAPE,
        LivrableReseau.RESEAU_DGN
    ],
    'Nuage de point': [
        LivrableReleveNumerique.RCP,
        LivrableReleveNumerique.ISPROJ
    ]
};