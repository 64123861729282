import { Component, OnInit, Host } from "@angular/core";
import { AdminPriceSettingsComponent } from "../admin-price-settings.component";

@Component({
  selector: "app-admin-price-settings-releve",
  templateUrl: "./admin-price-settings-releve.component.html",
  styleUrls: ["./admin-price-settings-releve.component.scss"],
})
export class AdminPriceSettingsReleveComponent implements OnInit {
  constructor(public parent: AdminPriceSettingsComponent) {}

  ngOnInit() {}
}
