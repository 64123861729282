// com.scanha.metier.devis.service.DevisServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LivrableReseau } from '../entities/LivrableReseau';
import { TypologieBatiment } from '../entities/TypologieBatiment';
import { NiveauPrecision } from '../entities/NiveauPrecision';
import { LivrableReleveNumerique } from '../entities/LivrableReleveNumerique';
import { TypeLivrable } from '../entities/TypeLivrable';
import { Precision2D } from '../entities/Precision2D';
import { Livrable2D } from '../entities/Livrable2D';
import { SurfaceUnite } from '../entities/SurfaceUnite';
import { Livrable3D } from '../entities/Livrable3D';
import { TypologieReseau } from '../entities/TypologieReseau';

@Injectable({
  providedIn: 'root',
})
export class DevisService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  demandeDevis(livrable: TypeLivrable, livrable2D: Livrable2D, typologieBatiment: TypologieBatiment, precision2d: Precision2D, nbNiveaux: number, nbCoupes: number, nbFacades: number, livrable3D: Livrable3D, precisionGlobale: NiveauPrecision, livrableReseau: LivrableReseau, typologieReseau: TypologieReseau, surfaceUnite: SurfaceUnite, livrableReleveNumerique: LivrableReleveNumerique, surface: number, nbScans: number, commentaire: string, email: string, nomProjet: string, tel: string, nomDO: string, prenomDO: string, idDepartement: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DevisService.demandeDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(livrable, 'com.scanha.metier.project.entities.TypeLivrable', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(livrable2D, 'com.scanha.metier.project.entities.Livrable2D', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(typologieBatiment, 'com.scanha.metier.project.entities.TypologieBatiment', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(precision2d, 'com.scanha.metier.project.entities.Precision2D', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbNiveaux, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbCoupes, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbFacades, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(livrable3D, 'com.scanha.metier.project.entities.Livrable3D', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(precisionGlobale, 'com.scanha.metier.project.entities.NiveauPrecision', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(livrableReseau, 'com.scanha.metier.project.entities.LivrableReseau', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(typologieReseau, 'com.scanha.metier.project.entities.TypologieReseau', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(surfaceUnite, 'com.scanha.metier.project.entities.SurfaceUnite', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(livrableReleveNumerique, 'com.scanha.metier.project.entities.LivrableReleveNumerique', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(surface, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbScans, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(commentaire, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nomProjet, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(tel, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nomDO, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(prenomDO, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idDepartement, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
