import { Component, OnInit, Inject, Host } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ProjetActionService } from "../../../../../ic2/services/ProjetActionService";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { FicheProjet } from "../../../../../ic2/entities/entities";

@Component({
  selector: "app-action-client-valide-devis",
  templateUrl: "./action-client-valide-devis.component.html",
  styleUrls: ["./action-client-valide-devis.component.scss"],
})
export class ActionClientValideDevisComponent implements OnInit {
  error: string;
  success: string;
  loading: boolean = false;
  ficheProjet: FicheProjet;
  prixFinal: number;
  modeModif: boolean;
  modifComment: string = "";

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ProjetActionService)
    private projetActionService: ProjetActionService,
    public actionLayoutComp: ActionLayoutComponent
  ) {}

  ngOnInit() {
    this.actionLayoutComp.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
      var dernierDevis = data.devisList.reduce((a, b) => {
        return a.dateCreation > b.dateCreation ? a : b;
      });
      this.prixFinal = dernierDevis.prixFinal;
    });
  }
  valideDevis() {
    this.loading = true;
    this.projetActionService.valideDevis(this.ficheProjet.projet.id).subscribe(
      (data) => {
        this.success = "Devis validé";
        setTimeout(() => {
          this.loading = false;
          this.success = null;
          this.actionLayoutComp.parentComp.reload();
        }, 2000);
      },
      (err) => {
        this.error = err.message;
        this.loading = false;
      }
    );
  }
  demandeModif() {
    if (this.modifComment.length === 0) return;
    this.loading = true;
    this.projetActionService
      .demandeModifDevis(this.ficheProjet.projet.id, this.modifComment)
      .subscribe(
        (data) => {
          this.success = "Demande de modification de devis envoyée";
          setTimeout(() => {
            this.loading = false;
            this.success = null;
            this.actionLayoutComp.parentComp.reload();
          }, 2000);
        },
        (err) => {
          this.error = err.message;
          this.loading = false;
        }
      );
  }
}
