import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'front-layout',
  templateUrl: './front-layout.component.html',
  styleUrls: ['./front-layout.component.scss']
})
export class FrontLayoutComponent implements OnInit {
  year: number;
  loggedIn: boolean;
  constructor(
    @Inject(AuthService) authService: AuthService
  ) {
    authService.isLoggedIn().subscribe((isLoggedIn) => {
      this.loggedIn = isLoggedIn;
    });
  }
  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}
