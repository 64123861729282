// ENTITY com.scanha.metier.user.dto.UserDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { User } from './User';
import { Address } from './Address';
import { UserInfo } from './UserInfo';

export class UserDTO {

  user: User | null = null;
  userInfo: UserInfo | null = null;
  address: Address | null = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.user.dto.UserDTO'] = UserDTO;

reverseMapping[UserDTO.name] = 'com.scanha.metier.user.dto.UserDTO';

fields['com.scanha.metier.user.dto.UserDTO']  = {
    user: 'com.ic2.entity.User',    userInfo: 'com.scanha.metier.user.entities.UserInfo',    address: 'com.scanha.metier.user.entities.Address'};
