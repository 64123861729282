// ENTITY com.scanha.metier.facture.entities.Facture generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModePaiement } from './ModePaiement';

export class Facture {

  id: number | null = 0;
  idUserLie: number | null = 0;
  idDevis: number | null = 0;
  idProjet: number | null = 0;
  acompte: boolean | null = false;
  paye: boolean | null = false;
  tva: number | null = 0;
  montantHT: number | null = 0;
  montantTTC: number | null = 0;
  dateCreation: Date | null = null;
  modePaiement: ModePaiement | null = null;
  infoPaiement: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.facture.entities.Facture'] = Facture;

reverseMapping[Facture.name] = 'com.scanha.metier.facture.entities.Facture';

fields['com.scanha.metier.facture.entities.Facture']  = {
    id: 'java.lang.Integer',    idUserLie: 'java.lang.Integer',    idDevis: 'java.lang.Integer',    idProjet: 'java.lang.Integer',    acompte: 'java.lang.Boolean',    paye: 'java.lang.Boolean',    tva: 'java.lang.Double',    montantHT: 'java.lang.Double',    montantTTC: 'java.lang.Double',    dateCreation: 'java.time.Instant',    modePaiement: 'com.scanha.metier.facture.entities.ModePaiement',    infoPaiement: 'java.lang.String'};
