// DB ENUM com.scanha.metier.project.entities.SurfaceUnite generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class SurfaceUnite {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly M2 = new SurfaceUnite(1, "m²", "M2");
  static readonly ML = new SurfaceUnite(2, "ml", "ML");

  static readonly values = [
    SurfaceUnite.M2, 
    SurfaceUnite.ML 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of SurfaceUnite.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of SurfaceUnite.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.SurfaceUnite'] = SurfaceUnite;
reverseMapping[SurfaceUnite.name] = 'com.scanha.metier.project.entities.SurfaceUnite';
