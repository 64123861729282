<form
  (onSubmit)="onSubmit()"
  #ngForm="ngForm"
  [formGroup]="form"
  [class.show-errors]="ngForm.submitted && !form.valid"
>
  <div class="container">
    <div class="row mb-2">
      <div class="hr-sect">
        <span>Votre projet <i class="fas fa-building"></i></span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="admin">
      <label>Activer le prix au scan (en admin, juste pour ce projet)</label>
      <input
        type="checkbox"
        [(ngModel)]="prixAuScan"
        [ngModelOptions]="{ standalone: true }"
        class="form-control"
      />
    </div>

    <div class="row mb-2">
      <label>Type de livrable *</label>
      <select
        formControlName="typeLivrable"
        class="form-control"
        (change)="livrableChanged()"
      >
        <option [ngValue]="null">- Livrables -</option>
        <option
          *ngFor="let typeLivrable of typeLivrables"
          [ngValue]="typeLivrable"
        >
          {{ typeLivrable }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('livrable3D')">
      <label>Format *</label>
      <select formControlName="livrable3D" class="form-control">
        <option [ngValue]="null">- Livrables -</option>
        <option *ngFor="let livrable of livrables3D" [ngValue]="livrable">
          {{ livrable }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('livrableReleve')">
      <label>Format *</label>
      <select formControlName="livrableReleve" class="form-control">
        <option [ngValue]="null">- Livrables -</option>
        <option *ngFor="let livrable of livrablesReleve" [ngValue]="livrable">
          {{ livrable }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('livrableReseau')">
      <label>Format *</label>
      <select formControlName="livrableReseau" class="form-control">
        <option [ngValue]="null">- Livrables -</option>
        <option *ngFor="let livrable of livrablesReseau" [ngValue]="livrable">
          {{ livrable }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('typologieBatiment')">
      <label>Typologie batiment *</label>
      <select formControlName="typologieBatiment" class="form-control">
        <option [ngValue]="null">- Typologies -</option>
        <option *ngFor="let typo of typologies" [ngValue]="typo">
          {{ typo }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('typologieReseau')">
      <label>Typologie réseau *</label>
      <select formControlName="typologieReseau" class="form-control">
        <option [ngValue]="null">- Typologies -</option>
        <option *ngFor="let typo of typologiesReseau" [ngValue]="typo">
          {{ typo }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('nbCoupes')">
      <label>Nombre de coupes *</label>
      <input type="number" formControlName="nbCoupes" class="form-control" />
    </div>

    <div class="row mb-2" *ngIf="form.get('nbFacades')">
      <label>Nombre de facades *</label>
      <input type="number" formControlName="nbFacades" class="form-control" />
    </div>

    <div class="row mb-2" *ngIf="form.get('nbNiveaux')">
      <label>Nombre de niveaux *</label>
      <input type="number" formControlName="nbNiveaux" class="form-control" />
    </div>

    <div
      class="row mb-2"
      *ngIf="
        form.get('typeLivrable').value !== types._RESEAU &&
        (!prixAuScan ||
          form.get('typeLivrable').value === types._RELEVE_NUMERIQUE)
      "
    >
      <label>Surface: additionnez tous les niveaux *</label>
      <input
        type="number"
        formControlName="surface"
        class="form-control"
        placeholder="m²"
      />
    </div>

    <div
      class="row mb-2"
      *ngIf="
        form.get('typeLivrable').value !== types._RESEAU &&
        (form.get('typeLivrable').value === types._2D ||
          form.get('typeLivrable').value === types._3D) &&
        prixAuScan
      "
    >
      <label>Nombre de scans *</label>
      <input type="number" formControlName="nbScans" class="form-control" />
    </div>

    <div
      class="row mb-2"
      *ngIf="form.get('typeLivrable').value === types._RESEAU"
    >
      <label>Surface *</label>
      <div class="input-group">
        <input
          type="number"
          formControlName="surface"
          class="form-control"
          [placeholder]="
            form.get('surfaceUnite').value == null
              ? 'm² ou ml'
              : form.get('surfaceUnite').value.lib
          "
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <ng-container *ngFor="let unite of unites">
              <label class="me-1" style="margin-bottom: 0">
                <input
                  type="radio"
                  formControlName="surfaceUnite"
                  [value]="unite"
                />
                {{ unite.lib }}
              </label>
            </ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="row mb-2" *ngIf="form.get('precision2d')">
      <label
        >Précision *
        <i
          class="fa fa-info-circle pointer"
          (click)="showLodInfo = !showLodInfo"
        ></i
      ></label>
      <div class="alert alert-info" *ngIf="showLodInfo">
        <p>Les plans 2D sont livrables sous deux formats :</p>
        <p>
          - dwg : fichier binaire, c'est le format natif de Autocad, supporté
          par la majorité des logiciels de CAO. Format dxf possible également.
        </p>
        <p>
          - pdf : plans complets pour impression papier annotés, côtés avec
          cartouche (possibilité de transmettre votre propre cartouche).
        </p>
      </div>
      <select class="form-control" formControlName="precision2d">
        <option [ngValue]="null">- Précisions -</option>
        <option *ngFor="let precision of precisions2D" [ngValue]="precision">
          {{ precision }}
        </option>
      </select>
    </div>

    <div class="row mb-2" *ngIf="form.get('precisionGlobale')">
      <label
        >Précision globale *
        <i
          class="fa fa-info-circle pointer"
          (click)="showLodInfo = !showLodInfo"
        ></i
      ></label>
      <div class="alert alert-info" *ngIf="showLodInfo">
        <p>
          Le Niveau de détail de la maquette dépend de la phase du projet dans
          laquelle vous vous trouvez. Internationalement les différents niveaux
          de détails suivants (appelés LOD de l'anglais Level of Detail) ont été
          adoptés:
        </p>
        <p>
          LOD 200: Les éléments du modèle sont représentés graphiquement d'une
          manière générique en tant qu'objet ou assemblage. Les dimensions,
          quantités, formes, positions et orientations des éléments peuvent être
          approximatives.
        </p>
        <p>
          LOD 300: Les éléments du modèle sont représentés graphiquement d'une
          manière spécifique en tant qu'objet ou assemblage. Les dimensions,
          quantités, formes, positions et orientations sont spécifiques aux
          éléments.
        </p>
        <a
          href="http://www.objectif-bim.com/index.php/technologie-bim/la-maquette-numerique/niveau-de-detail-de-la-maquette-numerique"
          target="_blank"
          >Plus d'informations</a
        >
      </div>
      <select class="form-control" formControlName="precisionGlobale">
        <option [ngValue]="null">- Précisions -</option>
        <option *ngFor="let precision of precisions" [ngValue]="precision">
          {{ precision }}
        </option>
      </select>
    </div>

    <div class="row mb-2">
      <label>Commentaire</label>
      <p class="w-100" style="font-size: 0.8em">
        Pour toute autre demande, merci de la formuler ci-dessous, nous
        reviendrons vers vous dans les plus brefs délais
      </p>
      <textarea
        class="w-100 form-control"
        formControlName="commentaire"
      ></textarea>
    </div>

    <div class="row mt-1">
      <div class="hr-sect">
        <span>Informations <i class="fas fa-user"></i></span>
      </div>
    </div>
    <div class="row mb-2">
      <label>Nom du projet *</label>
      <input
        type="text"
        formControlName="nomProjet"
        class="form-control"
        placeholder="Nom du projet"
      />
    </div>
    <div class="row mb-2" [class.mb-4]="user !== null">
      <label>Département du projet *</label>
      <select formControlName="departement" class="form-control">
        <option [ngValue]="null">- Départements -</option>
        <option
          *ngFor="let departement of departements"
          [ngValue]="departement"
        >
          {{ departement.code + " - " + departement.name }}
        </option>
      </select>
    </div>
    <div *ngIf="user === null">
      <div class="row mb-2">
        <label>Email *</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          placeholder="Email"
        />
      </div>
      <div class="row mb-2">
        <label>Téléphone *</label>
        <input
          type="text"
          formControlName="tel"
          class="form-control"
          placeholder="Téléphone"
        />
      </div>
      <div class="row mb-2">
        <label>Nom *</label>
        <input
          type="text"
          formControlName="nomDO"
          class="form-control"
          placeholder="Nom"
        />
      </div>
      <div class="row mb-4">
        <label>Prénom *</label>
        <input
          type="text"
          formControlName="prenomDO"
          class="form-control"
          placeholder="Prénom"
        />
      </div>
      <div class="row mb-4">
        <p
          (click)="form.get('cbRGPD').setValue(!form.get('cbRGPD').value)"
          [class.alert-danger]="ngForm.submitted && form.get('cbRGPD').errors"
          class="pointer"
          style="user-select: none"
        >
          <input type="checkbox" formControlName="cbRGPD" /> En soumettant ce
          formulaire, j'accepte que les informations saisies soient exploitées
          dans le cadre de la demande de devis et de la relation commericale qui
          peut en découler.
        </p>
      </div>
    </div>
    <!--<div class="row alert alert-danger" *ngIf="ngForm.submitted && !form.valid">
      {{form.get('tel').errors | json}}
    </div>-->
    <div class="row mb-3" *ngIf="admin">
      <label>Attacher le projet à un utilisateur existant (admin)</label>
      <ic2-textassist
        class="d-block w-100"
        placeholder="Rechercher un utilisateur..."
        [values]="users"
        [presenter]="userPresenter"
        (textChanges)="userLieTextChanged($event)"
        (objectChange)="userLieChanged($event)"
      >
      </ic2-textassist>
      <div class="text-warning" *ngIf="errorUserLie && ngForm.submitted">
        Attention à bien selectionner un utilisateur dans la liste des
        utilisateurs ou vider avec la croix pour ne pas rattacher à un
        utilisateur
      </div>
    </div>

    <div class="row justify-content-end mb-3">
      <button
        type="submit"
        (click)="onSubmit()"
        [disabled]="loading"
        class="btn btn-success"
      >
        Obtenir mon devis
        <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
      </button>
    </div>

    <div class="row justify-content-end mb-3" *ngIf="devisValide">
      <div class="alert alert-success">
        {{
          userLieSelected !== null
            ? "Le devis à été envoyé par email à " + userLieSelected.user.email
            : "Votre devis vient de vous être envoyé par email."
        }}
      </div>
    </div>
  </div>
</form>
