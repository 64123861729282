import { Component, OnInit, Host } from "@angular/core";
import { ShowProjectComponent } from "../show-project.component";
import {
  FicheProjet,
  Fichier,
  ScanhaRight,
} from "../../../../ic2/entities/entities";
import { environment } from "../../../../../../../environments/environment";
import { ProjetActionService } from "projects/scanha/src/ic2/services/ProjetActionService";
import { L } from "@ic2/ic2-lib";
import { AuthService } from "../../../core/auth.service";

@Component({
  selector: "app-project-files",
  templateUrl: "./project-files.component.html",
  styleUrls: ["./project-files.component.scss"],
})
export class ProjectFilesComponent implements OnInit {
  ficheProjet: FicheProjet;
  rpcUrl: string;
  dls = new Map<number, object>();
  canDeleteFiles: boolean = false;

  constructor(
    public parentComp: ShowProjectComponent,
    public projetActionService: ProjetActionService,
    public authService: AuthService
  ) {
    this.rpcUrl = environment.rpcUrl;
    this.canDeleteFiles = authService.has(ScanhaRight.ADMIN);
  }

  ngOnInit() {
    this.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
    });
  }

  getFichierToken(id: number) {
    return this.ficheProjet.fichierTokenList.find((dt) => {
      return dt.idFichier === id;
    }).token;
  }

  del(f: Fichier) {
    if (
      !confirm(
        "Voulez-vous vraiment supprimer le fichier: " + f.filename + " ?"
      )
    )
      return;
    this.projetActionService.removeFile(f.idFichier).subscribe(
      (data) => {
        this.ficheProjet.fichierList = this.ficheProjet.fichierList.filter(
          (fich) => {
            return fich.idFichier !== f.idFichier;
          }
        );
      },
      (err) => {
        L.e(err);
      }
    );
  }

  dl(f: Fichier) {
    if (this.dls[f.idFichier] && this.dls[f.idFichier].downloading) return;
    let data = {
      url: this.rpcUrl + "fichier/" + this.getFichierToken(f.idFichier),
      chunkSize: 10 * 1024 * 1024,
      size: null,
      nbPart: 1,
      blobParts: [],
      filename: f.filename,
      progressPercent: 0,
      error: false,
      idFichier: f.idFichier,
      downloading: true,
    };
    this.dls[f.idFichier] = data;
    this.fetch(data);
  }

  fetch(data) {
    var myHeaders = new Headers();
    let end = data.nbPart * data.chunkSize;
    if (data.size != null && end > data.size) end = data.size;
    let start = (data.nbPart - 1) * data.chunkSize + (data.nbPart > 1 ? 1 : 0); //+1 car range inclusif
    myHeaders.append("Range", "bytes=" + start + "-" + end);
    console.log("Range: bytes=" + start + "-" + end, data);
    var myInit = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(data.url, myInit)
      .then((response) => {
        if (![200, 206].includes(response.status)) {
          return;
        }
        let range = response.headers.get("Content-Range");
        if (range === null) {
          alert("Une erreur est survenue");
          return;
        }

        data.size = range.substring(range.indexOf("/") + 1);
        response.blob().then((blob) => {
          data.blobParts.push(blob);
          data.error = false;
          data.progressPercent = ((start / data.size) * 100).toFixed(0);

          if (end >= data.size) {
            let blob = new Blob(data.blobParts, {
              type: "application/octet-stream",
            });
            data.progressPercent = 100;
            data.downloading = false;
            this.dls.delete(data.idFichier);
            this.saveAs(blob, data.filename);
          } else {
            data.nbPart++;
            this.fetch(data);
          }
        });
      })
      .catch((err) => {
        console.error(err);
        data.error = true;
        data.nbPart--;
        setTimeout(() => {
          this.fetch(data);
        }, 1000);
      });
  }

  saveAs(blob, fileName) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    //anchorElem.style = "display: none";
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();

    document.body.removeChild(anchorElem);

    // On Edge, revokeObjectURL should be called only after
    // a.click() has completed, atleast on EdgeHTML 15.15048
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}
