// ENTITY com.scanha.metier.facture.dto.FactureAdminDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Facture } from './Facture';

export class FactureAdminDTO {

  facture: Facture | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  societe: string | null = '';
  token: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.facture.dto.FactureAdminDTO'] = FactureAdminDTO;

reverseMapping[FactureAdminDTO.name] = 'com.scanha.metier.facture.dto.FactureAdminDTO';

fields['com.scanha.metier.facture.dto.FactureAdminDTO']  = {
    facture: 'com.scanha.metier.facture.entities.Facture',    firstName: 'java.lang.String',    lastName: 'java.lang.String',    societe: 'java.lang.String',    token: 'java.lang.String'};
