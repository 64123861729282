// ENTITY com.scanha.metier.project.entities.Partage generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Partage {

  idPartage: number | null = 0;
  idProjet: number | null = 0;
  email: string | null = '';
  nbVisites: number | null = 0;
  dateLastVisite: Date | null = null;
  token: string | null = '';
  idUserCreateur: number | null = 0;
  idUserLastModif: number | null = 0;
  dateCreation: Date | null = null;
  lastModif: Date | null = null;
  del: boolean | null = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.entities.Partage'] = Partage;

reverseMapping[Partage.name] = 'com.scanha.metier.project.entities.Partage';

fields['com.scanha.metier.project.entities.Partage']  = {
    idPartage: 'java.lang.Integer',    idProjet: 'java.lang.Integer',    email: 'java.lang.String',    nbVisites: 'java.lang.Integer',    dateLastVisite: 'java.time.Instant',    token: 'java.lang.String',    idUserCreateur: 'java.lang.Integer',    idUserLastModif: 'java.lang.Integer',    dateCreation: 'java.time.Instant',    lastModif: 'java.time.Instant',    del: 'java.lang.Boolean'};
