// com.scanha.metier.tarif.entities.TarifServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { Tarif } from '../entities/Tarif';

@Injectable({
  providedIn: 'root',
})
export class TarifService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTarif(): RpcRequestBuilder<Tarif> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TarifService.getTarif';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.scanha.metier.tarif.entities.Tarif');
  }

  saveTarif(t: Tarif): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TarifService.saveTarif';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(t, 'com.scanha.metier.tarif.entities.Tarif', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
