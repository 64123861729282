// ENTITY com.scanha.metier.project.entities.Fichier generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TypeFichier } from './TypeFichier';

export class Fichier {

  idFichier: number | null = 0;
  idProjet: number | null = 0;
  idUserUpload: number | null = 0;
  dateUpload: Date | null = null;
  filename: string | null = '';
  typeFichier: TypeFichier | null = null;
  uploadTermine: boolean | null = false;
  del: boolean | null = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.entities.Fichier'] = Fichier;

reverseMapping[Fichier.name] = 'com.scanha.metier.project.entities.Fichier';

fields['com.scanha.metier.project.entities.Fichier']  = {
    idFichier: 'java.lang.Integer',    idProjet: 'java.lang.Integer',    idUserUpload: 'java.lang.Integer',    dateUpload: 'java.time.Instant',    filename: 'java.lang.String',    typeFichier: 'com.scanha.metier.project.entities.TypeFichier',    uploadTermine: 'java.lang.Boolean',    del: 'java.lang.Boolean'};
