<p *ngIf="montantVirement === null">Chargement...</p>
<div class="container mt-2" *ngIf="montantVirement !== null">
  <div class="row">
    <div class="col">
      Merci de réaliser un virement bancaire d'un montant de {{montantVirement}} € afin de régler la facture
      n°{{idFacture}}
    </div>
  </div>
  <div class="row">
    <div class="col">
      <img src="{{rpcUrl}}rib.png" />
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <a href="{{rpcUrl}}rib.png">Cliquez pour télécharger le rib</a>
    </div>
  </div>
</div>
