<div class="container-fluid bg-image">
  <div class="row">
    <div class="col text-center" style="margin-top: 5em">
      <h1>Le bâtiment numérisé à portée de main</h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <a class="btn btn-secondary btn-bold-white ps-5 pe-5" routerLink="/devis"
        >Cliquez ici pour<br />
        demander un devis</a
      >
    </div>
  </div>
</div>
<div class="container-fluid mt-5">
  <div class="row">
    <div class="col text-center">
      <h2>Comment ça marche ?</h2>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-4 col-sm-12 text-center">
      <i class="fas fa-laptop picto d-block"></i>
      <br />
      <span class="bold">1 -</span> Obtenez votre devis instantanément et
      validez-le en ligne
    </div>
    <div class="col-md-4 col-sm-12 mt-5 mt-sm-0 text-center">
      <i class="fas fa-user-plus picto d-block"></i>
      <br />
      <span class="bold">2 -</span> SCANha sélectionne pour vous les
      prestataires adaptés<br class="d-none d-xl-inline" />
      à votre demande.
    </div>
    <div class="col-md-4 col-sm-12 mt-5 mt-sm-0 text-center">
      <i class="fas fa-comments picto d-block"></i>
      <br />
      <span class="bold">3 -</span> Prise de rendez-vous avec le scan
      opérateur<br class="d-none d-xl-inline" />
      sur site.
    </div>
    <div class="col-md-4 col-sm-12 mt-5 text-center">
      <i class="fas fa-file-signature picto d-block"></i>
      <br />
      <span class="bold">4 -</span> Réalisation de votre prestation par
      <br class="d-none d-xl-inline" />
      les prestataires dédiés.
    </div>
    <div class="col-md-4 col-sm-12 mt-5 text-center">
      <i class="fas fa-check-circle picto d-block"></i>
      <br />
      <span class="bold">5 -</span> Consultez et validez les données numérisées,
      plans, maquette BIM depuis votre espace SCANha.
    </div>
    <div class="col-md-4 col-sm-12 mt-5 text-center">
      <i class="fas fa-cloud-download-alt picto d-block"></i>
      <br />
      <span class="bold">6 -</span> Téléchargez et partagez les données avec vos
      collaborateurs depuis votre espace SCANha.
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-md-6 col-sm-12 text-center">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/OeKcgjkpPk4"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="col-md-6 col-sm-12 text-center">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/ABx0pxGquE4"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-10 border-bottom border-primary"></div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <h2>Nos tutoriels</h2>
    </div>
  </div>
  <div class="row mt-5 mx-5">
    <div class="col-md-4 col-sm-12 text-center">
      <a
        href="https://relevebatiment3d.com/2020/03/22/premiers-pas-avec-un-releve-numerique-le-pack-de-demarrage/"
      >
        <img
          src="https://relevebatiment3d.files.wordpress.com/2020/03/permiers-pas_relevc3a9-numc3a9rique-1.jpg"
          alt="Nuage de points"
          class="imgex mb-2"
          style="max-height: 250px"
        /><br />
        Premiers pas avec un relevé numérique : le pack de démarrage
      </a>
    </div>
    <div class="col-md-4 col-sm-12 text-center">
      <a
        href="https://relevebatiment3d.com/2020/03/22/tutoriel-faire-un-devis-de-releve-numerique/"
      >
        <img
          src="https://relevebatiment3d.files.wordpress.com/2020/03/scanha.fr_accueil.png?w=765&h=380&crop=1"
          alt="Nuage de points"
          class="imgex mb-2"
          style="max-height: 250px"
        /><br />
        Tutoriel : faire un devis de relevé numérique
      </a>
    </div>
    <div class="col-md-4 col-sm-12 text-center">
      <a
        href="https://relevebatiment3d.com/2020/03/04/tutoriel-comment-utiliser-un-webshare-2-go/"
      >
        <img
          src="https://relevebatiment3d.files.wordpress.com/2020/03/naviguer_webshare_2go_carte-daperc3a7u_mesurer-zone.png?w=765&h=380&crop=1"
          alt="Nuage de points"
          class="imgex mb-2"
          style="max-height: 250px"
        /><br />
        Tutoriel : comment utiliser un webshare 2 GO
      </a>
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-10 border-bottom border-primary"></div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <h2>Nos prestations</h2>
    </div>
  </div>
  <div class="row mt-5" style="max-width: 1440px; margin: auto">
    <div class="col-md-6 col-sm-12 text-center">
      <img
        src="/assets/images/examples/1._Nuage_de_points.jpg"
        alt="Nuage de points"
        class="imgex mb-2"
      /><br />
      Relevé numérique du tel que construit&nbsp;: nuage&nbsp;de&nbsp;points
    </div>
    <div class="col-md-6 col-sm-12 mt-5 mt-sm-0 text-center">
      <img
        src="/assets/images/examples/2._Plans_2D.jpg"
        alt="Plans 2D"
        class="imgex mb-2"
      /><br />
      Conception de plans&nbsp;2D par relevé numérique pour&nbsp;édition
    </div>
    <div class="col-md-6 col-sm-12 mt-5 text-center">
      <img
        src="/assets/images/examples/3._Maquette_BIM.jpg"
        alt="Maquette BIM"
        class="imgex mb-2"
      /><br />
      Modélisation de maquettes&nbsp;3D par relevé numérique
      pour&nbsp;utilisation&nbsp;BIM
    </div>
    <div class="col-md-6 col-sm-12 mt-5 text-center">
      <img
        src="/assets/images/examples/4._Detection_reseau.jpg"
        alt="Detection reseau"
        class="imgex mb-2"
      /><br />
      Détection et géolocalisation de tous les réseaux souterrains<br
        class="d-none d-xl-inline"
      />
      et d'intérieur de bâtiment&nbsp;: sensible&nbsp;ou&nbsp;non
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-10 border-bottom border-primary"></div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <h2>Nous contacter</h2>
    </div>
  </div>
  <div class="row mt-4">
    <app-contact-form></app-contact-form>
  </div>
  <div class="row mt-5">
    <div class="col text-center phone-label">
      Vous pouvez aussi nous contacter directement par téléphone :
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-center phone-number">
      <img src="assets/numero_scanha.png" />
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-10 border-bottom border-primary"></div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <h2>Nos partenaires</h2>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-xl-4 col-md-6 col-xs-12 text-center p-4">
      <img
        src="/assets/images/partenaires/logo-faro.png"
        height="60"
        alt="Faro"
        title="Faro"
      />
    </div>
    <div class="col-xl-4 col-md-6 col-xs-12 mt-4 mt-sm-0 text-center p-4">
      <img
        src="/assets/images/partenaires/logo-elliva.png"
        height="60"
        alt="Elliva"
        title="Elliva"
      />
    </div>
    <div class="col-xl-4 col-md-6 col-xs-12 mt-4 mt-sm-0 text-center p-4">
      <img
        src="/assets/images/partenaires/logo-eurosia.png"
        height="60"
        alt="Eurosia"
        title="Eurosia"
      />
    </div>
  </div>
</div>
