// DB ENUM com.scanha.metier.project.entities.TypeFichier generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class TypeFichier {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly LIVRABLE = new TypeFichier(1, "Livrable", "LIVRABLE");
  static readonly DIVERS = new TypeFichier(2, "Divers", "DIVERS");
  static readonly PHOTO_ANNEXE = new TypeFichier(3, "Photo annexe", "PHOTO_ANNEXE");
  static readonly PREVISUALISATION = new TypeFichier(4, "Prévisualisation", "PREVISUALISATION");

  static readonly values = [
    TypeFichier.LIVRABLE, 
    TypeFichier.DIVERS, 
    TypeFichier.PHOTO_ANNEXE, 
    TypeFichier.PREVISUALISATION 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of TypeFichier.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of TypeFichier.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.TypeFichier'] = TypeFichier;
reverseMapping[TypeFichier.name] = 'com.scanha.metier.project.entities.TypeFichier';
