<table class="table table-hover table-responsive-sm">
  <thead>
    <tr>
      <th>Projet</th>
      <th>Etat</th>
      <th>Livrable</th>
      <th>Surface</th>
    </tr>
  </thead>
  <tbody>
    <tr class="pointer" *ngFor="let projet of projets" [routerLink]="['/client','projet',projet.id]">
      <td>{{projet.nom}}</td>
      <td>{{projet.etatProjet.lib}}</td>
      <td>{{projet.livrable.lib}}</td>
      <td>{{projet.surface}} m²</td>
    </tr>
  </tbody>
</table>
<div class="row justify-content-center">
  <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="nbPerPage" [maxSize]="6" [rotate]="true"
    (pageChange)="onPageChange()" [disabled]="loading"></ngb-pagination>
</div>
