// DB ENUM com.scanha.metier.project.entities.LivrableReseau generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class LivrableReseau {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly RESEAU_DWG = new LivrableReseau(1, "DWG", "RESEAU_DWG");
  static readonly RESEAU_SHAPE = new LivrableReseau(2, "Shape", "RESEAU_SHAPE");
  static readonly RESEAU_DGN = new LivrableReseau(3, "DGN", "RESEAU_DGN");

  static readonly values = [
    LivrableReseau.RESEAU_DWG, 
    LivrableReseau.RESEAU_SHAPE, 
    LivrableReseau.RESEAU_DGN 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of LivrableReseau.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of LivrableReseau.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.LivrableReseau'] = LivrableReseau;
reverseMapping[LivrableReseau.name] = 'com.scanha.metier.project.entities.LivrableReseau';
