export const environment = {
  production: false,
  name: 'RECETTE',
  rpcUrl: 'https://api.recette.scanha.fr/',
  ssrRpcUrl: 'http://rct_api:8080/',
  countries: ['FRANCE', 'BELGIQUE', 'SUISSE'],
  stripePublishableKey: 'pk_test_6Jf5WutcKB9jt31kr14NP1KU',
  paypalClientId: 'AX39topD-tip7a6BsalYR2KN4TLDdlXq4gmLd74EH3gm-8zWJ8DFhbk8VbOAuwgMxKdloaDqvqr0bi1P',
  recaptchaPublicKey: '6Ld5yYsUAAAAAOH92gzuE_1lDzU19aMNBWYUEqJN'
};
