import { Component, OnInit, Host } from "@angular/core";
import { AdminPriceSettingsComponent } from "../admin-price-settings.component";

@Component({
  selector: "app-admin-price-settings-maquette3d",
  templateUrl: "./admin-price-settings-maquette3d.component.html",
  styleUrls: ["./admin-price-settings-maquette3d.component.scss"],
})
export class AdminPriceSettingsMaquette3dComponent implements OnInit {
  constructor(public parent: AdminPriceSettingsComponent) {}

  ngOnInit() {}
}
