import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-address-edit-form',
  templateUrl: './address-edit-form.component.html',
  styleUrls: ['./address-edit-form.component.scss']
})
export class AddressEditFormComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  countries: string[];
  disabled: boolean = false;
  constructor() {
    this.countries = environment.countries;
  }

  ngOnInit() {
  }

}
