import { Component, OnInit, Inject } from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import { AdminProjetDTO } from "../../../ic2/entities/AdminProjetDTO";
import { ProjetService } from "../../../ic2/services/ProjetService";
import { AdminProjetFilter } from "../../../ic2/entities/AdminProjetFilter";
import { L } from "@ic2/ic2-lib";
import { Departement, ScanhaRight } from "../../../ic2/entities/entities";
import { EtatProjet } from "../../../ic2/entities/EtatProjet";
import { UserLiteDTO } from "../../../ic2/entities/UserLiteDTO";
import { ScanhaUserService } from "../../../ic2/services/ScanhaUserService";
import { DepartementCache } from "../../core/DepartementCache.service";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: "app-admin-project-list",
  templateUrl: "./admin-project-list.component.html",
  styleUrls: ["./admin-project-list.component.scss"],
})
export class AdminProjectListComponent implements OnInit {
  projets: AdminProjetDTO[] = [];
  filter: AdminProjetFilter = new AdminProjetFilter();
  loaded: boolean = false;
  loading: boolean = false;
  count = 0;
  nbPerPage = 15;
  page = 1;
  departements: Departement[] = [];
  etats: EtatProjet[] = EtatProjet.values;
  users: UserLiteDTO[] = [];
  apporteurAffaire: boolean;
  admin: boolean;

  constructor(
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ProjetService) public projetService: ProjetService,
    @Inject(ScanhaUserService) private scanhaUserService: ScanhaUserService,
    @Inject(DepartementCache) public departementCache: DepartementCache,
    @Inject(AuthService) private authService: AuthService
  ) {
    scanhaTitleService.title = "Projets";
    scanhaTitleService.icon = "fas fa-tachometer-alt";
    this.apporteurAffaire = this.authService.has(ScanhaRight.APPORTEUR_AFFAIRE);
    this.admin = this.authService.has(ScanhaRight.ADMIN);
  }

  ngOnInit() {
    this.onPageChange();

    this.departementCache.getDepartements().subscribe(
      (data) => {
        this.departements = data;
      },
      (err) => {
        L.e(err);
      }
    );
    this.scanhaUserService.getUsersAssignable().subscribe(
      (data) => {
        this.users = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  filterChanged() {
    this.page = 1;
    this.onPageChange();
  }

  getAssigne(projet: AdminProjetDTO) {
    if (projet.idUserAssigne === 0) return "Non assigné";
    return projet.nomAssigne + " " + projet.prenomAssigne;
  }

  onPageChange() {
    this.loading = true;
    this.projetService
      .getProjetsAdmin(this.page, this.nbPerPage, this.filter)
      .subscribe(
        (data) => {
          this.projets = data.data;
          this.count = data.count;
          this.loaded = true;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          L.e(error);
        }
      );
  }
}
