import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import { ScanhaRight } from "../../../ic2/entities/entities";
import { L } from "@ic2/ic2-lib";
import { ScanhaUserService } from "../../../ic2/services/ScanhaUserService";
import { UserDTO } from "../../../ic2/entities/UserDTO";
import { NgModel } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-admin-user-list",
  templateUrl: "./admin-user-list.component.html",
  styleUrls: ["./admin-user-list.component.scss"],
})
export class AdminUserListComponent implements OnInit, AfterViewInit {
  loaded: boolean = false;
  loading: boolean = false;
  count = 0;
  nbPerPage = 15;
  page = 1;
  users: UserDTO[] = [];
  rights: ScanhaRight[] = ScanhaRight.values;
  selectedRight: ScanhaRight = null;
  searchText: string = null;
  @ViewChild("searchTextInput", { static: true }) searchTextInput: NgModel;
  skipFirstChange: boolean = false;

  constructor(
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ScanhaUserService) private scanhaUserService: ScanhaUserService
  ) {
    scanhaTitleService.title = "Liste des utilisateurs";
    scanhaTitleService.icon = "fa fa-users";
  }

  ngOnInit() {
    this.onPageChange();
  }

  ngAfterViewInit() {
    this.searchTextInput.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (!this.skipFirstChange) {
          this.skipFirstChange = true;
          return;
        }
        this.filterChanged();
      });
  }

  filterChanged() {
    this.page = 1;
    this.onPageChange();
  }

  onPageChange() {
    this.loading = true;
    this.scanhaUserService
      .getUsersAdmin(
        this.page,
        this.nbPerPage,
        this.searchText,
        this.selectedRight
      )
      .subscribe(
        (data) => {
          this.users = data.data;
          this.count = data.count;
          this.loaded = true;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          L.e(error);
        }
      );
  }
}
