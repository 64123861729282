<div class="container mt-4 mb-4">
  <div class="row">
    <div class="col-sm-5 label">Réf du projet : </div>
    <div class="col-sm-7">{{ficheProjet.projet.nom}}</div>
  </div>
  <div class="row">
    <div class="col-sm-5 label">Montant {{ acompte ? 'de l\'accompte' : 'du paiement final' }} : </div>
    <div class="col-sm-7">
      {{montantHT}} € HT
      <span class="small text-muted" *ngIf="acompte">30% de {{dernierDevis.prixFinal}} € HT</span>
      <span class="small text-muted" *ngIf="!acompte">70% de {{dernierDevis.prixFinal}} € HT</span>
    </div>
    <div class="offset-sm-5 col-sm-7">soit {{montantTTC}} € TTC</div>
  </div>
  <div class="row mt-2">
    <div class="col mt-2 mt-sm-0">
      <button class="btn" [class.btn-outline-success]="!paypal" [class.btn-success]="paypal" (click)="modePaiementPaypal()">
        <img src="/assets/images/payment/paypal.png" class="img-payment" />
      </button>
    </div>
    <div class="col mt-2 mt-sm-0">
      <button class="btn" [class.btn-outline-success]="!paiementCB" [class.btn-success]="paiementCB" (click)="modePaiementCB()">
        <img src="/assets/images/payment/creditcard.png" class="img-payment" />
      </button>
    </div>
    <div class="col mt-2 mt-sm-0">
      <button class="btn" [class.btn-outline-success]="!virement" [class.btn-success]="virement" (click)="modePaiementVirement()">
        <img src="/assets/images/payment/credittransfer.png" class="img-payment" />
      </button>
    </div>
  </div>


  <form #checkout="ngForm" (ngSubmit)="validerPaiementCB(checkout)" *ngIf="paiementCB">
    <!-- [formGroup]="form" [class.show-errors]="ngForm.submitted && !form.valid" -->

    <div class="row mt-2">
      <div class="col-sm-12 label">Informations bancaires</div>
    </div>
    <div class="row mt-2" *ngIf="recette">
      <div class="col-sm-12">La carte bancaire de test est : 4242 4242 4242 4242 / Une date dans le futur / un CVC aléatoire</div>
    </div>
    
    <div id="card-info" class="mt-2" #cardInfo></div>

    <div id="card-errors" class="alert alert-danger mt-2" role="alert" *ngIf="error">{{ error }}</div>
    <div class="row justify-content-end mt-3">
      <button type="submit" [disabled]="loading" class="btn btn-success">
        Valider paiement
        <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
      </button>
    </div>
  </form>

  <div class="row justify-content-end mt-3" *ngIf="virement">
    <button type="submit" (click)="validerPaiementVirement()" [disabled]="loading" class="btn btn-success">
      Valider paiement par virement
      <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
    </button>
  </div>

  <div class="row justify-content-center mt-2" *ngIf="paypal">
      <div class="row mt-2" *ngIf="recette">
        <div class="col-sm-12">Le compte paypal de test est : contact-buyer@creha.fr / 12345678</div>
      </div>
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>

  <div class="row mt-2" *ngIf="success">
    <div class="alert alert-success">
      {{success}}
    </div>
  </div>

</div>
