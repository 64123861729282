// DB ENUM com.scanha.metier.project.entities.Livrable2D generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class Livrable2D {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PLAN2D = new Livrable2D(1, "Plan 2D", "PLAN2D");

  static readonly values = [
    Livrable2D.PLAN2D 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of Livrable2D.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of Livrable2D.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.Livrable2D'] = Livrable2D;
reverseMapping[Livrable2D.name] = 'com.scanha.metier.project.entities.Livrable2D';
