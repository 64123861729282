<div class="container-fluid mt-4 mb-4">
  <div class="row">
    <div class="col text-center">
      <input
        type="file"
        id="file"
        multiple
        style="display: none"
        accept="{{ authorizedFiles }}"
        #fileInput
        (change)="handleFileInput($event)"
      />
      <button class="btn btn-secondary me-2" (click)="fileInput.click()">
        Sélectionner des fichiers à envoyer
      </button>
    </div>
  </div>

  <div class="container-fluid mt-2">
    <div class="row bg-primary text-white">
      <div class="col-2">Nom du fichier</div>
      <div class="col-2 text-center" *ngFor="let type of fileTypes">
        {{ type.lib }}
      </div>
      <div class="col-2 text-center">Envoi</div>
    </div>
    <div class="row" *ngIf="files.length === 0">
      <div class="col border text-center">
        <div class="text-primary d-inline-block">Aucun fichier sélectionné</div>
      </div>
    </div>
    <div
      class="row"
      *ngFor="let file of files; let index = index; trackBy: trackByIndex"
    >
      <div class="col-2 border">
        <div class="text-primary d-inline-block break">{{ file.name }}</div>
      </div>
      <div
        *ngFor="let type of fileTypes"
        class="col-2 border text-center"
        [class.bg-primary]="file.fileType === type"
        (click)="files[index].fileType = type"
      >
        <input
          type="radio"
          name="radio-{{ index }}"
          [(ngModel)]="files[index].fileType"
          [value]="type"
        />
      </div>

      <div
        class="col-2 border d-flex justify-content-center align-items-center"
      >
        <div *ngIf="file.uploading && file.progressPercent != '100'">
          Envoi...
        </div>
        <div *ngIf="file.progressPercent == '100'">Terminé !</div>
        <div
          class="progress ms-2"
          style="width: 150px"
          *ngIf="file.progressPercent"
        >
          <div
            class="progress-bar"
            [class.bg-success]="file.progressPercent == '100'"
            role="progressbar"
            [style.background-color]="file.error ? 'red' : null"
            [style.width]="file.progressPercent + '%'"
          >
            {{ file.error ? "Erreur, réessai..." : file.progressPercent }}%
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col text-end">
      <button
        class="btn btn-success"
        [disabled]="loading || files.length === 0 || !checkAllFilesHasType()"
        (click)="envoyer()"
      >
        Envoyer
      </button>
    </div>
  </div>
  <div class="row mt-1" *ngIf="ficheProjet.fichierList.length > 0">
    <div class="col">
      <button class="btn btn-success" (click)="envoiEnValidationFinale()">
        Envoyer le dossier en validation finale
      </button>
    </div>
  </div>
</div>
