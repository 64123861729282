import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PartageService } from "../../../ic2/services/PartageService";
import { L } from "@ic2/ic2-lib";
import { ShowPartageDTO } from "../../../ic2/entities/entities";
import { environment } from "../../../../../../environments/environment";

@Component({
  selector: "app-partage-projet",
  templateUrl: "./partage-projet.component.html",
  styleUrls: ["./partage-projet.component.scss"],
})
export class PartageProjetComponent implements OnInit {
  sub: any;
  token: string;
  partage: ShowPartageDTO;
  error: string = null;
  rpcUrl: string;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(PartageService) public partageService: PartageService
  ) {
    this.rpcUrl = environment.rpcUrl;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params["token"] === undefined) return;

      this.token = params["token"];
      this.reload();
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  reload() {
    this.partageService.getPartage(this.token).subscribe(
      (data) => {
        this.partage = data;
      },
      (err) => {
        if (err.code == -32999)
          //business
          this.error = "Le partage n'est plus accessible";
        else L.e(err);
      }
    );
  }

  getFichierToken(id: number) {
    return this.partage.fichierTokenList.find((dt) => {
      return dt.idFichier === id;
    }).token;
  }
}
