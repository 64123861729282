// com.scanha.metier.facture.service.FactureServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { FactureAdminDTO } from '../entities/FactureAdminDTO';

@Injectable({
  providedIn: 'root',
})
export class FactureService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFacturesAdmin(page: number, nbPerPage: number): RpcRequestBuilder<ListWithCount<FactureAdminDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FactureService.getFacturesAdmin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  facturePayee(idFacture: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FactureService.facturePayee';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFacture, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
