// ENTITY com.scanha.metier.devis.entities.Devis generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { LivrableReseau } from './LivrableReseau';
import { TypologieBatiment } from './TypologieBatiment';
import { NiveauPrecision } from './NiveauPrecision';
import { LivrableReleveNumerique } from './LivrableReleveNumerique';
import { TypeLivrable } from './TypeLivrable';
import { Precision2D } from './Precision2D';
import { Livrable2D } from './Livrable2D';
import { SurfaceUnite } from './SurfaceUnite';
import { Livrable3D } from './Livrable3D';
import { TypologieReseau } from './TypologieReseau';

export class Devis {

  id: number | null = 0;
  idProjet: number | null = 0;
  dateCreation: Date | null = null;
  livrable: TypeLivrable | null = null;
  livrable2D: Livrable2D | null = null;
  livrable3D: Livrable3D | null = null;
  livrableReseau: LivrableReseau | null = null;
  livrableReleveNumerique: LivrableReleveNumerique | null = null;
  typologieBatiment: TypologieBatiment | null = null;
  typologieReseau: TypologieReseau | null = null;
  surface: number | null = 0;
  nbScans: number | null = 0;
  surfaceUnite: SurfaceUnite | null = null;
  precision2d: Precision2D | null = null;
  nbNiveaux: number | null = 0;
  nbCoupes: number | null = 0;
  nbFacades: number | null = 0;
  precisionGlobale: NiveauPrecision | null = null;
  idDepartement: number | null = 0;
  genereAuto: boolean | null = false;
  prixAuto: number | null = 0;
  prixAvantRemise: number | null = 0;
  remisePourcent: number | null = 0;
  prixFinal: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.devis.entities.Devis'] = Devis;

reverseMapping[Devis.name] = 'com.scanha.metier.devis.entities.Devis';

fields['com.scanha.metier.devis.entities.Devis']  = {
    id: 'java.lang.Integer',    idProjet: 'java.lang.Integer',    dateCreation: 'java.time.Instant',    livrable: 'com.scanha.metier.project.entities.TypeLivrable',    livrable2D: 'com.scanha.metier.project.entities.Livrable2D',    livrable3D: 'com.scanha.metier.project.entities.Livrable3D',    livrableReseau: 'com.scanha.metier.project.entities.LivrableReseau',    livrableReleveNumerique: 'com.scanha.metier.project.entities.LivrableReleveNumerique',    typologieBatiment: 'com.scanha.metier.project.entities.TypologieBatiment',    typologieReseau: 'com.scanha.metier.project.entities.TypologieReseau',    surface: 'java.lang.Integer',    nbScans: 'java.lang.Integer',    surfaceUnite: 'com.scanha.metier.project.entities.SurfaceUnite',    precision2d: 'com.scanha.metier.project.entities.Precision2D',    nbNiveaux: 'java.lang.Integer',    nbCoupes: 'java.lang.Integer',    nbFacades: 'java.lang.Integer',    precisionGlobale: 'com.scanha.metier.project.entities.NiveauPrecision',    idDepartement: 'java.lang.Integer',    genereAuto: 'java.lang.Boolean',    prixAuto: 'java.lang.Double',    prixAvantRemise: 'java.lang.Double',    remisePourcent: 'java.lang.Integer',    prixFinal: 'java.lang.Double'};
