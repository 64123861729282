// ENTITY com.scanha.metier.project.dto.PayeurInfos generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Address } from './Address';

export class PayeurInfos {

  email: string | null = '';
  tel: string | null = '';
  firstName: string | null = '';
  lastName: string | null = '';
  societe: string | null = '';
  fonction: string | null = '';
  siret: string | null = '';
  adresse: Address | null = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.PayeurInfos'] = PayeurInfos;

reverseMapping[PayeurInfos.name] = 'com.scanha.metier.project.dto.PayeurInfos';

fields['com.scanha.metier.project.dto.PayeurInfos']  = {
    email: 'java.lang.String',    tel: 'java.lang.String',    firstName: 'java.lang.String',    lastName: 'java.lang.String',    societe: 'java.lang.String',    fonction: 'java.lang.String',    siret: 'java.lang.String',    adresse: 'com.scanha.metier.user.entities.Address'};
