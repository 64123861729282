import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ScanhaTitleService {
  public changes = new EventEmitter<void>(true);
  public _icon: string = "";
  public _title: string = "Chargement...";

  /**
   * @deprecated
   */
  set icon(val) {
    this._icon = val;
    this.changes.emit();
  }
  /**
   * @deprecated
   */
  set title(val) {
    this._title = val;
    this.changes.emit();
  }

  setTitleAndIcon(title, icon) {
    this._icon = icon;
    this._title = title;
    this.changes.emit();
  }

  constructor() {}
}
