// ENTITY com.scanha.metier.facture.dto.MontantDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class MontantDTO {

  montantHT: number | null = 0;
  montantTTC: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.facture.dto.MontantDTO'] = MontantDTO;

reverseMapping[MontantDTO.name] = 'com.scanha.metier.facture.dto.MontantDTO';

fields['com.scanha.metier.facture.dto.MontantDTO']  = {
    montantHT: 'java.lang.Double',    montantTTC: 'java.lang.Double'};
