<div class="container mt-3" *ngIf="partage == null && error == null">
  <div class="row">
    <div class="col">Chargement...</div>
  </div>
</div>
<div class="container mt-3" *ngIf="error">
  <div class="row">
    <div class="col alert alert-danger">
      {{ error }}
    </div>
  </div>
</div>
<div class="container mt-3" *ngIf="partage != null">
  <div class="row mb-4">
    <div class="col text-center">
      <h1>{{ partage.nomProjet }}</h1>
    </div>
  </div>
  <div class="row mb-4" *ngFor="let f of partage.fichierList">
    <div class="col-md-6 col-sm-12" style="word-break: break-word">
      <a
        href="{{ rpcUrl + 'fichier/' + getFichierToken(f.idFichier) }}"
        target="_blank"
        >{{ f.filename }}</a
      >
    </div>
    <div class="col-md-3 col-sm-6">{{ f.typeFichier.lib }}</div>
    <div class="col-md-3 col-sm-6 text-end">
      <a
        href="{{ rpcUrl + 'fichier/' + getFichierToken(f.idFichier) }}"
        target="_blank"
        ><i class="fas fa-download"></i
      ></a>
    </div>
  </div>
</div>
