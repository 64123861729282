import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import {
  TypologieBatiment,
  NiveauPrecision,
  Departement,
  ForfaitDeplacementDepartement,
  CoefPrecision2D,
  Palier,
  SurfaceUnite,
  CoefTypologieReseau,
  CoefReseau,
} from "../../../ic2/entities/entities";
import { TarifService } from "../../../ic2/services/TarifService";
import { Tarif } from "../../../ic2/entities/Tarif";
import { CoefTypologieBatiment } from "../../../ic2/entities/CoefTypologieBatiment";
import LivrablePerDimension from "../../core/LivrablePerDimension";
import { CoefPrecision } from "../../../ic2/entities/CoefPrecision";
import { L } from "@ic2/ic2-lib";
import { DepartementCache } from "../../core/DepartementCache.service";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-admin-price-settings",
  templateUrl: "./admin-price-settings.component.html",
  styleUrls: ["./admin-price-settings.component.scss"],
})
export class AdminPriceSettingsComponent implements OnInit {
  @ViewChild(NgbNav)
  tabset: NgbNav;
  loading: boolean = false;
  edited: boolean = false;
  tarif: Tarif = null;
  departements: Departement[] = [];
  surface: number = 250;
  surfaceRetenue: number = 0;

  //2D
  coefPrecision2DSelected: CoefPrecision2D = null;
  precision2DPrice: number = 0;
  nbNiveaux: number = 1;
  nbCoupes: number = 1;
  nbFacades: number = 1;
  coefTarif2D: number = 0;
  tarif2DPrice: number = 0;
  planchesPrice: number = 0;

  //3D
  coefTarif3D: number = 0;
  tarif3DPrice: number = 0;
  precisions: NiveauPrecision[] = NiveauPrecision.values;
  coefPrecisionGlobale3DSelected: CoefPrecision = null;
  precisionGlobale3DPrice: number = 0;

  //RELEVE
  coefTarifReleve: number = 0;
  tarifRelevePrice: number = 0;

  //RESEAU
  coefReseauSelected: CoefReseau = null;
  reseauPrice: number = 0;
  coefTypologieReseauSelected: CoefTypologieReseau = null;
  typologieReseauPrice: number = 0;

  //ALL
  typologies: TypologieBatiment[] = TypologieBatiment.values;
  coefTypologieSelected: CoefTypologieBatiment = null;
  typologiePrice: number = 0;

  forfaitDeplacementSelected: ForfaitDeplacementDepartement = null;

  coefMarge: number = 0;
  margePrice: number = 0;

  total: number = 0;

  constructor(
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(TarifService) public tarifService: TarifService,
    @Inject(DepartementCache) public departementCache: DepartementCache,
    private cd: ChangeDetectorRef
  ) {
    scanhaTitleService.title = "Paramétrage tarif";
    scanhaTitleService.icon = "fa fa-dollar-sign";
  }

  ngOnInit() {
    this.tarifService.getTarif().subscribe(
      (data) => {
        this.tarif = data;
        //console.log(data);
        this.cd.detectChanges();
        this.calcul();
      },
      (err) => {
        L.e(err);
      }
    );
    this.departementCache.getDepartements().subscribe(
      (data) => {
        this.departements = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  getDepartementStr(id: number) {
    let d = this.departements.find((el) => el.id === id);
    if (d) return d.code + " - " + d.name;
    return null;
  }

  change() {
    this.edited = true;
    this.calcul();
  }

  calcul() {
    if (this.tabset === undefined) return;
    this.total = 0;

    let surface = this.surface;
    if (this.surface < 150) surface = 150;
    this.surfaceRetenue = surface;
    //console.log('calcul', this);

    if (this.tabset.activeId === "plan2d") {
      this.coefTarif2D = AdminPriceSettingsComponent.getCoefPalier(
        this.tarif.tarif2D,
        surface
      );
      if (this.nbNiveaux > 0 && this.nbCoupes === 0 && this.nbFacades === 0)
        this.tarif2DPrice =
          this.coefTarif2D * this.tarif.coefNiveauUniquement * surface;
      else if (
        this.nbFacades > 0 &&
        this.nbCoupes === 0 &&
        this.nbNiveaux === 0
      )
        this.tarif2DPrice =
          this.coefTarif2D * this.tarif.coefFacadeUniquement * surface;
      else this.tarif2DPrice = this.coefTarif2D * surface;

      this.planchesPrice =
        (this.nbCoupes + this.nbFacades + this.nbNiveaux) *
        this.tarif.coefPlanche;

      if (this.coefTypologieSelected)
        this.typologiePrice = this.coefTypologieSelected.coef * surface;
      else this.typologiePrice = 0;

      if (this.coefPrecision2DSelected)
        this.precision2DPrice = this.coefPrecision2DSelected.coef * surface;
      else this.precision2DPrice = 0;

      this.total =
        this.tarif2DPrice +
        this.planchesPrice +
        this.typologiePrice +
        this.precision2DPrice;
    } else if (this.tabset.activeId === "maquette3d") {
      this.coefTarif3D = AdminPriceSettingsComponent.getCoefPalier(
        this.tarif.tarif3D,
        surface
      );
      this.tarif3DPrice = this.coefTarif3D * surface;

      if (this.coefPrecisionGlobale3DSelected)
        this.precisionGlobale3DPrice =
          this.coefPrecisionGlobale3DSelected.coef * surface;
      else this.precisionGlobale3DPrice = 0;

      if (this.coefTypologieSelected)
        this.typologiePrice = this.coefTypologieSelected.coef * surface;
      else this.typologiePrice = 0;

      this.total =
        this.tarif3DPrice + this.precisionGlobale3DPrice + this.typologiePrice;
    } else if (this.tabset.activeId === "releve") {
      this.coefTarifReleve = AdminPriceSettingsComponent.getCoefPalier(
        this.tarif.tarifReleve,
        surface
      );
      this.tarifRelevePrice = this.coefTarifReleve * surface;

      if (this.coefTypologieSelected)
        this.typologiePrice = this.coefTypologieSelected.coef * surface;
      else this.typologiePrice = 0;

      this.total = this.tarifRelevePrice + this.typologiePrice;
    } else if (this.tabset.activeId === "reseau") {
      if (this.coefReseauSelected)
        this.reseauPrice = this.coefReseauSelected.coef * surface;
      else this.reseauPrice = 0;

      if (this.coefTypologieReseauSelected)
        this.typologieReseauPrice =
          this.coefTypologieReseauSelected.coef * surface;
      else this.typologieReseauPrice = 0;

      this.total = this.reseauPrice + this.typologieReseauPrice;
    }

    if (this.forfaitDeplacementSelected)
      this.total += this.forfaitDeplacementSelected.prix;

    this.coefMarge = AdminPriceSettingsComponent.getCoefPalier(
      this.tarif.paliersMarge,
      surface
    );
    this.margePrice = this.coefMarge * surface;
    this.total += this.margePrice;

    this.total = +this.total.toFixed(2);
  }

  static getCoefPalier(paliers: Palier[], surface: number): number {
    let palierDessous = paliers.reduce((t, u, idx, arr) => {
      if (u.min <= surface && u.min > t.min) return u;
      return t;
    });
    //console.log('palier dessous pour ' + surface, palierDessous);
    let palierDessus = paliers.reduce((t, u, idx, arr) => {
      if (t.min < surface) return u;
      if (u.min >= surface && u.min < t.min) return u;
      return t;
    });
    //console.log('palier dessus pour ' + surface, palierDessus);

    if (palierDessous.min === palierDessus.min) return palierDessous.coef;
    return AdminPriceSettingsComponent.linearInterpolation(
      palierDessous.min,
      palierDessous.coef,
      palierDessus.min,
      palierDessus.coef,
      surface
    );
  }

  static linearInterpolation(
    a: number,
    valA: number,
    b: number,
    valB: number,
    c: number
  ): number {
    return valA + (c - a) * ((valB - valA) / (b - a));
  }

  onSubmit() {
    this.loading = true;
    this.tarifService.saveTarif(this.tarif).subscribe(
      (data) => {
        this.edited = false;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  reorder(arr: Palier[]) {
    arr.sort((a, b) => a.min - b.min);
  }
  addPalierAfter(arr: Palier[], i) {
    let p = new Palier();
    p.min = arr[i].min + 1;
    p.coef = arr[i].coef + 1;
    arr.splice(i + 1, 0, p);
    this.change();
  }
  removePalier(arr: Palier[], i) {
    arr.splice(i, 1);
    this.change();
  }
}
