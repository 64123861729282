// com.scanha.metier.project.service.PartageServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ShowPartageDTO } from '../entities/ShowPartageDTO';
import { PartageDTO } from '../entities/PartageDTO';
import { PartageFichier } from '../entities/PartageFichier';

@Injectable({
  providedIn: 'root',
})
export class PartageService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPartages(idProjet: number): RpcRequestBuilder<PartageDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PartageService.getPartages';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  createPartage(partage: PartageDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PartageService.createPartage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(partage, 'com.scanha.metier.project.dto.PartageDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updatePartage(idPartage: number, fichiers: PartageFichier[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PartageService.updatePartage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPartage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(fichiers, 'java.util.List<com.scanha.metier.project.entities.PartageFichier>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deletePartage(idPartage: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PartageService.deletePartage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPartage, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getPartage(token: string): RpcRequestBuilder<ShowPartageDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PartageService.getPartage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.scanha.metier.project.dto.ShowPartageDTO');
  }

  getEmailPartagesRecents(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PartageService.getEmailPartagesRecents';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
