// com.scanha.metier.user.service.ScanhaUserServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { LoginResponse } from '../entities/LoginResponse';
import { Address } from '../entities/Address';
import { UserDTO } from '../entities/UserDTO';
import { ScanhaRight } from '../entities/ScanhaRight';
import { UserInfo } from '../entities/UserInfo';
import { UserLiteDTO } from '../entities/UserLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class ScanhaUserService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getUserInfo(idUser: number): RpcRequestBuilder<UserDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.getUserInfo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.scanha.metier.user.dto.UserDTO');
  }

  updateUser(idUser: number, email: string, newPassword: string, tel: string, firstName: string, lastName: string, societe: string, fonction: string, siret: string, adresse: Address, right: ScanhaRight, prixAuScan: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.updateUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(newPassword, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(tel, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(societe, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(fonction, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(adresse, 'com.scanha.metier.user.entities.Address', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(right, 'com.scanha.metier.ScanhaRight', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(prixAuScan, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getUsersAssignable(): RpcRequestBuilder<UserLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.getUsersAssignable';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  searchUsersAssignable(text: string): RpcRequestBuilder<UserLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.searchUsersAssignable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(text, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getMyInfo(): RpcRequestBuilder<UserInfo> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.getMyInfo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.scanha.metier.user.entities.UserInfo');
  }

  createUser(email: string, tel: string, firstName: string, lastName: string, societe: string, fonction: string, siret: string, adresse: Address, right: ScanhaRight, prixAuScan: boolean, idProjetLie: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.createUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(tel, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(societe, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(fonction, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(adresse, 'com.scanha.metier.user.entities.Address', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(right, 'com.scanha.metier.ScanhaRight', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(prixAuScan, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProjetLie, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  createPassword(password: string, pwdToken: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.createPassword';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(pwdToken, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  getUsersAdmin(page: number, nbPerPage: number, textSearch: string, right: ScanhaRight): RpcRequestBuilder<ListWithCount<UserDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.getUsersAdmin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(textSearch, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(right, 'com.scanha.metier.ScanhaRight', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  mdpOublie(email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ScanhaUserService.mdpOublie';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
