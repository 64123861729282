import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): Observable<boolean> {
        return Observable.create((observer) => {
            this.authService.isLoggedIn().subscribe(
                (data) => {
                    let loggedIn = data;
                    observer.next(loggedIn);
                    //console.log('login',loggedIn);
                    if (!loggedIn) {
                        // Store the attempted URL for redirecting
                        this.authService.redirectUrl = url;

                        // Navigate to the login page with extras
                        this.router.navigate(['/login']);
                    }
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                });
        });
    }
}