// com.scanha.metier.facture.service.PaiementServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { MontantDTO } from '../entities/MontantDTO';

@Injectable({
  providedIn: 'root',
})
export class PaiementService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getMontantAPayer(idProjet: number, acompte: boolean): RpcRequestBuilder<MontantDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaiementService.getMontantAPayer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acompte, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.scanha.metier.facture.dto.MontantDTO');
  }

  paiementStripe(idProjet: number, stripeToken: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaiementService.paiementStripe';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(stripeToken, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  paiementVirement(idProjet: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaiementService.paiementVirement';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  paiementPaypal(idProjet: number, paypalData: object): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaiementService.paiementPaypal';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(paypalData, 'com.ic2.json.JSONObject', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
