<div class="container" *ngIf="!modeModif">
  <div class="row mb-2 align-items-center">
    <div class="col-6">Votre projet:</div>
    <div class="col-6">
      {{ ficheProjet.projet.nom }}
    </div>
    <div class="col-6">Votre livrable:</div>
    <div class="col-6">
      {{ ficheProjet.projet.livrable.lib }}
    </div>
    <div class="col-6">Surface du projet:</div>
    <div class="col-6">{{ ficheProjet.projet.surface }} m²</div>
    <div class="col-6">Prix du projet:</div>
    <div class="col-6">{{ prixFinal }} € HT</div>
  </div>
  <div class="row justify-content-center">
    <button
      class="btn btn-success"
      (click)="valideDevis()"
      [disabled]="loading"
    >
      Valider le devis
    </button>
  </div>
  <div class="row mb-2 justify-content-end">
    <u
      ><a
        class="text-secondary pointer"
        (click)="modeModif = true"
        [class.disabled]="loading"
        >Demander une modification</a
      ></u
    >
  </div>
  <div class="row" *ngIf="error">
    <div class="alert alert-danger">
      {{ error }}
    </div>
  </div>
  <div class="row" *ngIf="success">
    <div class="alert alert-success">
      {{ success }}
    </div>
  </div>
</div>
<div class="container" *ngIf="modeModif">
  <div class="row mb-2 mt-2">
    <div class="col">
      <textarea
        rows="3"
        class="w-100"
        placeholder="Votre message ici"
        [(ngModel)]="modifComment"
      ></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <u
        ><a
          class="text-secondary pointer"
          (click)="modeModif = false"
          [class.disabled]="loading"
          >Annuler</a
        ></u
      >
    </div>
    <div class="row" *ngIf="error">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
    <div class="row" *ngIf="success">
      <div class="alert alert-success">
        {{ success }}
      </div>
    </div>
    <div class="col text-end">
      <button
        class="btn btn-secondary"
        (click)="demandeModif()"
        [disabled]="loading"
      >
        Envoyer ma demande de modif
      </button>
    </div>
  </div>
</div>
