// ENTITY com.scanha.metier.tarif.entities.ForfaitDeplacementDepartement generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ForfaitDeplacementDepartement {

  prix: number | null = 0;
  idDepartement: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.tarif.entities.ForfaitDeplacementDepartement'] = ForfaitDeplacementDepartement;

reverseMapping[ForfaitDeplacementDepartement.name] = 'com.scanha.metier.tarif.entities.ForfaitDeplacementDepartement';

fields['com.scanha.metier.tarif.entities.ForfaitDeplacementDepartement']  = {
    prix: 'java.lang.Double',    idDepartement: 'java.lang.Integer'};
