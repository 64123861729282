<div [formGroup]="form">
  <div class="row">
    <!--<div class="col-xs-6 col-md-12 mb-3">
      <input name="person" class="form-control" placeholder="Destinataire" formControlName="personne" />
    </div>-->
    <div class="col-xs-12 col-md-12 mb-3">
      <input name="addressLine" class="form-control" placeholder="Adresse" formControlName="ligne" />
    </div>
    <div class="col-xs-12 col-md-12 mb-3">
      <input name="addressLine2" class="form-control" placeholder="Complément d'adresse" formControlName="ligne2" />
    </div>
    <div class="col-xs-5 col-md-5 mb-3">
      <input class="form-control" placeholder="Code postal" formControlName="cp" />
    </div>
    <div class="col-xs-7 col-md-7 mb-3">
      <input name="city" class="form-control" placeholder="Ville" formControlName="ville" />
    </div>
    <div class="col-xs-12 col-md-12 mb-3">
      <select name="country" class="form-control" formControlName="pays">
        <option [ngValue]="null">- Pays -</option>
        <option *ngFor="let country of countries" [ngValue]="country">
          {{country}}
        </option>
      </select>
    </div>
  </div>
</div>
