import { Component } from "@angular/core";
import { AdminPriceSettingsComponent } from "../admin-price-settings.component";

@Component({
  selector: "app-admin-price-settings-prixauscan",
  templateUrl: "./admin-price-settings-prixauscan.component.html",
  styleUrls: ["./admin-price-settings-prixauscan.component.scss"],
})
export class AdminPriceSettingsPrixauscanComponent {
  constructor(public parent: AdminPriceSettingsComponent) {}

  ngOnInit() {}
}
