import { Injectable } from "@angular/core";
import { DepartementService } from "../../ic2/services/DepartementService";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { Departement } from "../../ic2/entities/entities";
import { L } from "@ic2/ic2-lib";

@Injectable({
  providedIn: "root",
})
export class DepartementCache {
  departements: ReplaySubject<Departement[]> = new ReplaySubject();
  loaded: boolean = false;
  constructor(private departementService: DepartementService) {}

  getDepartements() {
    if (!this.loaded) {
      this.departementService.getAllDepartements().subscribe(
        (data) => {
          this.loaded = true;
          this.departements.next(data);
          this.departements.complete();
        },
        (err) => {
          L.e(err);
        }
      );
    }
    return this.departements;
  }
}
