<h1>RGPD - Politique de traitement des données personnelles</h1>

<p>La politique énoncée ci-après s’applique aux traitements de données personnelles effectués par SCANha SAS de droit
  français au capital de 1&nbsp;000 &nbsp;€, immatriculée au registre du commerce et des sociétés de Châlons en
  Champagne sous le numéro 844 543 454, ayant son siège social 2 rue Saint Dominique 51000 Châlons en Champagne,
  représentée par Aurélie HUSSON, dûment habilité en sa qualité de Président (ci-après désigné le responsable du
  traitement).</p>

<h2>1 – Dispositions générales</h2>
<p>Les dispositions qui suivent concernent tous les traitements de données personnelles effectués par le responsable du
  traitement, sauf mention contraire dans les dispositions spécifiques.</p>
<h3>Cadre juridique – conformité au RGPD et à la loi française</h3>
<p>Le responsable du traitement déclare qu’il effectue des traitements de données personnelles conformément au
  règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016, relatif à la protection des personnes
  physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données
  (ci-après désigné le RGPD) et à loi française n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
  aux libertés (modifiée).</p>
<h3>Responsable du traitement et autres intervenants</h3>
<p>Le responsable du traitement est identifié ci-dessus. Ses coordonnées sont&nbsp;: 2 rue Saint Dominique 51000
  Châlons en Champagne, 03&nbsp;26&nbsp;24&nbsp;91&nbsp;33, aurelie.husson@sanha.fr.</p>
<h3>Destinataires des données à caractère personnel</h3>
<p>Les destinataires des données sont uniquement les collaborateurs de la société SCANha.</p>
<h3>Transfert de données</h3>
<p>Le responsable du traitement n’a pas l’intention d’effectuer un transfert de données à caractère personnel vers un
  pays tiers ou à une organisation internationale.</p>
<h3>Durée de conservation des données à caractère personnel</h3>
<p>Les données seront conservées pendant une durée de 3 ans à compter, de leur collecte pour les prospects, de la fin
  de l’exécution de la prestation pour les clients.</p>
<h3>Droits de la personne dont les données sont collectées</h3>
<p>La personne, dont les données personnelles sont collectées, a le droit&nbsp;:</p>
<ul>
  <li>de demander au responsable du traitement l’accès aux données à caractère personnel, la rectifi¬cation ou
    l’effacement de celles-ci, ou une limitation du traitement relatif à la personne concernée,</li>
  <li>de s’opposer au traitement,</li>
  <li>à la portabilité de ses données,</li>
  <li>d’introduire une réclamation auprès d’une autorité de contrôle,</li>
  <li>de retirer son consentement à tout moment, sans porter atteinte à la licéité du traitement fondé sur le
    consentement effectué avant le retrait de celui-ci, ce droit existant exclusivement lorsque le traitement est fondé
    sur l’article 6, paragraphe 1, point a), ou sur l’article 9, paragraphe 2, point a) du RGPD, c’est-à-dire sur le
    consentement de la personne concernée au traitement de ses données à caractère personnel pour une ou plusieurs
    finalités spécifiques.</li>
</ul>
<h3>Prise de décision automatisée – profilage</h3>
<p>Sauf mention contraire dans les dispositions spécifiques, aucun profilage ne sera réalisé et plus généralement
  aucune décision automatisée ne sera prise sur la base des données collectées.</p>

<h2>2 – Dispositions spécifiques</h2>
<p>Les dispositions qui suivent sont spécifiques à chaque type de traitement de données personnelles.</p>
<h3>Gestion de la relation avec nos contacts et prospects</h3>
<b>Données personnelles traitées</b>
<p>Nous traitons les données personnelles suivantes&nbsp;: civilité, prénom, nom, coordonnées téléphoniques, adresse
  électronique, adresse postale, profession, organisation (société, entreprise ou autre), numéros d’identification
  (SIRET et TVA), fonctions et activités, pays.</p>
<b>Finalités</b>
<p>Le traitement de données personnelles est destiné à la gestion de la relation avec nos contacts et prospects. En
  particulier, ce traitement tend à communiquer à la personne concernée des informations sur les actualités de notre
  organisation, nos produits et nos services.</p>
<b>Base juridique</b>
<p>Ce traitement de données personnelles est fondé sur le consentement de la personne concernée (article 6, paragraphe
  1, point a) du RGPD). La demande de données a un caractère contractuel. La personne concernée n’est pas tenue de
  fournir ces données. Si la personne concernée ne fournit pas les données ou retire son consentement au traitement de
  données, elle ne pourra pas recevoir d’information sur les actualités de notre organisation, nos produits et nos
  services.</p>
<h3>Gestion de la relation avec nos clients</h3>
<b>Données personnelles traitées</b>
<p>Nous traitons les données personnelles suivantes&nbsp;: civilité, prénom, nom, coordonnées téléphoniques, adresse
  électronique, adresse postale, profession, organisation (société, entreprise ou autre), numéros d’identification
  (SIRET, TVA), fonctions et activités, pays, date de naissance, nationalité, produit ou service acheté, prix d’achat,
  remise éventuelle, éventuelle offre promotionnelle, date et lieu d’achat, lieu de livraison, canal de distribution,
  modalités de paiement, informations relatives au paiement.</p>
<b>Finalités</b>
<p>Le traitement de données personnelles est destiné à la gestion de la relation avec nos clients. En particulier, ce
  traitement tend à exécuter les mesures précontractuelles prises à la demande de la personne concernée, exécuter le
  contrat auquel la personne concernée est partie, respecter nos obligations légales, fiscales et comptables.</p>
<b>Base juridique</b>
<p>Ce traitement est nécessaire à l’exécution d’un contrat auquel la personne concernée est partie ou à l’exécution de
  mesures précontractuelles prises à la demande de celle-ci. Il est fondé sur l’article 6, paragraphe 1, point b) du
  RGPD. La demande de données a un caractère contractuel. La personne concernée est tenue de fournir ces données si
  elle souhaite bénéficier de nos produits ou de nos services. Si la personne concernée ne fournit pas les données,
  elle ne pourra pas devenir cliente, acquérir nos produits ou bénéficier de nos services.</p>
<h3>Analyse et amélioration de nos produits et services</h3>
<b>Données personnelles traitées</b>
<p>Nous traitons les données suivantes&nbsp;: profession, organisation (société, entreprise ou autre), fonctions et
  activités, pays, produit ou service acheté, prix d’achat, remise éventuelle, éventuelle offre promotionnelle, date et
  lieu d’achat, lieu de livraison, canal de distribution, modalités de paiement.</p>
<b>Finalités</b>
<p>Ce traitement est utilisé à des fins d’analyse et d’étude statistique de l’utilisation de produits et services.</p>
<b>Base juridique</b>
<p>Le traitement est nécessaire aux fins des intérêts légitimes que nous poursuivons, consistant à améliorer et
  optimiser les produits et services. Il est fondé sur l’article 6, paragraphe 1, point f) du RGPD. La demande de
  données a un caractère contractuel. La personne concernée n’est pas tenue de fournir ces données et elle peut
  s’opposer à tout moment à leur traitement.</p>
<h3>Messages marketing et publicitaires</h3>
<b>Données personnelles traitées</b>
<p>Nous traitons les données suivantes&nbsp;: civilité, prénom, nom, adresse électronique, adresse postale, profession,
  organisation (société, entreprise ou autre), fonctions et activités, pays, produit ou service acheté, prix d’achat,
  remise éventuelle, éventuelle offre promotionnelle, date et lieu d’achat, canal de distribution, modalités de
  paiement.</p>
<b>Finalités</b>
<p>Le traitement est destiné à améliorer notre communication et à vous adresser des contenus pertinents, de nouveaux
  services et des offres promotionnelles à des moments opportuns.</p>
<b>Prise de décision automatisée – profilage</b>
<p>Des décisions automatisées seront prises sur la base des données collectées. La logique sous-jacente de ces
  décisions automatisées est la suivante&nbsp;: identification du secteur d’activité, lieu géographique. L’importance
  et les conséquences prévues de ce traitement pour la personne concernée sont les suivantes&nbsp;: réception
  d’informations sur les actualités de notre organisation, nos produits et nos services, d’offres promotionnelles.</p>
<b>Base juridique</b>
<p>Le traitement repose sur le consentement de la personne concernée (article 6, paragraphe 1, point a) du RGPD) et est
  nécessaire aux fins des intérêts légitimes que nous poursuivons, consistant à améliorer notre communication et à
  éviter d’adresser des communications inopportunes (article 6, paragraphe 1, point f) du RGPD). La demande de données
  a un caractère contractuel. La personne concernée n’est pas tenue de fournir ces données et elle peut s’opposer à
  tout moment à leur traitement. Si la personne concernée ne fournit pas les données ou retire son consentement au
  traitement de données, elle ne pourra pas recevoir des communications adaptées.</p>
<h3>Sécurité et prévention des activités illicites</h3>
<b>Données personnelles traitées</b>
<p>Nous traitons les données suivantes&nbsp;: civilité, prénom, nom, coordonnées téléphoniques, adresse électronique,
  adresse postale, profession, organisation (société, entreprise ou autre), numéros d’identification (SIRET, TVA),
  fonctions et activités, pays, date de naissance, nationalité, produit ou service acheté, prix d’achat, remise
  éventuelle, éventuelle offre promotionnelle, date et lieu d’achat, lieu de livraison, canal de distribution,
  modalités de paiement, informations relatives au paiement.</p>
<b>Finalités</b>
<p>Le traitement est destiné à assurer la sécurité de nos produits et services et à prévenir les activités illicites en
  lien avec nos produits et services.</p>
<b>Base juridique</b>
<p>Ce traitement est nécessaire au respect des obligations légales auxquelles le responsable du traitement est soumis
  (article 6, paragraphe 1, point c) du RGPD) et il est nécessaire aux fins des intérêts légitimes que nous
  poursuivons, consistant à assurer la sécurité de nos produits et de nos services et à prévenir les activités
  illicites (article 6, paragraphe 1, point f) du RGPD). La demande de données est conforme aux dispositions
  législatives et réglementaires applicables. La personne concernée est tenue de fournir ces données si elle souhaite
  bénéficier de nos produits et services. Si la personne concernée ne fournit pas les données, elle ne pourra pas
  recevoir d’information sur les actualités de notre organisation, nos produits et nos services, devenir cliente,
  acquérir nos produits ou bénéficier de nos services.</p>
