// DB ENUM com.scanha.metier.project.entities.LivrableReleveNumerique generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class LivrableReleveNumerique {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly RCP = new LivrableReleveNumerique(1, "format rcp", "RCP");
  static readonly ISPROJ = new LivrableReleveNumerique(2, "format isproj", "ISPROJ");

  static readonly values = [
    LivrableReleveNumerique.RCP, 
    LivrableReleveNumerique.ISPROJ 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of LivrableReleveNumerique.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of LivrableReleveNumerique.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.LivrableReleveNumerique'] = LivrableReleveNumerique;
reverseMapping[LivrableReleveNumerique.name] = 'com.scanha.metier.project.entities.LivrableReleveNumerique';
