import { UntypedFormGroup, AbstractControl } from "@angular/forms";

export class UserEditValidators {
    static siretValide(control: AbstractControl) {
        let siret = control.value;
        var estValide;
        if (siret === null || siret.length === 0)//valide si non saisi
            return null;
        if (siret.length != 14 || isNaN(siret))
            estValide = false;
        else {
            // Donc le SIRET est un numérique à 14 chiffres
            // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
            // correspondent au numéro d'établissement
            // et enfin le dernier chiffre est une clef de LUHN. 
            var somme = 0;
            var tmp;
            for (var cpt = 0; cpt < siret.length; cpt++) {
                if ((cpt % 2) == 0) { // Les positions impaires : 1er, 3è, 5è, etc... 
                    tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
                    if (tmp > 9)
                        tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                }
                else
                    tmp = siret.charAt(cpt);
                somme += parseInt(tmp);
            }
            if ((somme % 10) == 0)
                estValide = true; // Si la somme est un multiple de 10 alors le SIRET est valide 
            else
                estValide = false;
        }
        return estValide ? null : { invalidSiret: true };
    }

    static checkPasswords(name1: string, name2: string) {
        return (group: UntypedFormGroup) => {
            let pass = group.controls[name1].value;
            let confirmPass = group.controls[name2].value;
            if (pass !== null && pass.length != 0 && pass.length < 8) {
                group.controls[name1].setErrors({ minLength: 8 });
                return { minLength: 8 };
            }
            if (pass === confirmPass)
                return null;
            group.controls[name2].setErrors({ notSame: true });
            return { notSame: true };
        };
    }
}