// ENTITY com.scanha.metier.project.dto.PartageDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Partage } from './Partage';
import { PartageFichier } from './PartageFichier';

export class PartageDTO {

  partage: Partage | null = null;
  fichiers: PartageFichier[] | null = [];

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.PartageDTO'] = PartageDTO;

reverseMapping[PartageDTO.name] = 'com.scanha.metier.project.dto.PartageDTO';

fields['com.scanha.metier.project.dto.PartageDTO']  = {
    partage: 'com.scanha.metier.project.entities.Partage',    fichiers: 'java.util.List<com.scanha.metier.project.entities.PartageFichier>'};
