// ENTITY com.scanha.metier.facture.dto.FactureToken generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FactureToken {

  idFacture: number | null = 0;
  token: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.facture.dto.FactureToken'] = FactureToken;

reverseMapping[FactureToken.name] = 'com.scanha.metier.facture.dto.FactureToken';

fields['com.scanha.metier.facture.dto.FactureToken']  = {
    idFacture: 'java.lang.Integer',    token: 'java.lang.String'};
