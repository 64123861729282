// DB ENUM com.scanha.metier.facture.entities.ModePaiement generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class ModePaiement {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly VIREMENT = new ModePaiement(1, "Virement", "VIREMENT");
  static readonly STRIPE = new ModePaiement(2, "Stripe", "STRIPE");
  static readonly PAYPAL = new ModePaiement(3, "Paypal", "PAYPAL");

  static readonly values = [
    ModePaiement.VIREMENT, 
    ModePaiement.STRIPE, 
    ModePaiement.PAYPAL 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ModePaiement.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ModePaiement.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.facture.entities.ModePaiement'] = ModePaiement;
reverseMapping[ModePaiement.name] = 'com.scanha.metier.facture.entities.ModePaiement';
