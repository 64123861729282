<div class="container mt-4 mb-4">
  <div class="row">
    <div class="col-md-5 col-sm-12">
      <ul class="list-group">
        <li
          class="list-group-item pointer no-select"
          [class.bg-danger]="p.partage.del"
          [class.active]="currentPartage == p"
          (click)="select(p)"
          *ngFor="let p of partages"
        >
          {{ p.partage.email }}
          <i *ngIf="p.partage.del" class="fas fa-trash ms-2"></i>
        </li>
        <li class="list-group-item" *ngIf="addMode">
          <input
            type="text"
            id="email"
            email
            required
            class="form-control"
            placeholder="E-mail"
            [(ngModel)]="currentPartage.partage.email"
          />
        </li>
        <li class="list-group-item text-end" *ngIf="!addMode">
          <button class="btn btn-success" [disabled]="loading" (click)="add()">
            Ajouter
          </button>
        </li>
      </ul>
    </div>
    <div class="col-md-7 col-sm-12">
      <div class="container-fluid" *ngIf="currentPartage != null">
        <div class="row" *ngIf="!currentPartage.partage.del">
          <div class="col text-end">
            <button
              class="btn btn-sm btn-secondary"
              [disabled]="loading"
              (click)="selectAll()"
            >
              Tout sélectionner
            </button>
          </div>
        </div>
        <div class="row" *ngFor="let f of ficheProjet.fichierList">
          <div class="col" style="word-break: break-word">
            <input
              type="checkbox"
              [ngModel]="getValue(f)"
              (change)="setValue($event, f)"
              #cb
            /><span class="ms-2 pointer no-select" (click)="cb.click()">{{
              f.filename
            }}</span>
          </div>
        </div>
        <div class="row border p-3 mb-2 mt-2" *ngIf="editMode">
          <a
            target="_blank"
            href="/partage-projet/{{ currentPartage.partage.token }}"
            >Accéder au partage</a
          ><br />
          {{ currentPartage.partage.nbVisites }} visites<br />
          Dernière visite le
          {{
            currentPartage.partage.dateLastVisite == null
              ? "Jamais"
              : (currentPartage.partage.dateLastVisite
                | date : "HH:mm dd/MM/yyyy")
          }}
        </div>
        <div class="row" *ngIf="!currentPartage.partage.del">
          <div class="col text-end">
            <button
              class="btn btn-danger me-2"
              [disabled]="loading"
              *ngIf="editMode"
              (click)="deleteCurrent()"
            >
              Supprimer
            </button>
            <button
              class="btn btn-success"
              [disabled]="loading"
              (click)="save()"
            >
              {{ addMode ? "Partager" : "Editer" }}
            </button>
          </div>
        </div>
        <div class="row mt-2" *ngIf="success">
          <div class="col">
            <div class="alert alert-success">
              {{ success }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
