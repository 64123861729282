<header class="d-flex align-content-center mb-4">
  <div class="row">
    <div class="col">
      <button class="btn menu-toggle" (click)="menuVisible = !menuVisible">
        <i class="fa fa-bars"></i>
      </button>
      <a href="/"
        ><img
          src="assets/images/logo-scanha-header_196x50.png"
          width="196"
          height="50"
          class="ms-0 ms-md-4"
      /></a>
      <!--<h1 class="d-inline-block text-primary titre align-middle">Espace client</h1>-->
    </div>
  </div>
</header>
<div class="row-offcanvas row-offcanvas-left" [class.active]="menuVisible">
  <div id="sidebar" class="sidebar-offcanvas">
    <div class="px-3">
      <!--<h3>Espace client</h3>-->
      <nav class="nav flex-column bg-light">
        <a
          *ngIf="admin || donneurOrdre || dessinateur || scanop"
          class="nav-link"
          routerLink="/client/projets"
          routerLinkActive="active"
          (click)="menuVisible = false"
          [class.active]="router.isActive('/client/projet', false)"
          ><i class="fas fa-tachometer-alt"></i>Mes projets</a
        >
        <a
          *ngIf="admin || apporteurAffaire"
          class="nav-link"
          routerLink="/admin/projets"
          routerLinkActive="active"
          (click)="menuVisible = false"
          [class.active]="router.isActive('/admin/projet', false)"
          ><i class="fas fa-tachometer-alt"></i>Projets</a
        >
        <a
          *ngIf="admin"
          class="nav-link"
          routerLink="/admin/factures"
          routerLinkActive="active"
          (click)="menuVisible = false"
          ><i class="fas fa-file-invoice"></i>Liste des factures</a
        >
        <a
          *ngIf="admin || apporteurAffaire"
          class="nav-link"
          routerLink="/admin/utilisateurs"
          routerLinkActive="active"
          (click)="menuVisible = false"
          ><i class="fa fa-users"></i>Liste des utilisateurs</a
        >
        <a
          *ngIf="admin || apporteurAffaire"
          class="nav-link"
          routerLink="/admin/creation-compte"
          routerLinkActive="active"
          (click)="menuVisible = false"
          ><i class="fa fa-user-plus"></i>Création de compte</a
        >
        <a
          *ngIf="admin"
          class="nav-link"
          routerLink="/admin/parametrage-tarif"
          routerLinkActive="active"
          (click)="menuVisible = false"
          ><i class="fa fa-dollar-sign"></i>Paramétrage tarif</a
        >
        <a
          *ngIf="admin || donneurOrdre"
          class="nav-link"
          routerLink="/client/nouveau-projet"
          routerLinkActive="active"
          (click)="menuVisible = false"
          ><i class="fas fa-building"></i>Nouveau projet</a
        >
        <a
          class="nav-link"
          routerLink="/client/mon-compte"
          routerLinkActive="active"
          (click)="menuVisible = false"
          ><i class="fa fa-user"></i>Mon compte</a
        >
        <a class="nav-link" routerLink="" (click)="logout()"
          ><i class="fas fa-sign-out-alt"></i>Se déconnecter</a
        >
      </nav>
    </div>
  </div>
  <div id="main">
    <div class="title p-3 mb-3"><i class="{{ icon }}"></i>{{ title }}</div>
    <main class="p-3">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<!--/row-offcanvas -->
