// ENTITY com.scanha.metier.user.entities.Address generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Address {

  id: number | null = 0;
  personne: string | null = '';
  ligne: string | null = '';
  ligne2: string | null = '';
  cp: string | null = '';
  ville: string | null = '';
  pays: string | null = '';
  del: boolean | null = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.user.entities.Address'] = Address;

reverseMapping[Address.name] = 'com.scanha.metier.user.entities.Address';

fields['com.scanha.metier.user.entities.Address']  = {
    id: 'java.lang.Integer',    personne: 'java.lang.String',    ligne: 'java.lang.String',    ligne2: 'java.lang.String',    cp: 'java.lang.String',    ville: 'java.lang.String',    pays: 'java.lang.String',    del: 'java.lang.Boolean'};
