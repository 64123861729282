// ENTITY com.scanha.metier.devis.dto.DevisToken generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class DevisToken {

  idDevis: number | null = 0;
  token: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.devis.dto.DevisToken'] = DevisToken;

reverseMapping[DevisToken.name] = 'com.scanha.metier.devis.dto.DevisToken';

fields['com.scanha.metier.devis.dto.DevisToken']  = {
    idDevis: 'java.lang.Integer',    token: 'java.lang.String'};
