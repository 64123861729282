import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  UntypedFormControl,
} from "@angular/forms";
import {
  Departement,
  TypologieBatiment,
  NiveauPrecision,
  User,
  UserConfig,
  LivrableReleveNumerique,
  LivrableReseau,
  Livrable3D,
  Livrable2D,
  TypeLivrable,
  SurfaceUnite,
  TypologieReseau,
  Precision2D,
  ScanhaRight,
  UserDTO,
} from "../../../ic2/entities/entities";
import { DevisService } from "../../../ic2/services/DevisService";
import { L } from "@ic2/ic2-lib";
import LivrablePerDimension from "../../core/LivrablePerDimension";
import { AuthService } from "../../core/auth.service";
import { Router } from "@angular/router";
import { ScanhaTitleService } from "../../client-space/client-space-layout/scanha-title.service";
import { DepartementCache } from "../../core/DepartementCache.service";
import { ScanhaUserService } from "../../../ic2/services/ScanhaUserService";
import { UserInfo } from "../../../ic2/entities/UserInfo";

@Component({
  selector: "app-devis-form",
  templateUrl: "./devis-form.component.html",
  styleUrls: ["./devis-form.component.scss"],
})
export class DevisFormComponent implements OnInit, AfterViewInit {
  form = this.fb.group({
    typeLivrable: [null, [Validators.required]],

    surface: [null, []],
    nbScans: [null, []],

    nomProjet: ["", [Validators.required]],
    departement: [null, [Validators.required]],

    commentaire: [null, []],
  });
  plan2dControls: { [s: string]: UntypedFormControl } = {
    typologieBatiment: this.fb.control(null, [Validators.required]),
    precision2d: this.fb.control(null, [Validators.required]),
    nbCoupes: this.fb.control(0, []),
    nbFacades: this.fb.control(0, []),
    nbNiveaux: this.fb.control(0, []),
  };
  maquette3dControls: { [s: string]: UntypedFormControl } = {
    livrable3D: this.fb.control(null, [Validators.required]),
    precisionGlobale: this.fb.control(null, [Validators.required]),
    typologieBatiment: this.fb.control(null, [Validators.required]),
  };
  releveNumeriqueControls: { [s: string]: UntypedFormControl } = {
    livrableReleve: this.fb.control(null, [Validators.required]),
    typologieBatiment: this.fb.control(null, [Validators.required]),
  };
  reseauControls: { [s: string]: UntypedFormControl } = {
    livrableReseau: this.fb.control(null, [Validators.required]),
    surfaceUnite: this.fb.control(SurfaceUnite.M2, [Validators.required]),
    typologieReseau: this.fb.control(null, [Validators.required]),
  };

  oldLivrable: TypeLivrable = null;
  types: typeof TypeLivrable = TypeLivrable;
  typeLivrables: TypeLivrable[] = TypeLivrable.values;
  livrables3D: Livrable3D[] = LivrablePerDimension["3D"];
  livrablesReseau: LivrableReseau[] = LivrablePerDimension["Reseau"];
  livrablesReleve: LivrableReleveNumerique[] =
    LivrablePerDimension["Nuage de point"];
  typologies: TypologieBatiment[] = TypologieBatiment.values;
  typologiesReseau: TypologieReseau[] = TypologieReseau.values;
  precisions: NiveauPrecision[] = NiveauPrecision.values;
  precisions2D: Precision2D[] = Precision2D.values;
  unites: SurfaceUnite[] = SurfaceUnite.values;
  loading: boolean = false;
  showErrors: boolean = false;
  showLodInfo: boolean = false;
  departements: Departement[] = [];
  user: User = null;
  userInfo: UserInfo;
  devisValide: boolean;
  admin: boolean = false;
  users: UserDTO[] = [];
  userPresenter = (u: UserDTO) => u.user.firstName + " " + u.user.lastName;
  userLieSelected: UserDTO = null;
  userText: string = null;
  errorUserLie: boolean = false;
  prixAuScan: boolean = false;

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(DepartementCache) public departementCache: DepartementCache,
    @Inject(ScanhaUserService) public scanhaUserService: ScanhaUserService,
    @Inject(DevisService) private devisService: DevisService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) public router: Router,
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.departementCache.getDepartements().subscribe((data) => {
      this.departements = data;
    });

    this.authService.isLoggedIn().subscribe((data) => {
      if (data) {
        this.router.navigate(["client", "nouveau-projet"], {
          replaceUrl: true,
        });
        this.user = this.authService.userBundle.user;
        this.scanhaUserService.getMyInfo().subscribe(
          (data) => {
            this.userInfo = data;
            this.prixAuScan = this.userInfo.prixAuScan;
          },
          (err) => {
            L.e(err);
          }
        );
        this.admin = this.authService.has(ScanhaRight.ADMIN);
      } else {
        this.form.addControl(
          "email",
          this.fb.control("", [Validators.required, Validators.email])
        );
        this.form.addControl(
          "tel",
          this.fb.control("", [
            Validators.required,
            Validators.pattern("[0-9\\+\\-\\ ]+"),
          ])
        );
        this.form.addControl(
          "nomDO",
          this.fb.control("", [Validators.required])
        );
        this.form.addControl(
          "prenomDO",
          this.fb.control("", [Validators.required])
        );
        this.form.addControl(
          "cbRGPD",
          this.fb.control("", [Validators.requiredTrue])
        );
      }

      //console.log(this.authService.userBundle);
    });
  }

  ngAfterViewInit() {
    this.scanhaTitleService.setTitleAndIcon(
      "Nouveau projet",
      "fas fa-building"
    );
  }

  userLieTextChanged(txt) {
    console.log("changed", txt);
    this.userText = txt;
    this.errorUserLie = false;
    if (
      (this.userLieSelected === null && this.userText.length != 0) ||
      (this.userLieSelected !== null &&
        this.userPresenter(this.userLieSelected) !== this.userText)
    )
      this.errorUserLie = true;
    this.scanhaUserService.getUsersAdmin(1, 10, txt, null).subscribe(
      (data) => {
        this.users = data.data;
      },
      (err) => {
        L.e(err);
      }
    );
  }
  userLieChanged(user) {
    this.userLieSelected = user;

    this.errorUserLie = false;
    if (
      (this.userLieSelected === null && this.userText.length != 0) ||
      (this.userLieSelected !== null &&
        this.userPresenter(this.userLieSelected) !== this.userText)
    )
      this.errorUserLie = true;

    console.log("selected", user);
  }

  livrableChanged() {
    let livrableSelectione = this.form.get("typeLivrable").value;
    if (this.oldLivrable) this.toggleFormControls(this.oldLivrable, false);
    this.oldLivrable = livrableSelectione;
    this.toggleFormControls(livrableSelectione, true);
  }

  toggleFormControls(
    typeLivrable: TypeLivrable,
    trueMeansAddFalseMeansRemove: boolean
  ) {
    if (typeLivrable === TypeLivrable._2D) {
      if (trueMeansAddFalseMeansRemove)
        this.addFormControls(this.plan2dControls);
      else this.removeFormControls(this.plan2dControls);
    } else if (typeLivrable === TypeLivrable._3D) {
      if (trueMeansAddFalseMeansRemove)
        this.addFormControls(this.maquette3dControls);
      else this.removeFormControls(this.maquette3dControls);
    } else if (typeLivrable === TypeLivrable._RELEVE_NUMERIQUE) {
      if (trueMeansAddFalseMeansRemove)
        this.addFormControls(this.releveNumeriqueControls);
      else this.removeFormControls(this.releveNumeriqueControls);
    } else if (typeLivrable === TypeLivrable._RESEAU) {
      if (trueMeansAddFalseMeansRemove)
        this.addFormControls(this.reseauControls);
      else this.removeFormControls(this.reseauControls);
    }
  }

  addFormControls(controls: { [s: string]: UntypedFormControl }) {
    for (let entry of Object.entries(controls)) {
      this.form.addControl(entry[0], entry[1]);
    }
  }
  removeFormControls(controls: { [s: string]: UntypedFormControl }) {
    for (let name of Object.keys(controls)) this.form.removeControl(name);
  }

  onSubmit() {
    //console.log('SUBMIT', this.form);

    let value = this.form.value;
    if (
      value.typeLivrable === TypeLivrable._2D &&
      value.nbCoupes + value.nbNiveaux + value.nbFacades === 0
    ) {
      this.form.get("nbCoupes").setErrors({ sumMustBeOver1: true });
      this.form.get("nbNiveaux").setErrors({ sumMustBeOver1: true });
      this.form.get("nbFacades").setErrors({ sumMustBeOver1: true });
      return;
    }
    if (
      this.prixAuScan &&
      (value.nbScans <= 0 || value.nbScans === null) &&
      (value.typeLivrable === TypeLivrable._2D ||
        value.typeLivrable === TypeLivrable._3D)
    ) {
      this.form.get("nbScans").setErrors({ min: true });
      return;
    } else if (
      !this.prixAuScan &&
      (value.surface <= 0 || value.surface === null)
    ) {
      this.form.get("surface").setErrors({ min: true });
    }

    if (this.errorUserLie) {
      console.log("Erreur utilisateur commencé a être tapé sans etre cliqué");
      return;
    }

    if (!this.form.valid) {
      console.log(this.form);
      return;
    }

    let email = this.user === null ? value.email : this.user.email;
    let tel = this.user === null ? value.tel : this.userInfo.tel;
    let nomDO = this.user === null ? value.nomDO : this.user.lastName;
    let prenomDO = this.user === null ? value.prenomDO : this.user.firstName;

    this.devisService
      .demandeDevis(
        value.typeLivrable,

        value.typeLivrable === TypeLivrable._2D ? Livrable2D.PLAN2D : null,
        value.typeLivrable !== TypeLivrable._RESEAU
          ? value.typologieBatiment
          : null,
        value.typeLivrable === TypeLivrable._2D ? value.precision2d : null,
        value.typeLivrable === TypeLivrable._2D ? value.nbNiveaux : 0,
        value.typeLivrable === TypeLivrable._2D ? value.nbCoupes : 0,
        value.typeLivrable === TypeLivrable._2D ? value.nbFacades : 0,

        value.typeLivrable === TypeLivrable._3D ? value.livrable3D : null,
        value.typeLivrable === TypeLivrable._3D ? value.precisionGlobale : null,

        value.typeLivrable === TypeLivrable._RESEAU
          ? value.livrableReseau
          : null,
        value.typeLivrable === TypeLivrable._RESEAU
          ? value.typologieReseau
          : null,
        value.typeLivrable === TypeLivrable._RESEAU
          ? value.surfaceUnite
          : SurfaceUnite.M2,

        value.typeLivrable === TypeLivrable._RELEVE_NUMERIQUE
          ? value.livrableReleve
          : null,

        value.surface === null ? 0 : value.surface,
        value.nbScans === null ? 0 : value.nbScans,
        value.commentaire,

        email,
        value.nomProjet,
        tel,
        nomDO,
        prenomDO,
        value.departement.id,
        this.userLieSelected !== null ? this.userLieSelected.user.idUser : 0
      )
      .subscribe(
        (data) => {
          this.devisValide = true;
          setTimeout(() => {
            this.devisValide = false;
            this.loading = false;
            this.form.reset();
          }, 3000);
        },
        (error) => {
          L.e(error);
        }
      );
  }
}
