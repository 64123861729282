// ENTITY com.scanha.metier.project.dto.UploadedFileDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TypeFichier } from './TypeFichier';

export class UploadedFileDTO {

  idFichier: number | null = 0;
  fileName: string | null = '';
  fileType: TypeFichier | null = null;
  size: number | null = 0;
  start: number | null = 0;
  partSize: number | null = 0;
  partNumber: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.UploadedFileDTO'] = UploadedFileDTO;

reverseMapping[UploadedFileDTO.name] = 'com.scanha.metier.project.dto.UploadedFileDTO';

fields['com.scanha.metier.project.dto.UploadedFileDTO']  = {
    idFichier: 'java.lang.Integer',    fileName: 'java.lang.String',    fileType: 'com.scanha.metier.project.entities.TypeFichier',    size: 'java.lang.Long',    start: 'java.lang.Long',    partSize: 'java.lang.Long',    partNumber: 'java.lang.Integer'};
