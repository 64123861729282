<div class="card mb-3" *ngIf="admin">
  <div class="card-body">
    <h4>Filtres</h4>
    <div class="row">
      <div class="col-md-3 col-sm-12">Etat</div>
      <div class="col-md-9 col-sm-12">
        <select [(ngModel)]="filter.etat" class="form-control" (change)="filterChanged()">
          <option [ngValue]="null">- Etats -</option>
          <option *ngFor="let etat of etats" [ngValue]="etat">
            {{etat.lib}}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-12 mt-2">Département du projet</div>
      <div class="col-md-9 col-sm-12 mt-sm-0 mt-md-2">
        <select [(ngModel)]="filter.idDepartement" class="form-control" (change)="filterChanged()">
          <option [ngValue]="0">- Départements -</option>
          <option *ngFor="let departement of departements" [ngValue]="departement.id">
            {{departement.code + ' - ' + departement.name}}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-12 mt-2">Assigné</div>
      <div class="col-md-9 col-sm-12 mt-sm-0 mt-md-2">
        <select [(ngModel)]="filter.idUserAssigne" class="form-control" (change)="filterChanged()">
          <option [ngValue]="0">- Utilisateurs -</option>
          <option *ngFor="let user of users" [ngValue]="user.idUser">
            {{user.lastName + ' ' + user.firstName}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
<table class="table table-hover table-responsive-sm">
  <thead>
    <tr>
      <th>#</th>
      <th>Etat</th>
      <th>Assigné</th>
      <th>Client</th>
      <th>Nom projet</th>
      <th>Surface</th>
      <th>Livrable</th>
    </tr>
  </thead>
  <tbody>
    <tr [class.pointer]="admin" [class.bg-success]="!projet.dejaClient" *ngFor="let projet of projets" [routerLink]="admin ? ['/admin','projet',projet.id] : []">
      <td>{{projet.id}}</td>
      <td>{{projet.etatProjet.lib}}</td>
      <td>{{getAssigne(projet)}}</td>
      <td>{{projet.nomDO+' '+projet.prenomDO}}</td>
      <td>{{projet.nom}}</td>
      <td>{{projet.surface}} m²</td>
      <td>{{projet.livrable.lib}}</td>
    </tr>
  </tbody>
</table>
<div class="row justify-content-center">
  <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="nbPerPage" [maxSize]="6" [rotate]="true"
    (pageChange)="onPageChange()" [disabled]="loading"></ngb-pagination>
</div>

<div class="row">
  <div class="col-2">Légende: </div>
  <div class="col-2 p-1 bg-success">Prospects</div>
</div>
