<ng-container *ngIf="parent.tarif !== null">
  <div class="mt-3">
    <label>Surface</label>
    <input
      type="number"
      [(ngModel)]="parent.surface"
      (ngModelChange)="parent.change()"
      class="form-control"
      placeholder="m²"
    />
  </div>

  <div class="mt-3">
    <label>Tarif relevé numérique</label>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">Minimum m²</div>
        <div class="col-6">Coefficient</div>
        <ng-container
          *ngFor="let palier of parent.tarif.tarifReleve; let i = index"
        >
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.tarifReleve[i].min"
              (ngModelChange)="parent.change()"
              (blur)="parent.reorder(parent.tarif.tarifReleve)"
              class="form-control"
            />
          </div>
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.tarifReleve[i].coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <i
              class="fas fa-trash-alt pointer text-danger"
              *ngIf="parent.tarif.tarifReleve.length > 1"
              (click)="parent.removePalier(parent.tarif.tarifReleve, i)"
            ></i>
            <i
              class="fas fa-plus-circle ms-2 pointer text-success"
              (click)="parent.addPalierAfter(parent.tarif.tarifReleve, i)"
            ></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <label>Typologie batiment</label>
    <table class="table align-middle table-responsive-sm">
      <tr *ngFor="let cl of parent.tarif.coefsTypologieBatiment">
        <td
          class="pointer"
          [class.active]="cl === parent.coefTypologieSelected"
          (click)="parent.coefTypologieSelected = cl; parent.calcul()"
        >
          {{ cl.typologieBatiment.lib }}
        </td>
        <td>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">coef</span>
            </div>
            <input
              type="number"
              [(ngModel)]="cl.coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="mt-3">
    <label>Marge</label>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">Minimum m²</div>
        <div class="col-6">Coefficient</div>
        <ng-container
          *ngFor="let palier of parent.tarif.paliersMarge; let i = index"
        >
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.paliersMarge[i].min"
              (ngModelChange)="parent.change()"
              (blur)="parent.reorder(parent.tarif.paliersMarge)"
              class="form-control"
            />
          </div>
          <div class="col-5">
            <input
              type="number"
              [(ngModel)]="parent.tarif.paliersMarge[i].coef"
              (ngModelChange)="parent.change()"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <i
              class="fas fa-trash-alt pointer text-danger"
              *ngIf="parent.tarif.paliersMarge.length > 1"
              (click)="parent.removePalier(parent.tarif.paliersMarge, i)"
            ></i>
            <i
              class="fas fa-plus-circle ms-2 pointer text-success"
              (click)="parent.addPalierAfter(parent.tarif.paliersMarge, i)"
            ></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
