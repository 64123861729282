import { Component, OnInit, Host, Inject } from "@angular/core";
import {
  FicheProjet,
  TypeFichier,
  UploadedFileDTO,
} from "../../../../../ic2/entities/entities";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { ProjetActionService } from "../../../../../ic2/services/ProjetActionService";
import {
  IRPC,
  JSONSerializer,
  L,
  RpcError,
  RpcRequestBuilder,
} from "@ic2/ic2-lib";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
interface MyFile extends File {
  fileType: any;
  uf: UploadedFileDTO;
  uploading: boolean;
  error: boolean;
  progressPercent: string;
}
@Component({
  selector: "app-action-upload-files",
  templateUrl: "./action-upload-files.component.html",
  styleUrls: ["./action-upload-files.component.scss"],
})
export class ActionUploadFilesComponent implements OnInit {
  ficheProjet: FicheProjet;
  loading: boolean;
  success: string;
  files: MyFile[] = [];
  fileTypes: TypeFichier[] = TypeFichier.values;
  authorizedFiles: string =
    "image/*,.obj,.rcp,.isproj,.dwg,.dxf,.ifc,.pln,.bpn,.rvt,.rfa,.rte,.rft,.shape,.dgn,.stl,.ply,.wrl";

  constructor(
    public parentComp: ActionLayoutComponent,
    @Inject(ProjetActionService)
    private projetActionService: ProjetActionService,
    private http: HttpClient,
    private irpc: IRPC
  ) {}

  ngOnInit() {
    this.parentComp.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
    });
  }

  handleFileInput(event) {
    let files = Array.from(event.target.files);
    for (const file of <MyFile[]>(<any[]>files)) {
      if (this.files.find((f) => f.name === file.name)) continue;
      this.files.push(file);
    }

    console.log(
      this.files,
      this.files[0].size / 1024 / 1024 + "MB",
      this.files[0].name.split(".").pop()
    );
  }

  checkAllFilesHasType(): boolean {
    return (
      this.files.find((val, idx) => {
        return val.fileType === undefined;
      }) === undefined
    );
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  chunkSize = 10 * 1024 * 1024; //10Mb

  upload(file: MyFile) {
    if (file.uploading || file.progressPercent === "100") return;
    file.uploading = true;
    if (file.progressPercent == null || file.progressPercent == undefined) {
      file.progressPercent = "0";
    }
    if (file.size < this.chunkSize) {
      file.uf.partSize = file.size;
      file.uf.partNumber = 1;
      let rpcReq = this.projetActionService.uploadFile(
        this.ficheProjet.projet.id,
        file.uf
      );
      rpcReq.files = [file];
      rpcReq.subscribeWithProgress(
        (data) => {
          file.uf.idFichier = data;
          /*
          setTimeout(() => {
            this.progressPercent = null;
            this.files = [];
            this.parentComp.parentComp.reload();
          }, 2000);
          */
        },
        (loaded, total) => {
          //console.log('SAVE LOGO progress', loaded, total);
          file.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (error) => {
          file.progressPercent = null;
          L.e(error);
        }
      );
    } else {
      //chunked upload
      this.chunkUpload(file);
    }
  }

  chunkUpload(file: MyFile) {
    file.uf.partNumber++;
    file.error = false;
    file.uf.start = (file.uf.partNumber - 1) * this.chunkSize;
    let end = file.uf.partNumber * this.chunkSize;
    if (end > file.size) end = file.size;

    const blob: Blob = file.slice(file.uf.start, end);
    file.uf.partSize = blob.size;
    console.log("chunked upload", file.uf.partNumber, blob, file.uf);
    let rpcReq = this.projetActionService.uploadFile(
      this.ficheProjet.projet.id,
      file.uf
    );
    rpcReq.withFile(blob);
    rpcReq.subscribeWithProgress(
      (data) => {
        console.log("RETOUR SERVEUR UPLOAD", data);
        file.uf.idFichier = data;
        file.progressPercent = ((file.uf.start / file.size) * 100).toFixed(0);
        file.error = false;
        if (end != file.size) this.chunkUpload(file);
        else {
          file.progressPercent = "100";
          file.uploading = false;
        }
      },
      (loaded, total) => {},
      (err) => {
        if (err.isBusinessError()) {
          console.error("ERROR UPLOAD AT ", file.uf.partNumber, err);
          file.error = true;
        } else {
          console.log(
            "ERROR UPLOAD RETRYING UPLOAD AT ",
            file.uf.partNumber - 1,
            err
          );
          file.error = true;
          file.uf.partNumber--;
          setTimeout(() => {
            this.chunkUpload(file);
          }, 1000);
        }
      }
    );
  }

  envoyer() {
    if (this.files.length === 0 && !this.checkAllFilesHasType()) {
      return;
    }

    for (let file of this.files) {
      let uf = new UploadedFileDTO();
      uf.fileName = file.name;
      uf.fileType = file.fileType;
      uf.size = file.size;
      file.uf = uf;
      this.upload(file);
    }
    console.log("send", this.files);
  }

  envoiEnValidationFinale() {
    this.projetActionService
      .envoiValidationFinale(this.ficheProjet.projet.id)
      .subscribe(
        (data) => {
          setTimeout(() => {
            this.parentComp.parentComp.reload();
          }, 2000);
        },
        (err) => {
          L.e(err);
        }
      );
  }
}
