// ENTITY com.scanha.metier.tarif.entities.CoefPrecision2D generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Precision2D } from './Precision2D';

export class CoefPrecision2D {

  coef: number | null = 0;
  preci: Precision2D | null = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.tarif.entities.CoefPrecision2D'] = CoefPrecision2D;

reverseMapping[CoefPrecision2D.name] = 'com.scanha.metier.tarif.entities.CoefPrecision2D';

fields['com.scanha.metier.tarif.entities.CoefPrecision2D']  = {
    coef: 'java.lang.Double',    preci: 'com.scanha.metier.project.entities.Precision2D'};
