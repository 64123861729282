<div
  *ngIf="admin && etat === etats.ATTENTE_CREATION_COMPTE"
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-exclamation-circle"></i> Création du compte client
  </div>
  <app-action-admin-creation-compte></app-action-admin-creation-compte>
</div>

<div
  *ngIf="
    admin &&
    (etat === etats.ATTENTE_RECTIFICATION_PRIX_ET_ASSIGNATION ||
      etat === etats.REFUSE_PAR_CLIENT)
  "
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-exclamation-circle"></i> Rectification prix et assignation
  </div>
  <app-action-admin-rectif-prix-assignation></app-action-admin-rectif-prix-assignation>
</div>

<div
  *ngIf="
    (donneurOrdre || admin) && etat === etats.ATTENTE_ACCEPTATION_DEVIS_CLIENT
  "
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-check"></i> Validation du devis
  </div>
  <app-action-client-valide-devis></app-action-client-valide-devis>
</div>

<div
  *ngIf="
    (donneurOrdre || admin) &&
    (etat === etats.ATTENTE_PAIEMENT_ACCOMPTE ||
      etat === etats.ATTENTE_PAIEMENT_FINAL)
  "
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-lock"></i> Paiement
    {{ etat === etats.ATTENTE_PAIEMENT_ACCOMPTE ? "de l'accompte" : "final" }}
  </div>
  <app-action-client-paiement
    [acompte]="etat === etats.ATTENTE_PAIEMENT_ACCOMPTE"
  ></app-action-client-paiement>
</div>

<div
  *ngIf="
    (donneurOrdre || admin) &&
    (etat === etats.ATTENTE_PAIEMENT_ACCOMPTE_ATTENTE_VIREMENT ||
      etat === etats.ATTENTE_PAIEMENT_FINAL_ATTENTE_VIREMENT)
  "
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-lock"></i> Paiement
    {{
      etat === etats.ATTENTE_PAIEMENT_ACCOMPTE ? "de l'accompte" : "final"
    }}
    par virement
  </div>
  <app-action-client-attente-virement></app-action-client-attente-virement>
</div>

<div
  *ngIf="
    (scanop || dessinateur || donneurOrdre || admin) &&
    etat === etats.ATTENTE_PRISE_RDV
  "
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-calendar-check"></i>
    {{ scanop || dessinateur ? "Confirmation de RDV" : "Prise de RDV" }}
  </div>
  <app-action-prise-rdv></app-action-prise-rdv>
</div>

<div
  *ngIf="
    (scanop || dessinateur || admin) &&
    (etat === etats.ATTENTE_UPLOAD_FICHIERS ||
      etat === etats.ATTENTE_UPLOAD_FICHIERS_SUP)
  "
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-upload"></i> Envoi des fichiers
  </div>
  <app-action-upload-files></app-action-upload-files>
</div>

<div
  *ngIf="admin && etat === etats.ATTENTE_VALIDATION_FINALE"
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-check"></i> Validation finale
  </div>
  <app-action-validation-finale></app-action-validation-finale>
</div>

<div
  *ngIf="(donneurOrdre || admin) && etat === etats.PROJET_CLOS"
  class="border-primary border p-2 mt-5"
>
  <div class="bg-primary text-white p-2">
    <i class="fas fa-share"></i> Partage de fichiers
  </div>
  <app-action-partage-fichiers></app-action-partage-fichiers>
</div>
