// DB ENUM com.scanha.metier.ScanhaRight generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class ScanhaRight {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ADMIN = new ScanhaRight(1, "Admin", "ADMIN");
  static readonly DONNEUR_DORDRE = new ScanhaRight(2, "Donneur d'ordre", "DONNEUR_DORDRE");
  static readonly SCAN_OPERATOR = new ScanhaRight(3, "Scan opérateur", "SCAN_OPERATOR");
  static readonly DESSINATEUR = new ScanhaRight(4, "Dessinateur", "DESSINATEUR");
  static readonly APPORTEUR_AFFAIRE = new ScanhaRight(5, "Apporteur d'affaire", "APPORTEUR_AFFAIRE");

  static readonly values = [
    ScanhaRight.ADMIN, 
    ScanhaRight.DONNEUR_DORDRE, 
    ScanhaRight.SCAN_OPERATOR, 
    ScanhaRight.DESSINATEUR, 
    ScanhaRight.APPORTEUR_AFFAIRE 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ScanhaRight.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ScanhaRight.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.ScanhaRight'] = ScanhaRight;
reverseMapping[ScanhaRight.name] = 'com.scanha.metier.ScanhaRight';
