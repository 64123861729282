// ENTITY com.scanha.metier.facture.dto.FichierToken generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FichierToken {

  idFichier: number | null = 0;
  token: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.facture.dto.FichierToken'] = FichierToken;

reverseMapping[FichierToken.name] = 'com.scanha.metier.facture.dto.FichierToken';

fields['com.scanha.metier.facture.dto.FichierToken']  = {
    idFichier: 'java.lang.Integer',    token: 'java.lang.String'};
