import { Component, OnInit, Inject, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { IRPC } from "@ic2/ic2-lib";
import { CoreService } from "../ic2/services/CoreService";
import { ScanhaRight } from "../ic2/entities/entities";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor() //@Inject(CoreService) private coreService: CoreService,
  //@Inject(IRPC) private irpc: IRPC,
  {}
  ngOnInit() {
    /*this.coreService.login('maximilien.cruz@degineo.com', 'test').subscribe(
      (data) => {
        console.log(data);
        //Setting token
        this.irpc.authToken = data.token;
      },
      (error) => {
        console.log(error);
      }
    )*/
  }
}
