<div class="container-fluid" *ngIf="!tarif">
  <i class="fas fa-circle-notch spinning"></i> Chargement tarif...
</div>
<div class="container-fluid" *ngIf="tarif">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="'plan2d'">
      <a ngbNavLink>Plan 2D</a>
      <ng-template ngbNavContent>
        <app-admin-price-settings-plan2d></app-admin-price-settings-plan2d>
      </ng-template>
    </li>
    <li [ngbNavItem]="'maquette3d'">
      <a ngbNavLink>Maquette 3D</a>
      <ng-template ngbNavContent>
        <app-admin-price-settings-maquette3d></app-admin-price-settings-maquette3d>
      </ng-template>
    </li>
    <li [ngbNavItem]="'releve'">
      <a ngbNavLink>Relevé numérique</a>
      <ng-template ngbNavContent>
        <app-admin-price-settings-releve></app-admin-price-settings-releve>
      </ng-template>
    </li>
    <li [ngbNavItem]="'reseau'">
      <a ngbNavLink>Réseaux</a>
      <ng-template ngbNavContent>
        <app-admin-price-settings-reseau></app-admin-price-settings-reseau>
      </ng-template>
    </li>
    <li [ngbNavItem]="'forfaits'">
      <a ngbNavLink>Forfaits déplacement</a>
      <ng-template ngbNavContent>
        <table class="table align-middle table-responsive-sm">
          <tr *ngFor="let fdd of tarif.forfaitsDeplacement">
            <td
              class="pointer"
              [class.active]="fdd === forfaitDeplacementSelected"
              (click)="forfaitDeplacementSelected = fdd; calcul()"
            >
              {{ getDepartementStr(fdd.idDepartement) }}
            </td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">forfait</span>
                </div>
                <input
                  type="number"
                  [(ngModel)]="fdd.prix"
                  (ngModelChange)="change()"
                  class="form-control"
                />
              </div>
            </td>
          </tr>
        </table>
      </ng-template>
    </li>
    <li [ngbNavItem]="'options'">
      <a ngbNavLink>Options</a>
      <ng-template ngbNavContent>
        <div class="mt-3">
          <label>Tarif visite virtuelle</label>
          <input
            type="number"
            [(ngModel)]="tarif.tarifVisiteVirtuelle"
            (ngModelChange)="change()"
            class="form-control"
          />
        </div>
        <div class="mt-3">
          <label>Tarif Format natif FARO Zone 2D</label>
          <input
            type="number"
            [(ngModel)]="tarif.tarifFormatNatifFAROZone2D"
            (ngModelChange)="change()"
            class="form-control"
          />
        </div>
        <div class="mt-3">
          <label>Tarif Format natif Scene</label>
          <input
            type="number"
            [(ngModel)]="tarif.tarifFormatNatifScene"
            (ngModelChange)="change()"
            class="form-control"
          />
        </div>
        <div class="mt-3">
          <label>Tarif Plans issus de la maquette 3D</label>
          <input
            type="number"
            [(ngModel)]="tarif.tarifPlansIssusMaquette3D"
            (ngModelChange)="change()"
            class="form-control"
          />
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="'scan'">
      <a ngbNavLink>Prix au scan</a>
      <ng-template ngbNavContent>
        <app-admin-price-settings-prixauscan></app-admin-price-settings-prixauscan>
      </ng-template>
    </li>
  </ul>

  <div class="mt-2" [ngbNavOutlet]="nav"></div>

  <div class="row mt-3">
    <div class="col text-end">
      <button
        type="button"
        (click)="onSubmit()"
        [disabled]="!edited || loading"
        class="btn btn-success"
      >
        Sauvegarder tarif
        <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
      </button>
    </div>
  </div>
  <div class="mt-3 alert alert-info table-responsive-sm">
    <table class="table align-middle table-responsive-sm">
      <thead>
        <tr>
          <th scope="col">Poste</th>
          <th scope="col">Choix</th>
          <th scope="col">Coef</th>
          <th scope="col">Prix</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="tabset && tabset.activeId === 'plan2d'">
          <tr>
            <th scope="row">Tarif 2D</th>
            <td>{{ surfaceRetenue }} m²</td>
            <td>
              {{ coefTarif2D }}
              <ng-container
                *ngIf="nbNiveaux > 0 && nbCoupes === 0 && nbFacades === 0"
              >
                * {{ tarif.coefNiveauUniquement }}</ng-container
              >
              <ng-container
                *ngIf="nbFacades > 0 && nbCoupes === 0 && nbNiveaux === 0"
              >
                * {{ tarif.coefFacadeUniquement }}</ng-container
              >
            </td>
            <td>{{ tarif2DPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Planches</th>
            <td>
              {{ nbCoupes }} coupes + {{ nbFacades }} facades +
              {{ nbNiveaux }} niveaux
            </td>
            <td>{{ tarif.coefPlanche }}</td>
            <td>{{ planchesPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Typologie batiment</th>
            <td>{{ coefTypologieSelected?.typologieBatiment.lib }}</td>
            <td>{{ coefTypologieSelected?.coef }}</td>
            <td>{{ typologiePrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Précision 2D</th>
            <td>{{ coefPrecision2DSelected?.preci.lib }}</td>
            <td>{{ coefPrecision2DSelected?.coef }}</td>
            <td>{{ precision2DPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="tabset && tabset.activeId === 'maquette3d'">
          <tr>
            <th scope="row">Tarif 3D</th>
            <td>{{ surfaceRetenue }} m²</td>
            <td>{{ coefTarif3D }}</td>
            <td>{{ tarif3DPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Précision globale</th>
            <td>{{ coefPrecisionGlobale3DSelected?.precision.lib }}</td>
            <td>{{ coefPrecisionGlobale3DSelected?.coef }}</td>
            <td>{{ precisionGlobale3DPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Typologie batiment</th>
            <td>{{ coefTypologieSelected?.typologieBatiment.lib }}</td>
            <td>{{ coefTypologieSelected?.coef }}</td>
            <td>{{ typologiePrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="tabset && tabset.activeId === 'releve'">
          <tr>
            <th scope="row">Tarif Relevé</th>
            <td>{{ surfaceRetenue }} m²</td>
            <td>{{ coefTarifReleve }}</td>
            <td>{{ tarifRelevePrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Typologie batiment</th>
            <td>{{ coefTypologieSelected?.typologieBatiment.lib }}</td>
            <td>{{ coefTypologieSelected?.coef }}</td>
            <td>{{ typologiePrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="tabset && tabset.activeId === 'reseau'">
          <tr>
            <th scope="row">Unité surface</th>
            <td>
              {{ surfaceRetenue }} {{ coefReseauSelected?.surfaceUnite.lib }}
            </td>
            <td>{{ coefReseauSelected?.coef }}</td>
            <td>{{ reseauPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
          <tr>
            <th scope="row">Typologie réseau</th>
            <td>{{ coefTypologieReseauSelected?.typologieReseau.lib }}</td>
            <td>{{ coefTypologieReseauSelected?.coef }}</td>
            <td>{{ typologieReseauPrice | number : "1.0-2" : "fr" }} €</td>
          </tr>
        </ng-container>
        <tr>
          <th scope="row">Forfait déplacement</th>
          <td>
            {{ getDepartementStr(forfaitDeplacementSelected?.idDepartement) }}
          </td>
          <td>-</td>
          <td>
            {{ forfaitDeplacementSelected?.prix | number : "1.0-2" : "fr" }} €
          </td>
        </tr>
        <tr>
          <th scope="row">Marge</th>
          <td></td>
          <td>{{ coefMarge }}</td>
          <td>{{ margePrice | number : "1.0-2" : "fr" }} €</td>
        </tr>
        <tr>
          <th scope="row">Total</th>
          <td></td>
          <td></td>
          <td>{{ total | number : "1.0-2" : "fr" }} €</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
