<div class="card mb-3">
  <div class="card-body">
    <h4>Filtres</h4>
    <div class="row">
      <div class="col-md-3 col-sm-12">Recherche</div>
      <div class="col-md-9 col-sm-12">
        <input type="text" [(ngModel)]="searchText" class="form-control" #searchTextInput="ngModel" />
      </div>
      <div class="col-md-3 col-sm-12 mt-2">Droit</div>
      <div class="col-md-9 col-sm-12 mt-sm-0 mt-md-2">
        <select [(ngModel)]="selectedRight" class="form-control" (change)="filterChanged()">
          <option [ngValue]="null">- Droit -</option>
          <option *ngFor="let right of rights" [ngValue]="right">
            {{right}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
<table class="table table-hover table-responsive-sm">
  <thead>
    <tr>
      <th>#</th>
      <th>Utilisateur</th>
      <th>Société</th>
      <th>Téléphone</th>
      <th>Email</th>
    </tr>
  </thead>
  <tbody>
    <tr class="pointer" *ngFor="let user of users" [routerLink]="['/admin','utilisateur',user.user.idUser]">
      <td>{{user.user.idUser}}</td>
      <td>{{user.user.lastName+' '+user.user.firstName}}</td>
      <td>{{user.userInfo.societe}}</td>
      <td>{{user.userInfo.tel}}</td>
      <td>{{user.user.email}}</td>
    </tr>
  </tbody>
</table>
<div class="row justify-content-center">
  <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="nbPerPage" [maxSize]="6" [rotate]="true"
    (pageChange)="onPageChange()" [disabled]="loading"></ngb-pagination>
</div>
