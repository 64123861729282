import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import { ActivatedRoute } from "@angular/router";
import { L } from "@ic2/ic2-lib";
import { Subject, BehaviorSubject, forkJoin, ReplaySubject } from "rxjs";
import {
  FicheProjet,
  EtatProjet,
  Departement,
  ScanhaRight,
} from "../../../ic2/entities/entities";
import { ProjetService } from "../../../ic2/services/ProjetService";
import { DepartementCache } from "../../core/DepartementCache.service";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: "app-show-project",
  templateUrl: "./show-project.component.html",
  styleUrls: ["./show-project.component.scss"],
})
export class ShowProjectComponent implements OnInit, OnDestroy {
  public id: number;
  private sub: any;
  loading: boolean = false;
  collapse: boolean = true;
  canAccessFiles: boolean = false;
  dataLoaded: ReplaySubject<FicheProjet> = new ReplaySubject();
  ficheProjet: FicheProjet = null;
  departements: Departement[] = null;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ProjetService) public projetService: ProjetService,
    @Inject(DepartementCache) public departementCache: DepartementCache,
    @Inject(AuthService) public authService: AuthService,
    private cd: ChangeDetectorRef
  ) {
    scanhaTitleService.title = "Détail projet";
    scanhaTitleService.icon = "fas fa-tachometer-alt";
  }

  reload() {
    L.v(this.id);
    this.loading = true;
    this.projetService.getProjet(this.id).subscribe(
      (data) => {
        this.loading = false;
        this.ficheProjet = data;
        // peut voir les preview après validation finale
        if (
          this.authService.has(
            ScanhaRight.ADMIN,
            ScanhaRight.DESSINATEUR,
            ScanhaRight.SCAN_OPERATOR
          )
        )
          this.canAccessFiles = true;
        else
          this.canAccessFiles =
            this.ficheProjet.projet.etatProjet.id >
            EtatProjet.ATTENTE_VALIDATION_FINALE.id;

        this.dataLoaded.next(this.ficheProjet);
        //this.dataLoaded.complete();
        // on complete pas car on peut reload grace au next du coup
        this.cd.detectChanges();
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
  ngOnInit() {
    this.departementCache.getDepartements().subscribe({
      next: (deps) => {
        this.departements = deps;
      },
    });
    this.sub = this.route.params.subscribe((params) => {
      if (params["id"] === undefined) return;

      this.id = +params["id"];
      this.reload();
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getDepartementStr(id: number) {
    let d = this.departements.find((el) => el.id === id);
    if (d) return d.code + " - " + d.name;
    return null;
  }
}
