import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '../../../environments/environment';

if (environment.production)
  enableProdMode();

export * from './ic2/entities/entities';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
