// DB ENUM com.scanha.metier.project.entities.TypeLivrable generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class TypeLivrable {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly _2D = new TypeLivrable(1, "Plan 2D", "_2D");
  static readonly _3D = new TypeLivrable(2, "Maquette 3D", "_3D");
  static readonly _RESEAU = new TypeLivrable(3, "Réseau", "_RESEAU");
  static readonly _RELEVE_NUMERIQUE = new TypeLivrable(4, "Relevé numérique", "_RELEVE_NUMERIQUE");

  static readonly values = [
    TypeLivrable._2D, 
    TypeLivrable._3D, 
    TypeLivrable._RESEAU, 
    TypeLivrable._RELEVE_NUMERIQUE 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of TypeLivrable.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of TypeLivrable.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.TypeLivrable'] = TypeLivrable;
reverseMapping[TypeLivrable.name] = 'com.scanha.metier.project.entities.TypeLivrable';
