import {
  Component,
  OnInit,
  Host,
  Inject,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FicheProjet,
  PartageDTO,
  Partage,
  Fichier,
  PartageFichier,
} from "../../../../../ic2/entities/entities";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { PartageService } from "../../../../../ic2/services/PartageService";
import { L } from "@ic2/ic2-lib";

@Component({
  selector: "app-action-partage-fichiers",
  templateUrl: "./action-partage-fichiers.component.html",
  styleUrls: ["./action-partage-fichiers.component.scss"],
})
export class ActionPartageFichiersComponent implements OnInit {
  ficheProjet: FicheProjet;
  loading: boolean;
  success: string;
  partages: PartageDTO[] = [];
  addMode: boolean;
  editMode: boolean;
  currentPartage: PartageDTO = null;

  //TODO je load la liste d'emails mais je n'ai pas mis en place l'autocomplete car par défaut l'input est pas visible, donc marche pas avec ngbTypeahead
  emails: string[] = [];

  constructor(
    public parentComp: ActionLayoutComponent,
    @Inject(PartageService) public partageService: PartageService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.partageService.getEmailPartagesRecents().subscribe(
      (data) => {
        this.emails = data;
      },
      (err) => {
        L.e(err);
      }
    );
    this.parentComp.parentComp.dataLoaded.subscribe(
      (data) => {
        this.ficheProjet = data;
        this.load();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  load() {
    this.currentPartage = null;
    this.partageService.getPartages(this.ficheProjet.projet.id).subscribe(
      (data) => {
        this.partages = data;
        //console.log(this.partages);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  getValue(f: Fichier) {
    if (!this.currentPartage) return false;
    let present = this.currentPartage.fichiers.find(
      (pf) => pf.idFichier === f.idFichier
    );
    return present !== undefined;
  }

  selectAll() {
    this.currentPartage.fichiers = [];
    for (let fichier of this.ficheProjet.fichierList) {
      let pf = new PartageFichier();
      pf.idFichier = fichier.idFichier;
      this.currentPartage.fichiers.push(pf);
    }
    this.cd.detectChanges();
  }

  setValue($event, f: Fichier) {
    //console.log($event.target.value, f);
    let val = $event.target.value;
    let present = this.currentPartage.fichiers.find(
      (pf) => pf.idFichier === f.idFichier
    );
    if (present) {
      this.currentPartage.fichiers.splice(
        this.currentPartage.fichiers.indexOf(present),
        1
      );
    } else {
      let pf = new PartageFichier();
      pf.idFichier = f.idFichier;
      this.currentPartage.fichiers.push(pf);
    }
  }

  select(partage: PartageDTO) {
    this.editMode = true;
    this.addMode = false;
    this.currentPartage = partage;
  }

  add() {
    this.currentPartage = new PartageDTO();
    this.currentPartage.partage = new Partage();
    this.currentPartage.partage.idProjet = this.ficheProjet.projet.id;
    this.currentPartage.fichiers = [];
    this.addMode = true;
    this.editMode = false;
    this.cd.detectChanges();
    document.getElementById("email").focus();
    //email.focus()
  }
  save() {
    this.loading = true;
    if (this.addMode) {
      this.partageService.createPartage(this.currentPartage).subscribe(
        (data) => {
          this.success = "Partage créé";
          setTimeout(() => {
            this.addMode = false;
            this.loading = false;
            this.success = null;
            this.load();
          }, 2000);
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    } else {
      this.partageService
        .updatePartage(
          this.currentPartage.partage.idPartage,
          this.currentPartage.fichiers
        )
        .subscribe(
          (data) => {
            this.success = "Partage modifié";
            setTimeout(() => {
              this.editMode = false;
              this.loading = false;
              this.success = null;
              this.load();
            }, 2000);
          },
          (err) => {
            this.loading = false;
            L.e(err);
          }
        );
    }
  }
  deleteCurrent() {
    this.loading = true;
    this.partageService
      .deletePartage(this.currentPartage.partage.idPartage)
      .subscribe(
        (data) => {
          this.success = "Partage supprimé";
          setTimeout(() => {
            this.editMode = false;
            this.loading = false;
            this.success = null;
            this.load();
          }, 2000);
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }
}
