import { Component, OnInit, Inject } from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { UserEditValidators } from "../user-edit-form/user-edit.validators";
import { ScanhaUserService } from "../../../ic2/services/ScanhaUserService";
import { Address } from "../../../ic2/entities/Address";

@Component({
  selector: "app-admin-create-account-form",
  templateUrl: "./admin-create-account-form.component.html",
  styleUrls: ["./admin-create-account-form.component.scss"],
})
export class AdminCreateAccountFormComponent implements OnInit {
  loading: boolean;
  submitted: boolean;
  error: string = null;
  success: string = null;
  address: UntypedFormGroup = this.fb.group({
    ligne: [null, [Validators.required]],
    ligne2: [null, []],
    cp: [null, [Validators.required]],
    ville: [null, [Validators.required]],
    pays: [null, [Validators.required]],
  });
  form: UntypedFormGroup = this.fb.group(
    {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      right: [null, [Validators.required]],
      tel: [null, [Validators.required]],
      societe: [null, []],
      fonction: [null, []],
      siret: [null, [UserEditValidators.siretValide]],
      address: this.address,
      prixAuScan: [false, []],
    } /*, {
      validator: UserEditValidators.checkPasswords('mdp', 'mdp2') // your validation method
    }*/
  );

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ScanhaUserService) public scanhaUserService: ScanhaUserService
  ) {
    scanhaTitleService.title = "Création de compte";
    scanhaTitleService.icon = "fa fa-user-plus";
  }

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;
    if (!this.form.valid) return;
    this.loading = true;
    let val = this.form.value;
    let address = Object.assign(new Address(), val.address);
    //console.log(val, address);

    this.scanhaUserService
      .createUser(
        val.email,
        val.tel,
        val.firstName,
        val.lastName,
        val.societe,
        val.fonction,
        val.siret,
        address,
        val.right,
        val.prixAuScan,
        0
      )
      .subscribe(
        (voi) => {
          this.success = "Compte bien créé";
          this.loading = false;
          setTimeout(() => {
            this.form.reset();
            this.submitted = false;
            this.success = null;
          }, 2000);
        },
        (err) => {
          this.error = err.message;
          this.loading = false;
        }
      );
  }
}
