import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import { FactureAdminDTO } from "../../../ic2/entities/entities";
import { FactureService } from "../../../ic2/services/FactureService";
import { L } from "@ic2/ic2-lib";
import { environment } from "../../../../../../environments/environment";

@Component({
  selector: "app-admin-invoice-list",
  templateUrl: "./admin-invoice-list.component.html",
  styleUrls: ["./admin-invoice-list.component.scss"],
})
export class AdminInvoiceListComponent implements OnInit {
  loaded: boolean = false;
  loading: boolean = false;
  count = 0;
  nbPerPage = 15;
  page = 1;
  factures: FactureAdminDTO[] = [];
  rpcUrl: string;
  error: string;

  constructor(
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(FactureService) public factureService: FactureService,
    private cd: ChangeDetectorRef
  ) {
    scanhaTitleService.title = "Liste des factures";
    scanhaTitleService.icon = "fas fa-file-invoice";
    this.rpcUrl = environment.rpcUrl;
  }

  ngOnInit() {
    this.onPageChange();
  }

  paye(id: number) {
    this.loading = true;
    this.factureService.facturePayee(id).subscribe(
      (data) => {
        this.onPageChange();
      },
      (err) => {
        this.loading = false;
        L.e(err);
        this.error = "Une erreur est survenue : " + err.message;
      }
    );
  }

  onPageChange() {
    this.loading = true;
    this.factureService.getFacturesAdmin(this.page, this.nbPerPage).subscribe(
      (data) => {
        this.factures = data.data;
        this.count = data.count;
        this.loaded = true;
        this.loading = false;
        this.cd.detectChanges();
      },
      (error) => {
        this.loading = false;
        L.e(error);
      }
    );
  }
}
