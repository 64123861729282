// ENTITY com.scanha.metier.user.entities.UserInfo generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserInfo {

  idUser: number | null = 0;
  pwdToken: string | null = '';
  tel: string | null = '';
  societe: string | null = '';
  fonction: string | null = '';
  siret: string | null = '';
  idAddress: number | null = 0;
  idUserPayeurPour: number | null = 0;
  idUserCreateur: number | null = 0;
  prixAuScan: boolean | null = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.user.entities.UserInfo'] = UserInfo;

reverseMapping[UserInfo.name] = 'com.scanha.metier.user.entities.UserInfo';

fields['com.scanha.metier.user.entities.UserInfo']  = {
    idUser: 'java.lang.Integer',    pwdToken: 'java.lang.String',    tel: 'java.lang.String',    societe: 'java.lang.String',    fonction: 'java.lang.String',    siret: 'java.lang.String',    idAddress: 'java.lang.Integer',    idUserPayeurPour: 'java.lang.Integer',    idUserCreateur: 'java.lang.Integer',    prixAuScan: 'java.lang.Boolean'};
