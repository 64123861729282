import { Component, OnInit, Host } from "@angular/core";
import { ShowProjectComponent } from "../show-project.component";
import { FicheProjet } from "../../../../ic2/entities/entities";
import { environment } from "../../../../../../../environments/environment";

@Component({
  selector: "app-devis-factures-display",
  templateUrl: "./devis-factures-display.component.html",
  styleUrls: ["./devis-factures-display.component.scss"],
})
export class DevisFacturesDisplayComponent implements OnInit {
  ficheProjet: FicheProjet = null;
  rpcUrl: string;
  constructor(public parentComp: ShowProjectComponent) {
    this.rpcUrl = environment.rpcUrl;
  }

  ngOnInit() {
    this.parentComp.dataLoaded.subscribe((data) => {
      this.ficheProjet = data;
    });
  }

  getDevisToken(id: number) {
    return this.ficheProjet.devisTokenList.find((dt) => {
      return dt.idDevis === id;
    }).token;
  }

  getFactureToken(id: number) {
    return this.ficheProjet.factureTokenList.find((dt) => {
      return dt.idFacture === id;
    }).token;
  }
}
