// ENTITY com.scanha.metier.project.dto.FicheProjet generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { DevisToken } from './DevisToken';
import { Projet } from './Projet';
import { Devis } from './Devis';
import { UserTelEmailDTO } from './UserTelEmailDTO';
import { FichierToken } from './FichierToken';
import { Fichier } from './Fichier';
import { FactureToken } from './FactureToken';
import { Facture } from './Facture';

export class FicheProjet {

  projet: Projet | null = null;
  devisList: Devis[] | null = [];
  devisTokenList: DevisToken[] | null = [];
  factureList: Facture[] | null = [];
  factureTokenList: FactureToken[] | null = [];
  fichierList: Fichier[] | null = [];
  fichierTokenList: FichierToken[] | null = [];
  userAssigne: UserTelEmailDTO | null = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.FicheProjet'] = FicheProjet;

reverseMapping[FicheProjet.name] = 'com.scanha.metier.project.dto.FicheProjet';

fields['com.scanha.metier.project.dto.FicheProjet']  = {
    projet: 'com.scanha.metier.project.entities.Projet',    devisList: 'java.util.List<com.scanha.metier.devis.entities.Devis>',    devisTokenList: 'java.util.List<com.scanha.metier.devis.dto.DevisToken>',    factureList: 'java.util.List<com.scanha.metier.facture.entities.Facture>',    factureTokenList: 'java.util.List<com.scanha.metier.facture.dto.FactureToken>',    fichierList: 'java.util.List<com.scanha.metier.project.entities.Fichier>',    fichierTokenList: 'java.util.List<com.scanha.metier.facture.dto.FichierToken>',    userAssigne: 'com.scanha.metier.user.dto.UserTelEmailDTO'};
