// ENTITY com.scanha.metier.tarif.entities.Tarif generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Palier } from './Palier';
import { CoefTypologieBatiment } from './CoefTypologieBatiment';
import { CoefPrecision2D } from './CoefPrecision2D';
import { CoefPrecision } from './CoefPrecision';
import { ForfaitDeplacementDepartement } from './ForfaitDeplacementDepartement';
import { CoefReseau } from './CoefReseau';
import { CoefTypologieReseau } from './CoefTypologieReseau';

export class Tarif {

  tarif2D: Palier[] | null = [];
  coefPlanche: number | null = 0;
  coefsPrecision2D: CoefPrecision2D[] | null = [];
  tarif3D: Palier[] | null = [];
  coefsPrecisionGlobale3D: CoefPrecision[] | null = [];
  paliersPrixAuScan: Palier[] | null = [];
  coefsReseau: CoefReseau[] | null = [];
  coefsTypologieReseau: CoefTypologieReseau[] | null = [];
  tarifReleve: Palier[] | null = [];
  coefsTypologieBatiment: CoefTypologieBatiment[] | null = [];
  forfaitsDeplacement: ForfaitDeplacementDepartement[] | null = [];
  paliersMarge: Palier[] | null = [];
  tarifVisiteVirtuelle: number | null = 0;
  tarifFormatNatifFAROZone2D: number | null = 0;
  tarifFormatNatifScene: number | null = 0;
  tarifPlansIssusMaquette3D: number | null = 0;
  coefNiveauUniquement: number | null = 0;
  coefFacadeUniquement: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.tarif.entities.Tarif'] = Tarif;

reverseMapping[Tarif.name] = 'com.scanha.metier.tarif.entities.Tarif';

fields['com.scanha.metier.tarif.entities.Tarif']  = {
    tarif2D: 'java.util.List<com.scanha.metier.tarif.entities.Palier>',    coefPlanche: 'java.lang.Double',    coefsPrecision2D: 'java.util.List<com.scanha.metier.tarif.entities.CoefPrecision2D>',    tarif3D: 'java.util.List<com.scanha.metier.tarif.entities.Palier>',    coefsPrecisionGlobale3D: 'java.util.List<com.scanha.metier.tarif.entities.CoefPrecision>',    paliersPrixAuScan: 'java.util.List<com.scanha.metier.tarif.entities.Palier>',    coefsReseau: 'java.util.List<com.scanha.metier.tarif.entities.CoefReseau>',    coefsTypologieReseau: 'java.util.List<com.scanha.metier.tarif.entities.CoefTypologieReseau>',    tarifReleve: 'java.util.List<com.scanha.metier.tarif.entities.Palier>',    coefsTypologieBatiment: 'java.util.List<com.scanha.metier.tarif.entities.CoefTypologieBatiment>',    forfaitsDeplacement: 'java.util.List<com.scanha.metier.tarif.entities.ForfaitDeplacementDepartement>',    paliersMarge: 'java.util.List<com.scanha.metier.tarif.entities.Palier>',    tarifVisiteVirtuelle: 'java.lang.Double',    tarifFormatNatifFAROZone2D: 'java.lang.Double',    tarifFormatNatifScene: 'java.lang.Double',    tarifPlansIssusMaquette3D: 'java.lang.Double',    coefNiveauUniquement: 'java.lang.Double',    coefFacadeUniquement: 'java.lang.Double'};
