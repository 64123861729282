// DB ENUM com.scanha.metier.project.entities.Livrable3D generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class Livrable3D {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly REVIT_2016 = new Livrable3D(1, "Revit Version 2016", "REVIT_2016");
  static readonly REVIT_2017 = new Livrable3D(2, "Revit Version 2017", "REVIT_2017");
  static readonly REVIT_2018 = new Livrable3D(3, "Revit Version 2018", "REVIT_2018");
  static readonly REVIT_2019 = new Livrable3D(4, "Revit Version 2019", "REVIT_2019");
  static readonly ARCHICAD_19 = new Livrable3D(101, "Archicad Version 19", "ARCHICAD_19");
  static readonly ARCHICAD_20 = new Livrable3D(102, "Archicad Version 20", "ARCHICAD_20");
  static readonly ARCHICAD_21 = new Livrable3D(103, "Archicad Version 21", "ARCHICAD_21");
  static readonly ARCHICAD_22 = new Livrable3D(104, "Archicad Version 22", "ARCHICAD_22");
  static readonly AUTRES = new Livrable3D(200, "Autres", "AUTRES");

  static readonly values = [
    Livrable3D.REVIT_2016, 
    Livrable3D.REVIT_2017, 
    Livrable3D.REVIT_2018, 
    Livrable3D.REVIT_2019, 
    Livrable3D.ARCHICAD_19, 
    Livrable3D.ARCHICAD_20, 
    Livrable3D.ARCHICAD_21, 
    Livrable3D.ARCHICAD_22, 
    Livrable3D.AUTRES 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of Livrable3D.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of Livrable3D.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.Livrable3D'] = Livrable3D;
reverseMapping[Livrable3D.name] = 'com.scanha.metier.project.entities.Livrable3D';
