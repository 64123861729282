<header>
  <div class="ms-0 ms-md-4">
    <a href="/"
      ><img
        class="img-circle logo"
        src="assets/images/logo-scanha-header_196x50.png"
        alt="logo scanha"
    /></a>
    <!--<h1 class="ms-2 d-inline align-middle white">SCANha</h1>-->
  </div>
  <a
    class="btn me-0 me-md-4"
    [class.btn-outline-primary]="!loggedIn"
    [class.btn-secondary]="loggedIn"
    [class.white]="loggedIn"
    [routerLink]="loggedIn ? '/client' : '/login'"
    >{{ loggedIn ? "Espace client" : "Connexion" }}</a
  >
</header>
<div class="wrapper">
  <router-outlet></router-outlet>
  <footer>
    <div class="container-fluid">
      <div class="row justify-content-center mt-2">
        <div class="col-6 mt-3 text-right">
          <a
            class="footer-link-icon"
            href="https://www.linkedin.com/company/scanha"
            ><i class="fab fa-linkedin"></i
          ></a>
        </div>
        <div class="col-6 mt-3 text-left">
          <a
            class="footer-link-icon"
            href="https://www.facebook.com/SCANha-345163399666446/"
            ><i class="fab fa-facebook-square"></i
          ></a>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col text-center">
          <a class="footer-link me-3" routerLink="/mentions-legales"
            >Mentions légales</a
          >
          <a
            class="footer-link me-3"
            routerLink="/conditions-generales-de-vente"
            >Conditions générales de vente</a
          >
          <a class="footer-link me-3" routerLink="/rgpd">RGPD</a>
          <a class="footer-link me-3" routerLink="/cookie-info"
            >Politique de cookies</a
          >
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col text-center copyright">
          Copyright &copy; {{ year }} SCANha - Tous droits réservés
        </div>
      </div>
    </div>
  </footer>
</div>
