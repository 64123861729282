<div class="container-fluid" *ngIf="ficheProjet">
  <div class="row mb-2 pb-1 border-bottom border-primary">
    <div class="col">Devis</div>
  </div>
  <div class="row mb-4" *ngFor="let d of ficheProjet.devisList">
    <div class="col-4">
      Devis N°{{ d.id }} du {{ d.dateCreation | date : "dd/MM/yyyy" }}
    </div>
    <div class="col-4">{{ d.prixFinal }} € HT</div>
    <div class="col-4 text-end">
      <a
        href="{{ rpcUrl + 'devis/' + getDevisToken(d.id) }}/devis-{{
          d.id
        }}.pdf"
        target="_blank"
        ><i class="fas fa-download"></i
      ></a>
    </div>
  </div>
  <div class="row mb-2 pb-1 border-bottom border-primary">
    <div class="col">Factures</div>
  </div>
  <div class="row mb-4" *ngFor="let f of ficheProjet.factureList">
    <div class="col-4">
      Facture N°{{ f.id }} du {{ f.dateCreation | date : "dd/MM/yyyy" }}
    </div>
    <div class="col-4">{{ f.montantTTC }} € TTC</div>
    <div class="col-4 text-end">
      <a
        href="{{ rpcUrl + 'facture/' + getFactureToken(f.id) }}/facture-{{
          f.id
        }}.pdf"
        target="_blank"
        ><i class="fas fa-download"></i
      ></a>
    </div>
  </div>
</div>
