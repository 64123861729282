import { Component, OnInit, Inject, Host, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { ScanhaUserService } from "../../../../../ic2/services/ScanhaUserService";
import {
  UserLiteDTO,
  Address,
  FicheProjet,
} from "../../../../../ic2/entities/entities";
import { UserEditValidators } from "../../../user-edit-form/user-edit.validators";
import { ActionLayoutComponent } from "../action-layout/action-layout.component";
import { ProjetActionService } from "../../../../../ic2/services/ProjetActionService";
import { PayeurInfos } from "../../../../../ic2/entities/PayeurInfos";
import { L } from "@ic2/ic2-lib";
import { Observable, Subject, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  filter,
} from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-action-admin-rectif-prix-assignation",
  templateUrl: "./action-admin-rectif-prix-assignation.component.html",
  styleUrls: ["./action-admin-rectif-prix-assignation.component.scss"],
})
export class ActionAdminRectifPrixAssignationComponent implements OnInit {
  error: string;
  success: string;
  loading: boolean = false;
  prixFinal: number;
  users: UserLiteDTO[] = [];
  ficheProjet: FicheProjet;

  address: UntypedFormGroup = this.fb.group({
    ligne: [null, [Validators.required]],
    ligne2: [null, []],
    cp: [null, [Validators.required]],
    ville: [null, [Validators.required]],
    pays: [null, [Validators.required]],
  });
  userForm: UntypedFormGroup = this.fb.group({
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    right: [null, []],
    tel: [null, [Validators.required]],
    societe: [null, []],
    fonction: [null, []],
    siret: [null, [UserEditValidators.siretValide]],
    address: this.address,
  });
  form: UntypedFormGroup = this.fb.group({
    idUserAssigne: [null, [Validators.required]],
    prix: [null, [Validators.required, Validators.min(0)]],
    remise: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    nbHeures: [null, [Validators.required, Validators.min(0)]],
    payeurDifferent: [false, []],
  });
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ScanhaUserService) private scanhaUserService: ScanhaUserService,
    @Inject(ProjetActionService)
    private projetActionService: ProjetActionService,
    public actionLayoutComp: ActionLayoutComponent
  ) {}

  ngOnInit() {
    this.scanhaUserService.getUsersAssignable().subscribe(
      (data) => {
        this.users = data;
        this.actionLayoutComp.parentComp.dataLoaded.subscribe(
          (data) => {
            this.ficheProjet = data;
            this.form.patchValue({
              nbHeures: this.ficheProjet.projet.nbHeures,
              idUserAssigne: this.users.find(
                (u) => u.idUser === this.ficheProjet.projet.idUserAssigne
              ),
            });
            if (data.devisList.length > 0) {
              var dernierDevis = data.devisList.reduce((a, b) => {
                return a.dateCreation > b.dateCreation ? a : b;
              });
              this.form.patchValue({
                prix: dernierDevis.prixFinal,
              });
              this.relcalculPrixFinal();
            }
          },
          (err) => {
            L.e(err);
          }
        );
      },
      (err) => {
        L.e(err);
      }
    );
  }

  formatter(result: UserLiteDTO) {
    if (result === null || result === undefined || <any>result === 0) return "";
    return result.firstName + " " + result.lastName;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => {
        return term === ""
          ? this.users
          : this.users
              .filter((u) => {
                return (
                  (
                    u.firstName.toLowerCase() +
                    " " +
                    u.lastName.toLowerCase()
                  ).indexOf(term.toLowerCase()) > -1 ||
                  (
                    u.lastName.toLowerCase() +
                    " " +
                    u.firstName.toLowerCase()
                  ).indexOf(term.toLowerCase()) > -1
                );
              })
              .slice(0, 10);
      })
    );
  };

  payeurDifferentChange() {
    if (this.form.get("payeurDifferent").value)
      this.form.addControl("userForm", this.userForm);
    else this.form.removeControl("userForm");
  }
  relcalculPrixFinal() {
    this.prixFinal = +(
      this.form.value.prix -
      this.form.value.prix * (this.form.value.remise / 100)
    ).toFixed(2);
  }
  onSubmit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    let val = this.form.value;
    let payeurInfos = null;
    if (val.userForm) {
      const userForm = val.userForm;
      payeurInfos = new PayeurInfos();
      payeurInfos.email = userForm.email;
      payeurInfos.tel = userForm.tel;
      payeurInfos.firstName = userForm.firstName;
      payeurInfos.lastName = userForm.lastName;
      payeurInfos.societe = userForm.societe;
      payeurInfos.fonction = userForm.fonction;
      payeurInfos.siret = userForm.siret;
      let address = Object.assign(new Address(), userForm.address);
      payeurInfos.adresse = address;
    }
    console.log(val);
    this.loading = true;
    this.projetActionService
      .rectifPrixAssign(
        this.ficheProjet.projet.id,
        val.idUserAssigne.idUser,
        val.nbHeures,
        payeurInfos,
        val.prix,
        val.remise
      )
      .subscribe(
        (data) => {
          this.success = "Nouveau devis créé et envoyé par mail";
          setTimeout(() => {
            this.loading = false;
            this.form.reset();
            this.success = null;
            this.actionLayoutComp.parentComp.reload();
          }, 2000);
        },
        (err) => {
          this.error = err.message;
          this.loading = false;
        }
      );
  }
}
