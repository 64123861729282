// ENTITY com.scanha.metier.tarif.entities.Palier generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Palier {

  min: number | null = 0;
  fixe: number | null = 0;
  coef: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.tarif.entities.Palier'] = Palier;

reverseMapping[Palier.name] = 'com.scanha.metier.tarif.entities.Palier';

fields['com.scanha.metier.tarif.entities.Palier']  = {
    min: 'java.lang.Double',    fixe: 'java.lang.Double',    coef: 'java.lang.Double'};
