<div class="container-fluid" *ngIf="projet">
  <div class="row mb-2 pb-1 border-bottom border-primary">
    <div class="col">Détail projet</div>
    <div class="col" style="text-align: end" *ngIf="canEdit">
      <button class="btn btn-sm btn-primary" *ngIf="!editMode" (click)="edit()">
        Modifier
      </button>
      <button
        class="btn btn-sm btn-success me-2"
        [disabled]="saveLoading"
        *ngIf="editMode"
        (click)="save()"
      >
        Enregistrer
        <i class="fas fa-circle-notch spinning" *ngIf="saveLoading"></i>
      </button>
      <button
        class="btn btn-sm btn-secondary"
        [disabled]="saveLoading"
        *ngIf="editMode"
        (click)="cancel()"
      >
        Annuler
      </button>
    </div>
  </div>
  <div class="row mb-4" *ngIf="!editMode">
    <div class="col-md-3 col-sm-6 label">Etape projet</div>
    <div class="col-md-3 col-sm-6">{{ projet.etatProjet.lib }}</div>
    <div class="col-md-3 col-sm-6 label">Date dernière action</div>
    <div class="col-md-3 col-sm-6">
      {{ projet.dateDerniereAction | date : "dd/MM/yyyy" }}
    </div>
    <div class="col-md-3 col-sm-6 label">Type de Livrable</div>
    <div class="col-md-3 col-sm-6">{{ projet.livrable.lib }}</div>
    <ng-container *ngIf="projet.surface !== 0">
      <div class="col-md-3 col-sm-6 label">Surface</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.surface }} {{ projet.surfaceUnite.lib }}
      </div>
    </ng-container>
    <ng-container *ngIf="projet.surface === 0">
      <div class="col-md-3 col-sm-6 label">Nombre de scans</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.nbScans }}
      </div>
    </ng-container>
    <div class="col-md-3 col-sm-6 label">Département projet</div>
    <div class="col-md-3 col-sm-6">
      {{ parentComp.getDepartementStr(projet.idDepartement) }}
    </div>

    <ng-container *ngIf="projet.livrable === typeLivrable._2D">
      <div class="col-md-3 col-sm-6 label">Typologie batiment</div>
      <div class="col-md-3 col-sm-6">{{ projet.typologieBatiment.lib }}</div>
      <div class="col-md-3 col-sm-6 label">Nombre de coupes</div>
      <div class="col-md-3 col-sm-6">{{ projet.nbCoupes }}</div>
      <div class="col-md-3 col-sm-6 label">Nombre de facades</div>
      <div class="col-md-3 col-sm-6">{{ projet.nbFacades }}</div>
      <div class="col-md-3 col-sm-6 label">Nombre de niveaux</div>
      <div class="col-md-3 col-sm-6">{{ projet.nbNiveaux }}</div>
      <div class="col-md-3 col-sm-6 label">Précision</div>
      <div class="col-md-3 col-sm-6">{{ projet.precision2d?.lib }}</div>
    </ng-container>
    <ng-container *ngIf="projet.livrable === typeLivrable._3D">
      <div class="col-md-3 col-sm-6 label">Format</div>
      <div class="col-md-3 col-sm-6">{{ projet.livrable3D.lib }}</div>
      <div class="col-md-3 col-sm-6 label">Typologie batiment</div>
      <div class="col-md-3 col-sm-6">{{ projet.typologieBatiment.lib }}</div>
      <div class="col-md-3 col-sm-6 label">Précision globale</div>
      <div class="col-md-3 col-sm-6">{{ projet.precisionGlobale?.lib }}</div>
    </ng-container>
    <ng-container *ngIf="projet.livrable === typeLivrable._RELEVE_NUMERIQUE">
      <div class="col-md-3 col-sm-6 label">Format</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.livrableReleveNumerique.lib }}
      </div>
      <div class="col-md-3 col-sm-6 label">Typologie batiment</div>
      <div class="col-md-3 col-sm-6">{{ projet.typologieBatiment.lib }}</div>
    </ng-container>
    <ng-container *ngIf="projet.livrable === typeLivrable._RESEAU">
      <div class="col-md-3 col-sm-6 label">Format</div>
      <div class="col-md-3 col-sm-6">{{ projet.livrableReseau.lib }}</div>
      <div class="col-md-3 col-sm-6 label">Typologie réseau</div>
      <div class="col-md-3 col-sm-6">{{ projet.typologieReseau.lib }}</div>
    </ng-container>
  </div>
  <div class="row mb-4" *ngIf="editMode">
    <div class="col-md-3 col-sm-6 label">Etape projet</div>
    <div class="col-md-3 col-sm-6">{{ projet.etatProjet.lib }}</div>
    <div class="col-md-3 col-sm-6 label">Date dernière action</div>
    <div class="col-md-3 col-sm-6">
      {{ projet.dateDerniereAction | date : "dd/MM/yyyy" }}
    </div>
    <div class="col-md-3 col-sm-6 label">Livrable</div>
    <div class="col-md-3 col-sm-6">
      <select [(ngModel)]="projet.livrable" class="form-control">
        <option [ngValue]="null">- Livrables -</option>
        <option *ngFor="let livrable of typeLivrables" [ngValue]="livrable">
          {{ livrable }}
        </option>
      </select>
    </div>
    <ng-container
      *ngIf="projet.surface !== 0 && projet.livrable !== typeLivrable._RESEAU"
    >
      <div class="col-md-3 col-sm-6 label">Surface</div>
      <div class="col-md-3 col-sm-6">
        <input
          type="number"
          [(ngModel)]="projet.surface"
          class="form-control"
          placeholder="m²"
        />
      </div>
    </ng-container>
    <ng-container
      *ngIf="projet.surface === 0 && projet.livrable !== typeLivrable._RESEAU"
    >
      <div class="col-md-3 col-sm-6 label">Nombre de scans</div>
      <div class="col-md-3 col-sm-6">
        <input
          type="number"
          [(ngModel)]="projet.nbScans"
          class="form-control"
        />
      </div>
    </ng-container>
    <div
      class="col-md-3 col-sm-6"
      *ngIf="projet.livrable === typeLivrable._RESEAU"
    >
      <div class="input-group">
        <input
          type="number"
          [(ngModel)]="projet.surface"
          class="form-control"
          [placeholder]="projet.surfaceUnite.lib"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <ng-container *ngFor="let unite of unites">
              <label class="me-1" style="margin-bottom: 0">
                <input
                  type="radio"
                  [(ngModel)]="projet.surfaceUnite"
                  [value]="unite"
                />
                {{ unite.lib }}
              </label>
            </ng-container>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 label">Département projet</div>
    <div class="col-md-3 col-sm-6">
      <select [(ngModel)]="projet.idDepartement" class="form-control">
        <option [ngValue]="null">- Départements -</option>
        <option
          *ngFor="let departement of parentComp.departements"
          [ngValue]="departement.id"
        >
          {{ departement.code + " - " + departement.name }}
        </option>
      </select>
    </div>

    <ng-container *ngIf="projet.livrable === typeLivrable._2D">
      <div class="col-md-3 col-sm-6 label">Typologie batiment</div>
      <div class="col-md-3 col-sm-6">
        <select [(ngModel)]="projet.typologieBatiment" class="form-control">
          <option [ngValue]="null">- Typologies -</option>
          <option *ngFor="let typo of typologies" [ngValue]="typo">
            {{ typo }}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6 label">Nombre de coupes</div>
      <div class="col-md-3 col-sm-6">
        <input
          type="number"
          [(ngModel)]="projet.nbCoupes"
          class="form-control"
        />
      </div>
      <div class="col-md-3 col-sm-6 label">Nombre de facades</div>
      <div class="col-md-3 col-sm-6">
        <input
          type="number"
          [(ngModel)]="projet.nbFacades"
          class="form-control"
        />
      </div>
      <div class="col-md-3 col-sm-6 label">Nombre de niveaux</div>
      <div class="col-md-3 col-sm-6">
        <input
          type="number"
          [(ngModel)]="projet.nbNiveaux"
          class="form-control"
        />
      </div>
      <div class="col-md-3 col-sm-6 label">Précision</div>
      <div class="col-md-3 col-sm-6">
        <select class="form-control" [(ngModel)]="projet.precision2d">
          <option [ngValue]="null">- Précisions -</option>
          <option *ngFor="let precision of precisions2D" [ngValue]="precision">
            {{ precision }}
          </option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="projet.livrable === typeLivrable._3D">
      <div class="col-md-3 col-sm-6 label">Format</div>
      <div class="col-md-3 col-sm-6">
        <select [(ngModel)]="projet.livrable3D" class="form-control">
          <option [ngValue]="null">- Formats -</option>
          <option *ngFor="let livrable of livrables3D" [ngValue]="livrable">
            {{ livrable }}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6 label">Typologie batiment</div>
      <div class="col-md-3 col-sm-6">
        <select [(ngModel)]="projet.typologieBatiment" class="form-control">
          <option [ngValue]="null">- Typologies -</option>
          <option *ngFor="let typo of typologies" [ngValue]="typo">
            {{ typo }}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6 label">Précision globale</div>
      <div class="col-md-3 col-sm-6">
        <select class="form-control" [(ngModel)]="projet.precisionGlobale">
          <option [ngValue]="null">- Précisions -</option>
          <option *ngFor="let precision of precisions" [ngValue]="precision">
            {{ precision }}
          </option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="projet.livrable === typeLivrable._RELEVE_NUMERIQUE">
      <div class="col-md-3 col-sm-6 label">Format</div>
      <div class="col-md-3 col-sm-6">
        <select
          [(ngModel)]="projet.livrableReleveNumerique"
          class="form-control"
        >
          <option [ngValue]="null">- Formats -</option>
          <option *ngFor="let livrable of livrablesReleve" [ngValue]="livrable">
            {{ livrable }}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6 label">Typologie batiment</div>
      <div class="col-md-3 col-sm-6">
        <select [(ngModel)]="projet.typologieBatiment" class="form-control">
          <option [ngValue]="null">- Typologies -</option>
          <option *ngFor="let typo of typologies" [ngValue]="typo">
            {{ typo }}
          </option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="projet.livrable === typeLivrable._RESEAU">
      <div class="col-md-3 col-sm-6 label">Format</div>
      <div class="col-md-3 col-sm-6">
        <select [(ngModel)]="projet.livrableReseau" class="form-control">
          <option [ngValue]="null">- Formats -</option>
          <option *ngFor="let livrable of livrablesReseau" [ngValue]="livrable">
            {{ livrable }}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6 label">Typologie réseau</div>
      <div class="col-md-3 col-sm-6">
        <select [(ngModel)]="projet.typologieReseau" class="form-control">
          <option [ngValue]="null">- Typologies -</option>
          <option *ngFor="let typo of typologiesReseau" [ngValue]="typo">
            {{ typo }}
          </option>
        </select>
      </div>
    </ng-container>
  </div>
  <div class="row mb-2 border-bottom border-primary" *ngIf="userAssigne">
    <div class="col">Votre rdv / interlocuteur</div>
  </div>
  <div class="row" *ngIf="userAssigne">
    <div class="col-md-2 col-sm-6 label">Rdv</div>
    <div class="col-md-4 col-sm-6">
      {{ projet.rdvClient === null ? "A prendre" : projet.rdvClient }}
    </div>
    <div class="col-md-2 col-sm-6 label">Contact</div>
    <div class="col-md-4 col-sm-6">
      {{ userAssigne.lastName }} {{ userAssigne.firstName }}
    </div>
    <div class="col-md-2 col-sm-6 label">Téléphone</div>
    <div class="col-md-4 col-sm-6">
      <a href="tel:{{ userAssigne.tel }}">{{ userAssigne.tel }}</a>
    </div>
    <div class="col-md-2 col-sm-6 label">Email</div>
    <div class="col-md-4 col-sm-6">
      <a href="mailto:{{ userAssigne.email }}">{{ userAssigne.email }}</a>
    </div>
  </div>
  <div class="row mt-2 mb-2 border-bottom border-primary">
    <div class="col">Commentaire</div>
  </div>
  <div class="row">
    <div
      class="col"
      *ngIf="!editMode"
      [class.text-muted]="projet.commentaire === null"
    >
      {{
        projet.commentaire === null ? "Pas de commentaire" : projet.commentaire
      }}
    </div>
    <div class="col" *ngIf="editMode">
      <textarea class="w-100" [(ngModel)]="projet.commentaire"></textarea>
    </div>
  </div>
  <div class="container mt-3" style="background-color: #ffe598">
    <div class="row mb-2 mt-2 border-bottom border-primary" *ngIf="canEdit">
      <div class="col">Informations client</div>
    </div>
    <div class="row" *ngIf="canEdit">
      <div class="col-md-3 col-sm-6 label">Client</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.nomDO + " " + projet.prenomDO }}
      </div>
      <div class="col-md-3 col-sm-6 label">Téléphone</div>
      <div class="col-md-3 col-sm-6">
        <a href="tel:{{ projet.tel }}">{{ projet.tel }}</a>
      </div>
      <div class="col-md-3 col-sm-6 label">Email</div>
      <div class="col-md-3 col-sm-6">
        <a href="mailto:{{ projet.email }}">{{ projet.email }}</a>
      </div>
    </div>
    <div class="row mb-2 mt-2 border-bottom border-primary" *ngIf="canEdit">
      <div class="col">Commentaire privé</div>
    </div>
    <div class="row">
      <div
        class="col"
        *ngIf="!editMode"
        [class.text-muted]="projet.commentaireScanha === null"
      >
        {{
          projet.commentaireScanha === null
            ? "Pas de commentaire privé"
            : projet.commentaireScanha
        }}
      </div>
      <div class="col" *ngIf="editMode">
        <textarea
          class="w-100"
          [(ngModel)]="projet.commentaireScanha"
        ></textarea>
      </div>
    </div>
    <div
      class="row mb-2 mt-2 border-bottom border-primary pointer"
      *ngIf="canEdit"
      (click)="showDates = !showDates"
    >
      <div class="col">
        Dates
        <i
          class="fa"
          [class.fa-arrow-up]="showDates"
          [class.fa-arrow-down]="!showDates"
        ></i>
        <span class="text-muted" style="user-select: none"
          >(Cliquez pour {{ showDates ? "cacher" : "afficher" }})</span
        >
      </div>
    </div>
    <div class="row" *ngIf="canEdit && showDates">
      <div class="col-md-3 col-sm-6 label">Demande</div>
      <div class="col-md-3 col-sm-6 mb-md-5 mb-lg-0">
        {{ projet.dateDemande | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Création du compte</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateCreationCompte | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Dernière proposition du devis</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateDernierePropositionDevis | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Acceptation ou refus client</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateAcceptationOuRefusClient | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Paiement de l'accompte</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.datePaiementAccompte | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Demande du rdv</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateDemandeRdv | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Rdv fixé</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateRdvFixe | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Envoi des fichiers</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateUploadFichier | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">
        Envoi des fichiers supplémentaires
      </div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateUploadFichierSup | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Validation finale</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.dateValidationFinale | date : "dd/MM/yyyy" }}
      </div>
      <div class="col-md-3 col-sm-6 label">Paiement finale</div>
      <div class="col-md-3 col-sm-6">
        {{ projet.datePaiementFinal | date : "dd/MM/yyyy" }}
      </div>
    </div>
  </div>
</div>
