import { Component, OnInit, Inject, Input } from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { ScanhaUserService } from "../../../ic2/services/ScanhaUserService";
import { Address } from "../../../ic2/entities/Address";
import { UserEditValidators } from "../user-edit-form/user-edit.validators";
import { ActivatedRoute } from "@angular/router";
import { UserDTO } from "../../../ic2/entities/UserDTO";
import { AuthService } from "../../core/auth.service";
import { L } from "@ic2/ic2-lib";

@Component({
  selector: "app-user-edit-page",
  templateUrl: "./user-edit-page.component.html",
  styleUrls: ["./user-edit-page.component.scss"],
})
export class UserEditPageComponent implements OnInit {
  public id: number;
  private sub: any;
  loading: boolean;
  submitted: boolean;
  error: string = null;
  success: string = null;
  withRight: boolean = true;
  address: UntypedFormGroup = this.fb.group({
    ligne: [null, [Validators.required]],
    ligne2: [null, []],
    cp: [null, [Validators.required]],
    ville: [null, [Validators.required]],
    pays: [null, [Validators.required]],
  });
  form: UntypedFormGroup = this.fb.group(
    {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      right: [null, []],
      mdp: [null, []],
      mdp2: [null, []],
      tel: [null, [Validators.required]],
      societe: [null, []],
      fonction: [null, []],
      siret: [null, [UserEditValidators.siretValide]],
      address: this.address,
      prixAuScan: [false, []],
    },
    {
      validator: UserEditValidators.checkPasswords("mdp", "mdp2"), // your validation method
    }
  );
  originalData: UserDTO;
  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ScanhaUserService) public scanhaUserService: ScanhaUserService,
    @Inject(AuthService) public authService: AuthService
  ) {
    scanhaTitleService.title = "Mon compte";
    scanhaTitleService.icon = "fa fa-user";
  }

  ngOnInit() {
    if (this.route.routeConfig.path == "mon-compte") {
      this.withRight = false;
      this.id = this.authService.userBundle.user.idUser;
      this.load();
    } else {
      this.sub = this.route.params.subscribe((params) => {
        if (params["id"] === undefined) return;

        this.id = +params["id"];
        this.load();
      });
    }
  }

  load() {
    this.scanhaUserService.getUserInfo(this.id).subscribe(
      (data) => {
        this.originalData = data;
        this.form.patchValue({
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          tel: data.userInfo.tel,
          societe: data.userInfo.societe,
          fonction: data.userInfo.fonction,
          siret: data.userInfo.siret,
          address: data.address,
          prixAuScan: data.userInfo.prixAuScan,
        });
      },
      (err) => {
        L.e(err);
      }
    );
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  onSubmit() {
    this.submitted = true;
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.loading = true;
    let val = this.form.value;
    let address = Object.assign(new Address(), val.address);
    //console.log(val, address);
    if (val.mdp == "") val.mdp = null;
    this.scanhaUserService
      .updateUser(
        this.id,
        val.email,
        val.mdp,
        val.tel,
        val.firstName,
        val.lastName,
        val.societe,
        val.fonction,
        val.siret,
        address,
        val.right,
        val.prixAuScan
      )
      .subscribe(
        (voi) => {
          this.success = "Mise à jour ok";
          this.loading = false;
          setTimeout(() => {
            this.submitted = false;
            this.success = null;
          }, 2000);
        },
        (err) => {
          this.error = err.message;
          this.loading = false;
        }
      );
  }
}
