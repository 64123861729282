import { Component, OnInit, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserEditValidators } from "../../client-space/user-edit-form/user-edit.validators";
import { ActivatedRoute, Router } from "@angular/router";
import { ScanhaUserService } from "../../../ic2/services/ScanhaUserService";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: "app-creation-mdp",
  templateUrl: "./creation-mdp.component.html",
  styleUrls: ["./creation-mdp.component.scss"],
})
export class CreationMdpComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group(
    {
      mdp1: [null, [Validators.required]],
      mdp2: [null, [Validators.required]],
    },
    {
      validator: UserEditValidators.checkPasswords("mdp1", "mdp2"),
    }
  );

  loading: boolean;
  token: string = null;
  error: string = null;

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(ScanhaUserService) private scanhaUserService: ScanhaUserService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) public router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params["token"] === undefined) return;

      this.token = params["token"];
    });
  }
  onSubmit() {
    if (!this.form.valid) {
      console.log(this.form.errors);
      return;
    }
    if (this.token === null) {
      this.error = "Token de création de mot de passe introuvable";
      return;
    }
    this.loading = true;
    this.scanhaUserService
      .createPassword(this.form.value.mdp1, this.token)
      .subscribe(
        (data) => {
          this.loading = false;
          this.authService.bundleReceived(data.ub);
          this.router.navigate(["/client"]);
        },
        (err) => {
          this.loading = false;
          this.error = err.message;
        }
      );
  }
}
