// DB ENUM com.scanha.metier.project.entities.TypologieReseau generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class TypologieReseau {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly URBAINE = new TypologieReseau(1, "Urbaine", "URBAINE");
  static readonly RURAL = new TypologieReseau(2, "Rural", "RURAL");

  static readonly values = [
    TypologieReseau.URBAINE, 
    TypologieReseau.RURAL 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of TypologieReseau.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of TypologieReseau.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.scanha.metier.project.entities.TypologieReseau'] = TypologieReseau;
reverseMapping[TypologieReseau.name] = 'com.scanha.metier.project.entities.TypologieReseau';
