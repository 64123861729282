// ENTITY com.scanha.metier.user.dto.UserLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserLiteDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.user.dto.UserLiteDTO'] = UserLiteDTO;

reverseMapping[UserLiteDTO.name] = 'com.scanha.metier.user.dto.UserLiteDTO';

fields['com.scanha.metier.user.dto.UserLiteDTO']  = {
    idUser: 'java.lang.Integer',    firstName: 'java.lang.String',    lastName: 'java.lang.String'};
