// ENTITY com.scanha.metier.project.dto.ShowPartageDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FichierToken } from './FichierToken';
import { Fichier } from './Fichier';

export class ShowPartageDTO {

  nomProjet: string | null = '';
  fichierList: Fichier[] | null = [];
  fichierTokenList: FichierToken[] | null = [];

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.ShowPartageDTO'] = ShowPartageDTO;

reverseMapping[ShowPartageDTO.name] = 'com.scanha.metier.project.dto.ShowPartageDTO';

fields['com.scanha.metier.project.dto.ShowPartageDTO']  = {
    nomProjet: 'java.lang.String',    fichierList: 'java.util.List<com.scanha.metier.project.entities.Fichier>',    fichierTokenList: 'java.util.List<com.scanha.metier.facture.dto.FichierToken>'};
