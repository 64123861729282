import { Component, OnInit, Inject } from "@angular/core";
import { ScanhaTitleService } from "../client-space-layout/scanha-title.service";
import { L } from "@ic2/ic2-lib";
import { ProjetService } from "../../../ic2/services/ProjetService";
import { ClientProjetDTO } from "../../../ic2/entities/ClientProjetDTO";

@Component({
  selector: "app-client-project-list",
  templateUrl: "./client-project-list.component.html",
  styleUrls: ["./client-project-list.component.scss"],
})
export class ClientProjectListComponent implements OnInit {
  projets: ClientProjetDTO[] = [];
  loaded: boolean = false;
  loading: boolean = false;
  count = 0;
  nbPerPage = 5;
  page = 1;
  constructor(
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ProjetService) public projetService: ProjetService
  ) {
    scanhaTitleService.title = "Mes projets";
    scanhaTitleService.icon = "fas fa-tachometer-alt";
  }

  ngOnInit() {
    this.onPageChange();
  }

  onPageChange() {
    this.loading = true;
    this.projetService.getProjets(this.page, this.nbPerPage).subscribe(
      (data) => {
        this.projets = data.data;
        this.count = data.count;
        this.loaded = true;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        L.e(error);
      }
    );
  }
}
