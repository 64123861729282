// ENTITY com.scanha.metier.project.dto.AdminProjetDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TypeLivrable } from './TypeLivrable';
import { EtatProjet } from './EtatProjet';

export class AdminProjetDTO {

  id: number | null = 0;
  idUserLie: number | null = 0;
  idUserAssigne: number | null = 0;
  nomAssigne: string | null = '';
  prenomAssigne: string | null = '';
  nomDO: string | null = '';
  prenomDO: string | null = '';
  dejaClient: boolean | null = false;
  nom: string | null = '';
  etatProjet: EtatProjet | null = null;
  livrable: TypeLivrable | null = null;
  surface: number | null = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.project.dto.AdminProjetDTO'] = AdminProjetDTO;

reverseMapping[AdminProjetDTO.name] = 'com.scanha.metier.project.dto.AdminProjetDTO';

fields['com.scanha.metier.project.dto.AdminProjetDTO']  = {
    id: 'java.lang.Integer',    idUserLie: 'java.lang.Integer',    idUserAssigne: 'java.lang.Integer',    nomAssigne: 'java.lang.String',    prenomAssigne: 'java.lang.String',    nomDO: 'java.lang.String',    prenomDO: 'java.lang.String',    dejaClient: 'java.lang.Boolean',    nom: 'java.lang.String',    etatProjet: 'com.scanha.metier.project.entities.EtatProjet',    livrable: 'com.scanha.metier.project.entities.TypeLivrable',    surface: 'java.lang.Integer'};
