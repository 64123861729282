// ENTITY com.scanha.metier.tarif.entities.CoefReseau generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:23 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { SurfaceUnite } from './SurfaceUnite';

export class CoefReseau {

  coef: number | null = 0;
  surfaceUnite: SurfaceUnite | null = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.scanha.metier.tarif.entities.CoefReseau'] = CoefReseau;

reverseMapping[CoefReseau.name] = 'com.scanha.metier.tarif.entities.CoefReseau';

fields['com.scanha.metier.tarif.entities.CoefReseau']  = {
    coef: 'java.lang.Double',    surfaceUnite: 'com.scanha.metier.project.entities.SurfaceUnite'};
