<form
  (onSubmit)="onSubmit()"
  #ngForm="ngForm"
  [formGroup]="form"
  [class.show-errors]="ngForm.submitted && !form.valid"
  *ngIf="ficheProjet"
>
  <div class="container">
    <div
      class="row mb-2"
      *ngIf="
        ficheProjet.projet.motifRefus !== null &&
        ficheProjet.projet.motifRefus.length > 0
      "
    >
      <label>Motif du refus client</label>
      <p class="w-100 text-info">{{ ficheProjet.projet.motifRefus }}</p>
    </div>
    <div class="row mb-2">
      <label>Assigner à</label>
      <input
        type="text"
        class="form-control"
        (focus)="focus$.next($event.target.value)"
        (click)="click$.next($event.target.value)"
        #instance="ngbTypeahead"
        formControlName="idUserAssigne"
        [ngbTypeahead]="search"
        [resultFormatter]="formatter"
        [inputFormatter]="formatter"
        required
      />
    </div>
    <div class="row mb-2">
      <label>Nombre d'heures</label>
      <input type="number" class="form-control" formControlName="nbHeures" />
    </div>
    <div class="row mb-2">
      <label
        >Payeur différent
        <input
          type="checkbox"
          formControlName="payeurDifferent"
          (change)="payeurDifferentChange()"
      /></label>
      <app-user-edit-form
        [form]="userForm"
        [withRight]="false"
        [submitted]="ngForm.submitted"
        *ngIf="form.get('payeurDifferent').value"
      ></app-user-edit-form>
    </div>
    <div class="row mb-2">
      <label>Prix (HT)</label>
      <input
        type="number"
        class="form-control"
        formControlName="prix"
        (change)="relcalculPrixFinal()"
      />
    </div>
    <div class="row mb-2">
      <label>Remise (en %)</label>
      <input
        type="number"
        class="form-control"
        formControlName="remise"
        (change)="relcalculPrixFinal()"
      />
    </div>
    <div class="row mb-2 justify-content-end">
      <p>Prix final : {{ prixFinal }} € HT</p>
    </div>
    <div class="row" *ngIf="error">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
    <div class="row" *ngIf="success">
      <div class="alert alert-success">
        {{ success }}
      </div>
    </div>
    <div class="row justify-content-end">
      <button
        type="submit"
        (click)="onSubmit()"
        [disabled]="loading"
        class="btn btn-success"
      >
        Valider <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
      </button>
    </div>
  </div>
</form>
