// com.scanha.metier.project.service.ProjetActionServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 06 12:44:18 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UploadedFileDTO } from '../entities/UploadedFileDTO';
import { PayeurInfos } from '../entities/PayeurInfos';

@Injectable({
  providedIn: 'root',
})
export class ProjetActionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  rectifPrixAssign(idProjet: number, idUserAssigne: number, nbHeures: number, infos: PayeurInfos, prix: number, remise: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.rectifPrixAssign';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserAssigne, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbHeures, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(infos, 'com.scanha.metier.project.dto.PayeurInfos', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(prix, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(remise, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  valideDevis(idProjet: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.valideDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  demandeModifDevis(idProjet: number, commentaire: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.demandeModifDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(commentaire, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  proposerDatesRdv(idProjet: number, propositionRdvClient: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.proposerDatesRdv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(propositionRdvClient, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  accepterRdv(idProjet: number, rdvClient: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.accepterRdv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(rdvClient, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  uploadFile(idProjet: number, uploadedFile: UploadedFileDTO): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.uploadFile';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(uploadedFile, 'com.scanha.metier.project.dto.UploadedFileDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  removeFile(idFichier: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.removeFile';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFichier, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  envoiValidationFinale(idProjet: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.envoiValidationFinale';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  validationFinale(idProjet: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.validationFinale';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  redistribue(idProjet: number, idUser: number, commentaireScanha: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProjetActionService.redistribue';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProjet, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(commentaireScanha, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
