import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScanhaTitleService } from "./scanha-title.service";
import { AuthService } from "../../core/auth.service";
import { ScanhaRight } from "../../../ic2/entities/entities";

@Component({
  selector: "app-client-space-layout",
  templateUrl: "./client-space-layout.component.html",
  styleUrls: ["./client-space-layout.component.scss"],
})
export class ClientSpaceLayoutComponent implements OnInit, OnDestroy {
  menuVisible: boolean = false;
  admin: boolean;
  scanop: boolean;
  dessinateur: boolean;
  donneurOrdre: boolean;
  apporteurAffaire: boolean;
  title;
  icon;

  constructor(
    @Inject(ScanhaTitleService) public scanhaTitleService: ScanhaTitleService,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(Router) public router: Router,
    @Inject(AuthService) private authService: AuthService
  ) {
    this.admin = this.authService.has(ScanhaRight.ADMIN);
    this.scanop = this.authService.has(ScanhaRight.SCAN_OPERATOR);
    this.dessinateur = this.authService.has(ScanhaRight.DESSINATEUR);
    this.donneurOrdre = this.authService.has(ScanhaRight.DONNEUR_DORDRE);
    this.apporteurAffaire = this.authService.has(ScanhaRight.APPORTEUR_AFFAIRE);
    //console.log(router.isActive('/admin/projet',false), router);
    /*this._routerSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })).subscribe((route: ActivatedRouteSnapshot) => {
        console.log(route);
        if (route.data.title)
          this.title = route.data.title;
        else
          this.title = 'Manque le titre dans le routing';
      });*/

    scanhaTitleService.changes.subscribe(() => {
      this.title = scanhaTitleService._title;
      this.icon = scanhaTitleService._icon;
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    //this._routerSub.unsubscribe();
  }
  logout() {
    this.authService.logout();
  }
}
