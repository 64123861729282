<div *ngIf="!originalData">Chargement <i class="fas fa-circle-notch spinning"></i></div>
<div *ngIf="originalData">
  <app-user-edit-form [form]="form" [withRight]="withRight" [submitted]="submitted"></app-user-edit-form>
  <div class="container">
    <div class="row justify-content-end" *ngIf="error">
      <div class="alert alert-danger">
        {{error}}
      </div>
    </div>
    <div class="row justify-content-end" *ngIf="success">
      <div class="alert alert-success">
        {{success}}
      </div>
    </div>
    <div class="row justify-content-end">
      <button type="button" (click)="onSubmit()" [disabled]="loading" class="btn btn-success">Sauvegarder <i
          class="fas fa-circle-notch spinning" *ngIf="loading"></i></button>
    </div>
  </div>
</div>
